import * as React from "react";
import {Button} from "react-bootstrap";
import {Container, Col} from "react-bootstrap";
import "./Signin.css";
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {Formik, FormikHelpers} from 'formik';
import {loginFormValidation} from "./SigninValidation/loginFormValidation";
import {withRouter, Link, RouteComponentProps} from "react-router-dom";
import {Dispatch} from "react";
import {connect} from "react-redux";
import {loginUserWithParams} from "../../hooks/user/userFunctions";
import {AppState} from "../../store/reducers/rootReducer";
import {getUser} from "../../store/selectors/user/user.selector";

interface UserLoggedInfo {
  isUserLogged: boolean,
}

interface LoginData {
  username: string,
  password: string,
}

interface DispatchLoginFunctions {
  loginUserWithCredentials: (
    username: string,
    password: string,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
}

const mapStateToProps = (state: AppState): UserLoggedInfo => {
  return {
    isUserLogged: getUser(state) !== null,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchLoginFunctions => {
  return {
    loginUserWithCredentials: (
      username: string,
      password: string,
      handleSuccess: () => void,
      handleError: () => void) => loginUserWithParams(username, password, handleSuccess, handleError, dispatch),
  }
}

type UserLoginData = DispatchLoginFunctions & UserLoggedInfo & RouteComponentProps;

const Signin: React.FC<UserLoginData> = (storeData) => (
  <Container className="p-0">
    <div className="signing-content">
        <Col xs="12" sm="12" lg="6" className="my-5">
          <p className="w-100 text-right">
            <Link to="/signup" className="text-signup-link"> Don’t have an account? Sign up here.</Link>
          </p>
          <h2 className="my-4">Sign In </h2>
          <Formik
            initialValues={{ username: "", password: ""}}
            validationSchema={loginFormValidation}
            onSubmit={(login: LoginData, helpers:FormikHelpers<LoginData>) => {
              helpers.setSubmitting(true)
              storeData.loginUserWithCredentials(login.username, login.password, () => {
                helpers.resetForm()
                storeData.history.replace("/")
                helpers.setSubmitting(false)
              }, () => {
                helpers.setStatus({
                  isError: true,
                  errorMsg: "The credential you have entered are invalid!"
                })
                helpers.setSubmitting(false)
              })
            }}
          >
            {
              ({
                 values,
                 errors,
                 touched,
                 status,
                 isSubmitting,
                 handleChange,
                 handleBlur,
                 handleSubmit,
              }) => (
                <Form className="row" onSubmit={handleSubmit}>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>Email/Username:</FormLabel>
                      <FormControl
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.username && errors.username ? "error-border" : ""}
                        placeholder="Enter your email" />
                      {touched.username && errors.username ? (
                        <div className="error-message">{errors.username}</div>
                      ): <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicPass">
                      <FormLabel>Password:</FormLabel>
                      <FormControl
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.password && errors.password ? "error-border" : ""}
                        placeholder="Enter your password"
                      />
                      {touched.password && errors.password ? (
                        <div className="error-message">{errors.password}</div>
                      ): <div className="error-message">&nbsp;</div>}
                      {status && status.isError && <div className="error-message">{status.errorMsg}</div>}
                      {/*<FormText className="text-muted text-right">
                        Forgot your password?
                      </FormText>*/}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12" className="my-3 text-center">
                    <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                      {!isSubmitting && "Sign in"}
                      {isSubmitting && <div className="button-loader">
                        <div className="dot1"/>
                        <div className="dot2"/>
                        <div className="dot3"/>
                      </div>}
                    </Button>
                  </Col>
                </Form>
              )
            }
          </Formik>
        </Col>
    </div>
  </Container>
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signin));
