import * as React from "react";
import "../Modals.css"
import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {isConsentStatusAvailable, setUserConsent} from "../../../hooks/user/userFunctions";
import {useState} from "react";

const ConsentModal: React.FC = () => {
  const [showConsentDialog, setShowConsentDialog] = useState(!isConsentStatusAvailable())
  const closeDialog = () => setShowConsentDialog(false)
  return (
    <Modal show={showConsentDialog} className="modal-cookies">
      <Modal.Header className="text-left justify-content-start">
        <Modal.Title><h6 className="m-0 cookie-header">We only need a small confirmation</h6></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-left text-cookies">
      To make TVBIZZ work, we log user data. By using TVBIZZ, you agree to our <Link to={'/privacy'}>Privacy Policy</Link>, including cookie policy.
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <div className="modal-buttons">
          <Button onClick={() => setUserConsent(false, closeDialog)} variant="outline-primary" className="tvbizz-outline-primary mx-2">
            Close
          </Button>
          <Button onClick={() => setUserConsent(true, closeDialog)} variant="tvbizz-primary" className="tvbizz-primary modal-button">
            Continue to TVBIZZ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ConsentModal;
