import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";

import update from "immutability-helper";
import {Show} from "../../../model/Show";
import {
  CLEAR_SHOW_DATA_EVENT,
  LOAD_SHOW_COMPLETED_EVENT,
  LOAD_SHOW_NOT_COMPLETED_EVENT,
  LOAD_SHOW_RELATED_ITEMS_EVENT,
  LoadShowCompletedAction,
  LoadShowNotCompletedAction,
  LoadShowRelatedItemsAction,
  ClearShowData,
} from "../../actions/show/show.actions";

export interface ShowReducerState {
  tweets: Tweet[],
  videos: TweetVideo[],
  loading: boolean,
  show: Show | null,
  noMoreRelatedData: boolean,
}

const showState = {
  tweets: [],
  videos: [],
  loading: false,
  show: null,
  noMoreRelatedData: false,
}

export default function showReducer(state: ShowReducerState = showState,
                                    action: LoadShowCompletedAction | LoadShowRelatedItemsAction |
                                      LoadShowNotCompletedAction | ClearShowData
) : ShowReducerState {
  switch (action.type) {
    case LOAD_SHOW_COMPLETED_EVENT:
      return update(state, {
        show: {$set: action.show},
        loading: {$set: false},
      })
    case LOAD_SHOW_RELATED_ITEMS_EVENT:
      return update(state, {
        tweets: {$push: action.tweets},
        videos: {$push: action.videos},
        loading: {$set: false},
        noMoreRelatedData: {$set: (action.tweets.length === 0 && action.videos.length === 0)},
      })
    case LOAD_SHOW_NOT_COMPLETED_EVENT:
      return update(state, {
        loading: {$set: true},
      })
    case CLEAR_SHOW_DATA_EVENT:
      return update(state, {
        show: {$set: null},
        tweets: {$set: []},
        videos: {$set: []},
        loading: {$set: false},
      })
    default:
      return state
  }
}
