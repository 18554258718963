import * as React from "react";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./HeadlinePaneCard.css"
import {IMAGE_URL_LOCAL} from "../../../../../const/valueConst";
import NewsTitle from "../../../../Common/Title/NewsTitle";
import {Tweet} from "../../../../../model/Tweet";
import {buildArticleLink} from "../../../../../utils/article/articleUtils";
import NewsDateCountryPane from "../../../../Common/DateCountry/NewsDateCountryPane";

interface HeadlineData {
  news: Tweet,
}

const HeadlinePaneCard: React.FC<HeadlineData> = (props) => (
  <Card className="headline_box_no_borders">
    <Link to={buildArticleLink(props.news.tweetId, props.news.ceetv)}>
      <Card.Img className="card-image-first-page image_no_border_rounding" src={`${IMAGE_URL_LOCAL}${props.news.image ?? (props.news.ceetv ? "ceetv-black-fill-small.png" : "filler.png")}`} />
      <div className="country-card-images-first">
        <div className="LocationTime location-news-list pl-0">
          <span className="country-background">
            <NewsDateCountryPane newsItem={props.news} withDate={false} />
          </span>
        </div>
      </div>  
    </Link>
    <Card.Body className="headline_title_background">
      <Card.Title className="text_no_bottom_margin h6 mt-1">
        <NewsTitle
          isPaid={!props.news.free}
          isPr={props.news.pr}
          title={props.news.title}
          newsId={props.news.tweetId}
          ceetv={props.news.ceetv}
        />
      </Card.Title>
    </Card.Body>
  </Card>
)
export default HeadlinePaneCard;
