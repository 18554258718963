
export function tranformCountry(country: string, isCeetv: boolean | null): string {
  if(country === 'N/A') {
    return isCeetv ? "CEETV" : "TVBIZZ";
  } else if(country === 'UK') {
    return "United Kingdom";
  } else if(country === 'US') {
    return "United States";
  }
  return country
}

export function getCountryFlagCode(country: string) {
  switch (country) {
    case 'Belarus':
      return 'BLR'
    case 'Bosnia':
      return 'BIH'
    case 'Bulgaria':
      return 'BGR'
    case 'Croatia':
      return 'HRV'
    case 'Czech Republic':
      return 'CZE'
    case 'Estonia':
      return 'EST'
    case 'Greece':
      return 'GRC'
    case 'Hungary':
      return 'HUN'
    case 'Latvia':
      return 'LVA'
    case 'Lithuania':
      return 'LTU'
    case 'North Macedonia':
      return 'MKD'
    case 'Poland':
      return 'POL'
    case 'Romania':
      return 'ROU'
    case 'Russia':
      return 'RUS'
    case 'Serbia':
      return 'SRB'
    case 'Slovakia':
      return 'SVK'
    case 'Slovenia':
      return 'SVN'
    case 'Turkey':
      return 'TUR'
    case 'Ukraine':
      return 'UKR'
    case 'Middle East':
      return ''
    case 'Algeria':
    case 'Egypt':
    case 'Israel':
    case 'Morocco':
    case 'Tunisia':
    case 'United States':
    case 'US':
    case 'Canada':
    case 'Asia':
      return ''
    case 'China':
      return 'CHN'
    case 'Hong Kong':
      return 'HKG'
    case 'India':
      return 'IND'
    case 'Japan':
      return 'JPN'
    case 'Korea':
      return 'KOR'
    case 'Philippines':
      return 'PHL'
    case 'Singapore':
      return 'SGP'
    case 'Thailand':
      return 'THA'
    case 'Vietnam':
      return 'VNM'
    case 'Australia':
      return 'AUS'
    case 'New Zealand':
      return 'NZL'
    case 'Africa':
      return ''
    case 'Austria':
      return 'AUT'
    case 'Denmark':
      return 'DNK'
    case 'Finland':
      return 'FIN'
    case 'France':
      return 'FRA'
    case 'Germany':
      return 'DEU'
    case 'Italy':
      return 'ITA'
    case 'Ireland':
      return 'IRL'
    case 'Netherlands':
      return 'NLD'
    case 'Norway':
      return 'NOR'
    case 'Portugal':
      return 'PRT'
    case 'Spain':
      return 'ESP'
    case 'Sweden':
      return 'SWE'
    case 'Switzerland':
      return 'CHE'
    case 'United Kingdom':
      return 'GBR'
    case 'UK':
      return 'GBR'
    case 'Argentina':
      return 'ARG'
    case 'Bolivia':
      return 'BOL'
    case 'Brazil':
      return 'BRA'
    case 'Chile':
      return 'CHL'
    case 'Colombia':
      return 'COL'
    case 'Ecuador':
      return 'ECU'
    case 'Mexico':
      return 'MEX'
    case 'Panama':
      return 'PAN'
    case 'Paraguay':
      return 'PRY'
    case 'Peru':
      return 'PER'
    case 'Uruguay':
      return 'URY'
    case 'Venezuela':
      return 'VEN'
    default:
      return ''
  }
}
