import {
  newsLatest,
  headlinesList,
  loadSingleNewsItem,
  trendingStartPage
} from "../../../const/urlConst";
import {Tweet} from "../../../model/Tweet";
import {FilterCriteria} from "../../../model/FilterCriteria";
import {Dispatch} from "react";
import {axiosInstance} from "../../../const/axiosClient";
import {RegionType} from "../../../model/RegionType";
import {getEnumFromInt} from "../../../utils/country/regionUtils";

export const LOAD_HEADLINES_EVENT = 'LOAD_HEADLINES';
export const LOAD_TWEETS_LATEST_EVENT = 'LOAD_TWEETS_LATEST';
export const LOAD_TWEETS_TRENDING_EVENT = 'LOAD_TWEETS_TRENDING';
export const LOAD_TWEET_RELATED_EVENT = 'LOAD_TWEET_RELATED';
export const LOAD_TWEET_LOADING_EVENT = 'LOAD_TWEET_LOADING';
export const LOAD_TWEETS_LATEST_RELATED_EVENT = 'LOAD_TWEETS_LATEST_RELATED';
export const CLEAR_DATA_IN_STORE_EVENT = 'CLEAR_DATA_IN_STORE';

// define types
export type LOAD_HEADLINES = typeof LOAD_HEADLINES_EVENT;
export type LOAD_TWEETS_LATEST = typeof LOAD_TWEETS_LATEST_EVENT;
export type LOAD_TWEETS_TRENDING = typeof LOAD_TWEETS_TRENDING_EVENT;
export type LOAD_TWEET_RELATED = typeof LOAD_TWEET_RELATED_EVENT;
export type LOAD_TWEET_LOADING = typeof LOAD_TWEET_LOADING_EVENT;
export type LOAD_TWEETS_LATEST_RELATED = typeof LOAD_TWEETS_LATEST_RELATED_EVENT;
export type CLEAR_DATA_IN_STORE = typeof CLEAR_DATA_IN_STORE_EVENT;


export interface LoadTweetLatestAction {
  type: LOAD_TWEETS_LATEST,
  tweets: Tweet[],
  filter: FilterCriteria,
}

export interface LoadTweetTrendingAction {
  type: LOAD_TWEETS_TRENDING,
  tweets: Tweet[],
  filter: FilterCriteria,
}

export interface LoadTweetHeadlines {
  type: LOAD_HEADLINES,
  headlines: Tweet[],
  mainNews: Tweet,
}

export interface LoadTweetLoading {
  type: LOAD_TWEET_LOADING,
  loading: boolean,
}

export interface LoadTweetRelated {
  type: LOAD_TWEET_RELATED,
  selectedItem: Tweet,
  relatedNews: Tweet[],
  latestRelatedNews: Tweet[],
}

export interface LoadTweetLatestRelated {
  type: LOAD_TWEETS_LATEST_RELATED,
  latestRelatedNews: Tweet[],
}

export interface ClearNewsData {
  type: CLEAR_DATA_IN_STORE,
}

export const loadTweetLists = (isTrending: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = isTrending ? await axiosInstance.get(`${trendingStartPage}`)
                                : await axiosInstance.get(`${newsLatest}`);
    dispatch( {
      type: isTrending ? LOAD_TWEETS_TRENDING_EVENT : LOAD_TWEETS_LATEST_EVENT,
      tweets: data.news,
      filter: {
        region: null,
        country: null,
        company: null,
        show: null,
        category: null,
      }
    });
  }
}

export const loadTweetListsAfterDate = (lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
                    ? await axiosInstance.get(`${newsLatest}?before=${lastDate}`)
                    : await axiosInstance.get(`${newsLatest}`);
    dispatch( {
      type: LOAD_TWEETS_LATEST_EVENT,
      tweets: data.news,
      filter: {
        region: null,
        country: null,
        company: null,
        show: null,
        category: null,
      }
    });
  }
}

export const loadTweetHeadlines = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${headlinesList}`)
    dispatch( {
      type: LOAD_HEADLINES_EVENT,
      headlines: data.news,
      mainNews: data.headline,
    });
  }
}

export const loadingRelatedData = (loading: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_TWEET_LOADING_EVENT,
      loading: loading,
    });
  }
}

export const loadRelatedDataSingleItem = (newsId: number, ceetv: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = ceetv
                      ? await axiosInstance.get(`${loadSingleNewsItem}${newsId}?ceetv`)
                      : await axiosInstance.get(`${loadSingleNewsItem}${newsId}`)
    dispatch( {
      type: LOAD_TWEET_RELATED_EVENT,
      selectedItem: data.newsItem,
      relatedNews: data.related,
      latestRelatedNews: data.nextItems,
    });
  }
}

export const loadTweetRelatedAfterDate = (lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${newsLatest}?before=${lastDate}`)
      : await axiosInstance.get(`${newsLatest}`);
    dispatch( {
      type: LOAD_TWEETS_LATEST_RELATED_EVENT,
      latestRelatedNews: data.news,
    });
  }
}

export const loadTweetsListForRegion = (region: RegionType, lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${newsLatest}?before=${lastDate}&region=${region}`)
      : await axiosInstance.get(`${newsLatest}?region=${region}`);
    dispatch( {
      type: LOAD_TWEETS_LATEST_EVENT,
      tweets: data.news,
      filter: {
        region: getEnumFromInt(data.region),
        country: null,
        company: null,
        show: null,
        category: null,
      },
    });
  }
}

export const loadTweetsListForCountry = (country: string, lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${newsLatest}?before=${lastDate}&country=${encodeURIComponent(country)}`)
      : await axiosInstance.get(`${newsLatest}?country=${encodeURIComponent(country)}`);
    dispatch( {
      type: LOAD_TWEETS_LATEST_EVENT,
      tweets: data.news,
      filter: {
        region: null,
        country: data.country,
        company: null,
        show: null,
        category: null,
      },
    });
  }
}

export const clearNewsData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: CLEAR_DATA_IN_STORE_EVENT,
    });
  }
}

