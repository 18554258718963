import * as React from 'react';
import {Image} from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom';
import tvbizz from "../../../resources/images/tvbizz-logo-new.svg";
import "./MainLogo.css"

const MainLogo: React.FC = () => (
  <div className="logo_container ml-0">
    <Link to="/">
      <Image src={tvbizz} className="tvbizz_logo" fluid />
    </Link>
  </div>
)

export default withRouter(MainLogo);
