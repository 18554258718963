import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";

import {Dispatch} from "react";
import {axiosInstance} from "../../../const/axiosClient";
import {categoryRelatedItems, countryRelatedItems, searchRelatedItems, tagRelatedItems} from "../../../const/urlConst";
import {SearchType} from "../../../model/SearchType";


export const LOAD_PEOPLE_COMPLETED_EVENT = 'LOAD_PEOPLE_COMPLETED';
export const LOAD_COUNTRY_COMPLETED_EVENT = 'LOAD_COUNTRY_COMPLETED';
export const LOAD_KEYWORD_COMPLETED_EVENT = 'LOAD_KEYWORD_COMPLETED';
export const LOAD_CATEGORY_COMPLETED_EVENT = 'LOAD_CATEGORY_COMPLETED';
export const LOAD_SEARCH_NOT_COMPLETED_EVENT = 'LOAD_SEARCH_NOT_COMPLETED';
export const LOAD_RELATED_ITEMS_EVENT = 'LOAD_RELATED_ITEMS';
export const CLEAR_SEARCH_DATA_EVENT = 'CLEAR_SEARCH_DATA';

export type LOAD_PEOPLE_COMPLETED = typeof LOAD_PEOPLE_COMPLETED_EVENT;
export type LOAD_COUNTRY_COMPLETED = typeof LOAD_COUNTRY_COMPLETED_EVENT;
export type LOAD_KEYWORD_COMPLETED = typeof LOAD_KEYWORD_COMPLETED_EVENT;
export type LOAD_CATEGORY_COMPLETED = typeof LOAD_CATEGORY_COMPLETED_EVENT;
export type LOAD_SEARCH_NOT_COMPLETED= typeof LOAD_SEARCH_NOT_COMPLETED_EVENT;
export type LOAD_RELATED_ITEMS = typeof LOAD_RELATED_ITEMS_EVENT;
export type CLEAR_SEARCH_DATA = typeof CLEAR_SEARCH_DATA_EVENT;

export interface LoadPeopleCompletedAction {
  type: LOAD_PEOPLE_COMPLETED,
  criteria: string,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadCountryCompletedAction {
  type: LOAD_COUNTRY_COMPLETED,
  criteria: string,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadCategoryCompletedAction {
  type: LOAD_CATEGORY_COMPLETED,
  criteria: string,
  tweets: Tweet[],
}

export interface LoadKeywordCompletedAction {
  type: LOAD_KEYWORD_COMPLETED,
  criteria: string,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadRelatedItemsAction {
  type: LOAD_RELATED_ITEMS,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadSearchNotCompletedAction {
  type: LOAD_SEARCH_NOT_COMPLETED,
}

export interface ClearSearchData {
  type: CLEAR_SEARCH_DATA,
}

export const loadRelatedNews = (criteria: string, type: SearchType, before: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const lastItemTime = before ? before : Date.now();
    const { data } = await axiosInstance.get(getUrlForSearchType(criteria, type, lastItemTime))
    if(before) {
      if(type === SearchType.PEOPLE) {
        dispatch( {
          type: LOAD_PEOPLE_COMPLETED_EVENT,
          criteria: criteria,
          tweets: data.news,
          videos: data.videos,
        });
      } else if(type === SearchType.COUNTRY) {
        dispatch( {
          type: LOAD_COUNTRY_COMPLETED_EVENT,
          criteria: criteria,
          tweets: data.news,
          videos: data.videos,
        });
      } else if(type === SearchType.CATEGORY) {
        dispatch( {
          type: LOAD_CATEGORY_COMPLETED_EVENT,
          criteria: criteria,
          tweets: data.news,
        });
      } else {
        dispatch( {
          type: LOAD_KEYWORD_COMPLETED_EVENT,
          criteria: criteria,
          tweets: data.news,
          videos: data.videos,
        });
      }
    } else {
      dispatch( {
        type: LOAD_RELATED_ITEMS_EVENT,
        tweets: data.news,
        videos: data.videos ? data.videos : [],
      });
    }
  }
}

export const markSearchStateAsUnloaded = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_SEARCH_NOT_COMPLETED_EVENT,
    });
  }
}

export const clearAllSearchData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: CLEAR_SEARCH_DATA_EVENT,
    });
  }
}

function getUrlForSearchType(criteria: string, type: SearchType, lastItemTime: number): string {
  if(type === SearchType.KEYWORD) {
    return `${searchRelatedItems}${encodeURIComponent(criteria)}&before=${lastItemTime}`
  } else if(type === SearchType.COUNTRY) {
    return `${countryRelatedItems}${encodeURIComponent(criteria)}&before=${lastItemTime}`
  } else if(type === SearchType.CATEGORY) {
    return `${categoryRelatedItems}${encodeURIComponent(criteria)}&before=${lastItemTime}`
  }
  return `${tagRelatedItems}${encodeURIComponent(criteria)}&before=${lastItemTime}`
}
