import * as React from "react";
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import icon_fb from "../../../resources/icons/facebook-icon-color.png";
import icon_x from "../../../resources/icons/logo_X.png";
import icon_linkedin from "../../../resources/icons/linkedin-icon-color.png";
import icon_insta from "../../../resources/icons/instagram-icon-color.png";
import "./FooterMenus.css";

const FooterMenus: React.FC = () => (
  <div className="py-3 d-flex flex-column justify-content-center">
    <div className="mx-1 text-center">
      <b>TVBIZZ 2022, All Rights Reserved</b>&nbsp;&nbsp;&nbsp;&nbsp;<br />
      <Link to="/about" className="folder_links">About us</Link>&nbsp;|&nbsp;
      <a href="mailto:info@tvbizz.net" className="folder_links">Contact</a>&nbsp;|&nbsp;
      <Link to="/privacy" className="folder_links">Privacy Policy</Link>&nbsp;|&nbsp;
      <Link to="/terms" className="folder_links">Terms of Use</Link>
    </div>
    <div className="mx-5 my-2 text-center">
      <a href="https://www.facebook.com/tvbizznet" rel="noreferrer" target="_blank" className="footer-social-link"><Image src={icon_fb} className="footer-social"/></a>
      <a href="https://twitter.com/tvbizznet" rel="noreferrer" target="_blank" className="footer-social-link"><Image src={icon_x} className="footer-social"/></a>
      <a href="https://www.linkedin.com/company/tvbizz" rel="noreferrer" target="_blank" className="footer-social-link"><Image src={icon_linkedin} className="footer-social"/></a>
      <a href="https://www.instagram.com/tvbizz/" rel="noreferrer" target="_blank" className="footer-social-link"><Image src={icon_insta} className="footer-social"/></a>
    </div>
  </div>
)

export default FooterMenus;
