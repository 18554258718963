import {RegionType} from "../../model/RegionType";
import {countries} from "../../const/menuConst";
import {MenuItemElement} from "../../model/MenuItemElement";

export function getEnumFromString(region: string): RegionType {
  const regionCheck = region.toLocaleUpperCase()
  if(regionCheck === 'USCAN') {
    return RegionType.USCAN
  } else if(regionCheck === 'WESTEUROPE') {
    return RegionType.WESTEUROPE
  } else if(regionCheck === 'LATAM') {
    return RegionType.LATAM
  } else if(regionCheck === 'ASIA') {
    return RegionType.ASIA
  } else if(regionCheck === 'AUSNZ') {
    return RegionType.AUSNZ
  } else if(regionCheck === 'CEE') {
    return RegionType.CEE
  } else if(regionCheck === 'AFRICA') {
    return RegionType.AFRICA
  } else if(regionCheck === 'MIDEAST') {
    return RegionType.MIDEAST
  }
  return RegionType.NA;
}

export function getEnumFromInt(region: number): RegionType | null {
  if(region === 1) {
    return RegionType.USCAN
  } else if(region === 2) {
    return RegionType.WESTEUROPE
  } else if(region === 3) {
    return RegionType.LATAM
  } else if(region === 4) {
    return RegionType.ASIA
  } else if(region === 5) {
    return RegionType.AUSNZ
  } else if(region === 6) {
    return RegionType.CEE
  } else if(region === 7) {
    return RegionType.AFRICA
  } else if(region === 8) {
    return RegionType.MIDEAST
  }
  return null;
}

export function getEnumFromCountry(country: string): RegionType | null {
  let regions = countries.filter(countryData => countryData.countries.includes(country))
  return regions.length > 0 ? regions[0].regionEnum : null
}

export function getCountiresFromEnumRegion(region: RegionType): string[] {
  let regions = countries.filter(countryData => countryData.regionEnum === region)
  return regions.length > 0 ? regions[0].countries : []
}

export function getStringFromEnum(region: RegionType): String | null {
  if(region === RegionType.USCAN) {
    return "US/Canada"
  } else if(region === RegionType.WESTEUROPE) {
    return "Western Europe"
  } else if(region === RegionType.LATAM) {
    return "Latin America"
  } else if(region === RegionType.ASIA) {
    return "Asia"
  } else if(region === RegionType.AUSNZ) {
    return "Australia and New Zealand"
  } else if(region === RegionType.CEE) {
    return "Central and Eastern Europe"
  } else if(region === RegionType.AFRICA) {
    return "Africa"
  } else if(region === 8) {
    return "Middle East"
  }
  return null;
}

export function getRegionFromCountry(country: String): RegionType {
  switch (country) {
    case 'United States':
    case 'Canada':
      return RegionType.USCAN
    case 'Austria':
    case 'Belgium':
    case 'Denmark':
    case 'Finland':
    case 'France':
    case 'Germany':
    case 'Italy':
    case 'Ireland':
    case 'Netherlands':
    case 'Norway':
    case 'Portugal':
    case 'Spain':
    case 'Switzerland':
    case 'Sweden':
    case 'United Kingdom':
      return RegionType.WESTEUROPE

    case 'Argentina':
    case 'Bolivia':
    case 'Brazil':
    case 'Chile':
    case 'Colombia':
    case 'Ecuador':
    case 'Mexico':
    case 'Panama':
    case 'Paraguay':
    case 'Peru':
    case 'Uruguay':
    case 'Venezuela':
      return RegionType.LATAM

    case 'Asia':
    case 'China':
    case 'Hong Kong':
    case 'India':
    case 'Japan':
    case 'Korea':
    case 'Philippines':
    case 'Singapore':
    case 'Thailand':
    case 'Vietnam':
      return RegionType.ASIA

    case 'Australia':
    case 'New Zealand':
      return RegionType.AUSNZ

    case 'Belarus':
    case 'Bosnia':
    case 'Bosnia and Herzegovina':
    case 'Bulgaria':
    case 'Czech Republic':
    case 'Croatia':
    case 'Estonia':
    case 'Greece':
    case 'Hungary':
    case 'Latvia':
    case 'Lithuania':
    case 'Macedonia':
    case 'North Macedonia':
    case 'Poland':
    case 'Romania':
    case 'Russia':
    case 'Serbia':
    case 'Slovakia':
    case 'Slovenia':
    case 'Turkey':
    case 'Ukraine':
      return RegionType.CEE

    case 'Africa':
      return RegionType.AFRICA

    case 'Middle East':
    case 'Algeria':
    case 'Egypt':
    case 'Israel':
    case 'Morocco':
    case 'Tunisia':
      return RegionType.MIDEAST

    default:
      return RegionType.NA
  }
}

export function setActiveRegion(list: MenuItemElement[], region: RegionType | null): MenuItemElement[] {
  // cancel all selections
  list.map(element => element.selected = false)
  // no region selected
  if(!region) {
    list[0].selected = true
    return list
  }
  // go through all regions
  if(region === RegionType.USCAN) {
    list[1].selected = true
  } else if(region === RegionType.WESTEUROPE) {
    list[2].selected = true
  } else if(region === RegionType.LATAM) {
    list[3].selected = true
  } else if(region === RegionType.ASIA) {
    list[4].selected = true
  } else if(region === RegionType.AUSNZ) {
    list[5].selected = true
  } else if(region === RegionType.CEE) {
    list[6].selected = true
  } else if(region === RegionType.AFRICA) {
    list[7].selected = true
  } else if(region === RegionType.MIDEAST) {
    list[8].selected = true
  } else {
    list[0].selected = true
  }
  return list
}
