import {AppState} from "../../reducers/rootReducer";
import {User} from "../../../model/User";
import {UserData} from "../../../model/UserData";


export function getUser(state: AppState): User | null {
  return state.user.user;
}

export function getUserData(state: AppState): UserData | null {
  return state.user.userData;
}

export function getCustomerKey(state: AppState): string | null {
  return state.user.stripeCustomerId;
}

export function isTrial(state: AppState): boolean {
  return state.user.isTrial;
}

export function isCeetv(state: AppState): boolean {
  return state.user.isCeetv;
}

export function isListView(state: AppState): boolean {
  return state.user.isListView;
}

export function isFree(state: AppState): boolean {
  return state.user.isFree;
}

export function isLocked(state: AppState): boolean {
  return state.user.isLocked
}

export function isExpired(state: AppState): boolean {
  return state.user.isExpired
}

