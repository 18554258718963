import * as React from 'react';
import {Container} from "react-bootstrap";
import {Nav} from "react-bootstrap";
import MainMenuItem from "./MainMenuItem/MainMenuItem";
import "./MainHeader.css"
import MainMenuContainer from "./MainMenuContainer/MainMenuContainer";

const MainHeader: React.FunctionComponent = () => (
  <Container className="main_toolbar" fluid>
    <Container className="main_toolbar" fluid>
      <Container className="sm-news-container p-0 d-flex justify-content-center">
          <MainMenuContainer/>
      </Container>
    </Container>  
    <Container className="main_toolbar container-second-row" fluid>
      <Container className="sm-news-container p-0 d-flex justify-content-center">
        <div className="menu-second-row col-xl-9 col-lg-12 col-md-12 main_menu_background justify-content-center px-0"> 
          <Nav.Item className="menu_item_b_left no-line-item">
            <a href={'/news'} className="menu_script">News</a>
          </Nav.Item>
          {/*<NavDropdown title="News" id="news-nav-dropdown-sub" className="news-nav-dropdown menu_script">
            <span className="sub-item-indicator-news"/>
            <div className="row-big">
              <ul className="clmn-big p-70">
                <div className="nav-category">Regions</div>
                <MenuCountry sectionUrl={"news"} />
              </ul>
            </div>
          </NavDropdown>*/}
          <MainMenuItem linkAddress="watch" caption="Watch" />
          <MainMenuItem linkAddress="trending" caption="Trending"/>
        </div>
      </Container> 
    </Container>   
  </Container>
)

export default MainHeader;
