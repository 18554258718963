import {Company} from "../../../model/Company";
import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";
import {Dispatch} from "react";
import {axiosInstance} from "../../../const/axiosClient";
import {companyData, tagRelatedItems} from "../../../const/urlConst";

export const LOAD_COMPANY_COMPLETED_EVENT = 'LOAD_COMPANY_COMPLETED';
export const LOAD_COMPANY_NOT_COMPLETED_EVENT = 'LOAD_COMPANY_NOT_COMPLETED';
export const LOAD_COMPANY_RELATED_ITEMS_EVENT = 'LOAD_COMPANY_RELATED_ITEMS';
export const CLEAR_COMPANY_DATA_EVENT = 'CLEAR_COMPANY_DATA';

export type LOAD_COMPANY_COMPLETED = typeof LOAD_COMPANY_COMPLETED_EVENT;
export type LOAD_COMPANY_NOT_COMPLETED = typeof LOAD_COMPANY_NOT_COMPLETED_EVENT;
export type LOAD_COMPANY_RELATED_ITEMS = typeof LOAD_COMPANY_RELATED_ITEMS_EVENT;
export type CLEAR_COMPANY_DATA = typeof CLEAR_COMPANY_DATA_EVENT;

export interface LoadCompanyCompletedAction {
  type: LOAD_COMPANY_COMPLETED,
  company: Company | null,
}

export interface LoadCompanyRelatedItemsAction {
  type: LOAD_COMPANY_RELATED_ITEMS,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadCompanyNotCompletedAction {
  type: LOAD_COMPANY_NOT_COMPLETED,
}

export interface ClearCompanyData {
  type: CLEAR_COMPANY_DATA,
}

export const loadCompany = (companyName: string) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${companyData}${encodeURIComponent(companyName)}`);
    dispatch( {
      type: LOAD_COMPANY_COMPLETED_EVENT,
      company: data.found ? {
        companyId: data.company.companyId,
        name: data.company.name,
        logo: data.company.logo,
        country: data.company.country,
        webpage: data.company.website,
      } : null
    });
  }
}

export const loadCompanyRelatedData = (companyName: string, before: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const lastItemTime = before ? before : Date.now();
    const { data } = await axiosInstance.get(`${tagRelatedItems}${encodeURIComponent(companyName)}&before=${lastItemTime}`);
    dispatch( {
      type: LOAD_COMPANY_RELATED_ITEMS_EVENT,
      tweets: data.news,
      videos: data.videos,
    });
  }
}

export const markCompanyStateAsUnloaded = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_COMPANY_NOT_COMPLETED_EVENT,
    });
  }
}

export const clearCompanyState = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: CLEAR_COMPANY_DATA_EVENT,
    });
  }
}
