import React, {Dispatch} from "react";
import {Button, Col, Form, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Formik, FormikHelpers} from "formik";
import {subscribeFormValidation} from "../SignUp/SigninValidation/subscribeFormValidation";
import {createUserSubscription} from "../../hooks/user/subscriptionFunctions";
import {SUBSCRIPTION_PRICE_ID} from "../../const/valueConst";
import {AppState} from "../../store/reducers/rootReducer";
import {getCustomerKey} from "../../store/selectors/user/user.selector";
import {updateCurrentUser} from "../../hooks/initial/loadInitial";
import {connect} from "react-redux";

interface BillingAddressInfo {
  company: string,
  address: string,
  zip: string,
  country: string,
  city: string,
}

interface StripeCustomer {
  customerId: string | null,
}

interface DispatchStripeFunctions {
  updateUser: () => void,
}

const mapStateToProps = (state: AppState): StripeCustomer => {
  return {
    customerId: getCustomerKey(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchStripeFunctions => {
  return {
    updateUser: () => updateCurrentUser(dispatch),
  }
}

const UserTabsStripePayment: React.FC<StripeCustomer & DispatchStripeFunctions> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  return (
    <Row>
      {props.customerId && <div className="m-auto">
        <Formik
          initialValues={{ company: "", address: "", country: "", city: "", zip: ""}}
          validationSchema={subscribeFormValidation}
          onSubmit={async (billing: BillingAddressInfo, helpers:FormikHelpers<BillingAddressInfo>) => {
            helpers.setSubmitting(true)
            if (!stripe || !elements) {
              helpers.setStatus({
                isError: true,
                errorMsg: "The payment system is not available - please, try again later",
              })
              helpers.setSubmitting(false)
              return;
            }
            const cardElement = elements.getElement(CardElement);
            if(cardElement) {
              const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
              });
              if (error || !paymentMethod) {
                helpers.setStatus({
                  isError: true,
                  errorMsg: "Error registering your card - please, try again later",
                })
              } else {
                console.log('[PaymentMethod]', paymentMethod);
                await createUserSubscription(
                  props.customerId ? props.customerId : '',
                  paymentMethod.id,
                  SUBSCRIPTION_PRICE_ID,
                  billing.company, billing.country, billing.city,
                  billing.address, billing.zip,
                  (error: string) => {
                    helpers.setStatus({
                      isError: true,
                      errorMsg: error,
                    })
                  },
                  stripe,
                  props.updateUser,
                )
                helpers.setSubmitting(false)
              }
            }
          }}
        >
          {
            ({
               values,
               errors,
               touched,
               status,
               isSubmitting,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
              <Form onSubmit={handleSubmit}>
                {status && status.isError && <div className="error-message">{status.errorMsg}</div>}
                <h2 className="my-4">Please, fill in your payment informtion</h2>
                <FormGroup controlId="formGridAddress1">
                  <FormLabel>Credit Card*</FormLabel>
                  <CardElement options={{
                    style: {
                      base: {
                        fontSize: "15px",
                        fontFamily: "'Arial', sans-serif",
                        iconColor: "#674CFF",
                        padding: "2px",
                        color: "#674CFF",
                        '::placeholder': {
                          color: "#4b525a",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}/>
                </FormGroup>

                <FormGroup controlId="formGridAddress1">
                  <FormLabel>Company</FormLabel>
                  <FormControl
                    type="text"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.company && errors.company ? "error-border" : ""}
                    placeholder="Company legal name"
                  />
                  {touched.company && errors.company ? (
                    <div className="error-message">{errors.company}</div>
                  ) : <div className="error-message">&nbsp;</div>}
                </FormGroup>

                <FormGroup controlId="formGridAddress2">
                  <FormLabel>Address*</FormLabel>
                  <FormControl
                    type="text"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={touched.address && errors.address ? "error-border" : ""}
                    placeholder="Billing address"
                  />
                  {touched.address && errors.address ? (
                    <div className="error-message">{errors.address}</div>
                  ) : <div className="error-message">&nbsp;</div>}
                </FormGroup>

                <Row>
                  <FormGroup as={Col} controlId="formGridCity">
                    <FormLabel>City*</FormLabel>
                    <FormControl
                      type="text"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.city && errors.city ? "error-border" : ""}
                      placeholder="City"
                    />
                    {touched.city && errors.city ? (
                      <div className="error-message">{errors.city}</div>
                    ) : <div className="error-message">&nbsp;</div>}
                  </FormGroup>

                  <FormGroup as={Col} controlId="formGridZip">
                    <FormLabel>Country*</FormLabel>
                    <FormControl
                      type="text"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.country && errors.country ? "error-border" : ""}
                      placeholder="Country"
                    />
                    {touched.country && errors.country ? (
                      <div className="error-message">{errors.country}</div>
                    ) : <div className="error-message">&nbsp;</div>}
                  </FormGroup>

                  <FormGroup as={Col} controlId="formGridZip">
                    <FormLabel>Zip</FormLabel>
                    <FormControl
                      type="text"
                      name="zip"
                      value={values.zip}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={touched.zip && errors.zip ? "error-border" : ""}
                      placeholder="ZIP code"
                    />
                    {touched.zip && errors.zip ? (
                      <div className="error-message">{errors.zip}</div>
                    ) : <div className="error-message">&nbsp;</div>}
                  </FormGroup>

                </Row>

                <Col sm="12" lg="12" xl="12" className="my-3 text-center">
                  <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                    {!isSubmitting && "Pay and Subscribe"}
                    {isSubmitting && <div className="button-loader">
                      <div className="dot1"/>
                      <div className="dot2"/>
                      <div className="dot3"/>
                    </div>}
                  </Button>
                </Col>
              </Form>
            )
          }
        </Formik>
      </div>}
    </Row>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTabsStripePayment);
