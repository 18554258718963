import React from 'react';
import {Button} from "react-bootstrap";
import {Image} from "react-bootstrap";
import icon_signup from "../../../../resources/icons/signup.svg";
import icon_login from "../../../../resources/icons/login.svg";

const NotSignedUserMenu: React.FC = () => (
  <div className="d-flex sign-menu-div">
    {/*<MainMenuItem linkAddress="signin" caption="Sign in"/>*/}
    <div className="menu_item_mobile_button">
          <Button variant="sign-up-button" className="sign-up-button mr-1" type="submit" href="/signin">
              <Image src={icon_login} className="icon-menu" title="Sign in with your account"/>
          </Button>
    </div>
    <div className="menu_item_mobile_button">
      <Button variant="sign-up-button" className="sign-up-button mr-1" type="submit" href="/signup">
        <Image src={icon_signup} className="icon-menu" title="Sign up for a new account"/>
      </Button>
    </div>
  </div>
)

export default NotSignedUserMenu;
