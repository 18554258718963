import * as React from "react";
import "./TrendingArticles.css";
import TrendingArticlesList from "./TrendingArticlesList/TrendingArticlesList";
import {Image} from "react-bootstrap";
// import {Link} from "react-router-dom";
import {Tweet} from "../../../../../model/Tweet";
import {RouteComponentProps} from "react-router-dom";
import icon_office from "../../../../../resources/icons/office.svg";
import icon_human from "../../../../../resources/icons/human.svg";
import icon_see from "../../../../../resources/icons/see.svg";
import icon_globe from "../../../../../resources/icons/globe.svg";
import {TrendingItem} from "../../../../../model/TrendingItem";
import TrendingTagList from "./TrendingTagList/TrendingTagList";


interface TrendingData {
  trending: Tweet[],
  companyTags: TrendingItem[],
  showTags: TrendingItem[],
  peopleTags: TrendingItem[],
}

interface TrendingTabState {
  tabState: number,
}

type TredingProps = TrendingData & RouteComponentProps;

class TrendingArticles extends React.Component<TredingProps, TrendingTabState> {

  state = {
    tabState: 0,
  }

  render() {
    return (
        <React.Fragment>
          <div className="trending_list_headline_title">
            <div onClick={() => this.props.history.push('/trending')} className="trending_list_underline m-0 force-cursor-on-div">Trending now</div>
          </div>
          <div className="card-height p-0">
            <div className="row trending-icons">
              <div className={`col-3 ${this.state.tabState === 0 ? 'trending-first-icon-selected' : 'trending-first-icon'} div-tab correct-cursor-for-tabs`} onClick={() => this.setState({tabState: 0})}>
                <Image src={icon_globe} className="icon-tabs"/> <span className="text-tabs">NEWS</span>
              </div>
              <div className={`col-3 ${this.state.tabState === 1 ? 'trending-second-icon-selected' : 'trending-second-icon'} div-tab correct-cursor-for-tabs`} onClick={() => this.setState({tabState: 1})}>
                <Image src={icon_office} className="icon-tabs"/><span className="text-tabs">COMPANIES</span>
              </div>
              <div className={`col-3 ${this.state.tabState === 2 ? 'trending-third-icon-selected' : 'trending-third-icon'} div-tab correct-cursor-for-tabs`} onClick={() => this.setState({tabState: 2})}>
                <Image src={icon_see} className="icon-tabs"/><span className="text-tabs">SHOWS</span>
              </div>
              <div className={`col-3 ${this.state.tabState === 3 ? 'trending-last-icon-selected' : 'trending-last-icon'} div-tab correct-cursor-for-tabs`} onClick={() => this.setState({tabState: 3})}>
                <Image src={icon_human} className="icon-tabs"/><span className="text-tabs">PEOPLE</span>
              </div>
            </div>
            <div className="row margin-one">
              {this.state.tabState === 0 && <TrendingArticlesList trending={this.props.trending} />}
              {this.state.tabState === 1 && <TrendingTagList trendingTags={this.props.companyTags} />}
              {this.state.tabState === 2 && <TrendingTagList trendingTags={this.props.showTags} />}
              {this.state.tabState === 3 && <TrendingTagList trendingTags={this.props.peopleTags} />}
            </div>
          </div>
        </React.Fragment>
    )
  }
}

export default TrendingArticles;
