import React from "react";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {Link} from "react-router-dom";

interface ModalSimpleCloseFunction {
  closeDialog: () => void
}

const ModalButtonsSimple: React.FC<ModalSimpleCloseFunction> = (props) => (
  <Modal.Footer>
    <div className="modal-buttons">
      <Button onClick={props.closeDialog} variant="tvbizz-primary" className="tvbizz-primary modal-button">
        Ok
      </Button>
    </div>
    <div className="learn-more"><Link to={'/learnmore'}>Learn more</Link> about TVBIZZ account benefits</div>
  </Modal.Footer>
)

export default ModalButtonsSimple;
