import {AppState} from "../../reducers/rootReducer";
import {TweetVideo} from "../../../model/TweetVideo";

export function getVideos(state: AppState): TweetVideo[] {
  return state.videos.videos;
}

export function getMainVideo(state: AppState): TweetVideo | null {
  return state.videos.mainVideo
}

export function getHeadlineVideos(state: AppState): TweetVideo[] {
  return state.videos.headlines
}

export function getRelatedVideos(state: AppState): TweetVideo[] {
  return state.videos.relatedVideos
}

export function getLatestRelatedVideos(state: AppState): TweetVideo[] {
  return state.videos.relatedLatestVideos
}

export function isLoading(state: AppState): boolean {
  return state.videos.loading
}

export function getVideoById(state: AppState, videoId: number): TweetVideo | null {
  let allVideos: TweetVideo[] = state.videos.selectedVideoItem ? [state.videos.selectedVideoItem] : [];
  allVideos = allVideos.concat(
    (state.videos.mainVideo ? [state.videos.mainVideo] : []),
    state.videos.headlines,
    state.videos.videos,
    state.videos.relatedVideos,
    state.videos.relatedLatestVideos)
  const filterArray = allVideos.filter(video => video.tweetVideoId === videoId)
  return filterArray.length > 0 ? filterArray[0] : null
}

export function getRegion(state:AppState) {
  return state.videos.regionCriteria
}

export function getCountry(state:AppState) {
  return state.videos.countryCriteria
}
