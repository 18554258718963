import {AppState} from "../../reducers/rootReducer";
import {Banner} from "../../../model/Banner";

export function getBannersLeft(state: AppState): Banner[] {
  return state.banners.frontPageLeft
}

export function getBannersRight(state: AppState): Banner[] {
  return state.banners.frontPageRight
}

export function getBannersInnerLeft(state: AppState): Banner[] {
  return state.banners.innerPageLeft
}

export function getBannersInnerRight(state: AppState): Banner[] {
  return state.banners.innerPageRight
}
