import * as React from "react";
import {Col, Row} from "react-bootstrap";
import VideoHeadline from "./VideoHeadline/VideoHeadline";
import LatestArticles from "./LatestArticles/LatestArticles";
import "./TrendingPane.css";
import TrendingArticles from "./TrendingArticles/TrendingArticles";
import {Tweet} from "../../../../model/Tweet";
import {TweetVideo} from "../../../../model/TweetVideo";
import {TrendingItem} from "../../../../model/TrendingItem";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

interface NewsData {
  trending: Tweet[],
  latest: Tweet[],
  mainVideo: TweetVideo | null,
  companyTags: TrendingItem[],
  showTags: TrendingItem[],
  peopleTags: TrendingItem[],
  trendingLoadComplete: boolean,
}

// @ts-ignore
const TrendingPane: React.FC<NewsData> = (props) => {
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  return (
      <div className="pane_center_row w-100">
        <Row className="pane_center_row w-100 p-0 d-flex justify-content-center">
          <Col className="m-auto px-0">
            <Row className="px-0">
              <Col md={{span: 6}} xl={{span: 5}} lg={{span:4}} sm={{span:12}} className="px-0">
                {props.mainVideo && <VideoHeadline video={props.mainVideo} />}
              </Col>
              <Col md={{span: 6}} xl={{span: 7}} lg={{span:8 }} sm={{span:12}} className="sm-px-0 pr-0">
                <TrendingArticles
                    trending={props.trending.length > 5 ? props.trending.slice(0, 5) : props.trending}
                    companyTags={props.companyTags.length > 5 ? props.companyTags.slice(0, 5) : props.companyTags}
                    showTags={props.showTags.length > 5 ? props.showTags.slice(0, 5) : props.showTags}
                    peopleTags={props.peopleTags.length > 5 ? props.peopleTags.slice(0, 5) : props.peopleTags}
                    history={history}
                    location={location}
                    match={match}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{span: 12}} className="mt-2 px-0">
                {props.trendingLoadComplete && <LatestArticles latest={props.latest} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
  )
}

export default TrendingPane;
