import React from "react";
import "./Loader.css";

const Loader: React.FC = () => (
  <div className="headline_center_row justify-content-center center_data_on_page">
    {/*<Image src={loader} alt='Loading...' className="lodaer" />*/}
  </div>
);

export default Loader;
