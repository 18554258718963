import * as React from "react";
import axios from "axios";
import ReactCrop from "react-image-crop";
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Col, FormControl, FormGroup, Row} from "react-bootstrap";
import '../../UserSettings/UserSettings.css';
import "../Modals.css"
import {axiosInstance} from "../../../const/axiosClient";
import {updateProfileImage} from "../../../const/urlConst";
import {updateUserAfterPicture} from "../../../hooks/initial/loadInitial";



class PictureSetupModal extends React.Component {

  state = {
    fileSource: null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 16 / 16
    },
    croppedImageUrl: null,
    updatingImage: false,
    errorMsg: null,
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ fileSource: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'image_file.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  uploadFileRequest = ({ file }) => {
    const data = new FormData();
    data.append('image', file, file.name);

    return axios.post(`/upload/image`, data, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
      timeout: 30000,
    });
  };

  createCanvas(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return canvas
  }

  uploadCompletedImage = () => {
    this.setState({ updatingImage: true })
    const image = this.imageRef
    const crop = this.state.crop
    const fileName = `image_file_${new Date().getTime()}.jpeg`
    const canvas = this.createCanvas(image, crop, fileName)
    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          this.setState({
            updatingImage: false,
            errorMsg: 'Invalid image formating or image packaging',
          })
          return;
        }
        blob.name = fileName;
        const data = new FormData();
        data.append('image', blob, blob.name);
        const upload = await axiosInstance.post(`${updateProfileImage}`, data, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          },
          timeout: 30000,
        });
        if(upload.data.result) {
          await updateUserAfterPicture(this.props.dispatch)
          this.handleUploadSuccess()
        } else {
          this.setState({
            updatingImage: false,
            errorMsg: upload.data.errorMsg,
          })
        }
      },
      'image/jpeg',
      1
    );
  }

  handleUploadSuccess() {
    this.setState({ updatingImage: false })
    this.props.closeDialog()
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = this.createCanvas(image, crop, fileName)
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  render() {
    const { crop, croppedImageUrl, fileSource } = this.state;
    return (
      <Modal show={this.props.showDialog} className="modal-paid-news">
        <Modal.Header className="text-center justify-content-center">
          <Modal.Title>Profile Image Update</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center text-center">
          <div className="text-news">
            <h6 className="mb-2">Upload the image file for cropping</h6>
            <FormGroup controlId="formFile" className="mb-3">
              <FormControl type="file" size="sm" accept="image/*" onChange={this.onSelectFile} />
            </FormGroup>
            <Row className="pic-crop">
              <Col md={6}>
                {fileSource && (
                  <ReactCrop
                    src={fileSource}
                    crop={crop}
                    imageStyle={{ maxHeight: '750px' }}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </Col>
              <Col md={4} >
                {croppedImageUrl && (
                  <img className="profile_photo" alt="Crop" style={{ maxWidth: '50%' }} src={croppedImageUrl} />
                )}
                {(this.state.errorMsg && this.state.errorMsg.length > 0) ? (
                  <div className="error-message" style={{ fontSize: '14px' }}>{this.state.errorMsg}</div>
                ) : <div className="error-message">&nbsp;</div>}
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="modal-buttons m-auto">
            <Button onClick={this.uploadCompletedImage} variant="tvbizz-primary" className="tvbizz-primary modal-button mx-2">
              {!this.state.updatingImage && "Change Image"}
              {this.state.updatingImage && <div className="button-loader">
                <div className="dot1"/>
                <div className="dot2"/>
                <div className="dot3"/>
              </div>}
            </Button>
            <Button onClick={this.props.closeDialog} variant="tvbizz-outline" className="tvbizz-outline-primary modal-button">
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

PictureSetupModal.propTypes = {
  showDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  dispatch: PropTypes.object,
};

export default PictureSetupModal;
