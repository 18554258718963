import * as React from "react";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import UserTabMyContent from "./UserTabMyContent";
import UserTabSecurity from "./UserTabSecurity";
import UserTabUpgradeContent from "./UserTabUpgradeContent";
import "./UserSettings.css";
import {AppState} from "../../store/reducers/rootReducer";
import {Dispatch} from "react";
import {User} from "../../model/User";
import {UserData} from "../../model/UserData";
import {getUser, getUserData, isFree} from "../../store/selectors/user/user.selector";
import {connect} from "react-redux";
import {loadAllUserData} from "../../hooks/initial/loadInitial";
import UserTabTrialSubscContent from "./UserTabTrialSubscContent";
import {registerStripeCustomer} from "../../hooks/user/userFunctions";
import {RouteComponentProps} from "react-router-dom";
import UserTabPrepaidSubscrContent from "./UserTabPrepaidSubscContent";
import * as H from "history";

interface UserPropertiesFromState {
  user: User | null,
  userData: UserData | null,
  isFree: boolean,
  selectedTab: string,
}

interface DispatchFunctions {
  initializeUserData: () => void,
  getConsumerId: (
    email: string,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
}

type UserProps = UserPropertiesFromState & DispatchFunctions & RouteComponentProps;

function getSelectedTab(location: string): string {
  if(location.endsWith("security")) {
    return "security"
  } else if(location.endsWith("subscription")) {
    return "subscription"
  } else if(location.endsWith("upgrade")) {
    return "upgrade"
  }
  return "tvbizz"
}

function setSelectedTab(tab: string, history: H.History) {
  if(tab === "security") {
    return history.push('/profile/security')
  } else if(tab === "subscription") {
    return history.push('/profile/subscription')
  } else if(tab === "upgrade") {
    return history.push('/profile/upgrade')
  }
  return history.push('/profile')
}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps): UserPropertiesFromState => {
  return {
    user: getUser(state),
    userData: getUserData(state),
    isFree: isFree(state),
    selectedTab: getSelectedTab(ownProps.location.pathname)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchFunctions => {
  return {
    initializeUserData: () => loadAllUserData(dispatch),
    getConsumerId: (
      email: string,
      handleSuccess: () => void,
      handleError: () => void) => registerStripeCustomer(email, handleSuccess, handleError, dispatch),
  }
}

class UserSettingsTabs extends React.Component<UserProps> {

  componentDidMount() {
    this.props.initializeUserData()
  }

  render() {
    return (
        <Container>
          <Row className="settings_main_row pb-0 justify-content-between">
            <Col xs="12" sm="12" lg="8" className="align-items-center">
              {this.props.user && <Tabs
                id="controlled-tab-example"
                activeKey={this.props.selectedTab}
                onSelect={(tab) => setSelectedTab(tab ?? 'tvbizz', this.props.history)}
              >
                <Tab eventKey="tvbizz" title="My TVBIZZ" className="settings_tab_label">
                  <UserTabMyContent />
                </Tab>
                {!this.props.isFree && <Tab eventKey="subscription" title="Subscription" className="settings_tab_label">
                  <UserTabPrepaidSubscrContent expiryDate={this.props.user.expiryDate} />
                </Tab>}
                {this.props.isFree && <Tab eventKey="subscription" title="Subscription" className="settings_tab_label">
                  <UserTabTrialSubscContent />
                </Tab>}
                {this.props.isFree && <Tab eventKey="upgrade" title="Upgrade" className="settings_tab_label">
                  <UserTabUpgradeContent
                    email={this.props.user.email}
                    getConsumerId={this.props.getConsumerId}
                  />
                </Tab>}
                <Tab eventKey="security" title="Security" className="settings_tab_label">
                  <UserTabSecurity />
                </Tab>
              </Tabs>}
            </Col>
          </Row>
        </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettingsTabs);
