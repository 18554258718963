import * as React from "react";
// import HeadlineArticle from "../../../CommonComponents/HeadlineArticle/HeadlineArticle";
import {Tweet} from "../../../../../../model/Tweet";
import {Col} from "react-bootstrap";
import {Image} from "react-bootstrap";
import TrendingHeadlineArticle from "../../../CommonComponents/TrendingHeadlineArticle/TrendingHeadlineArticle";
import icon_list from "../../../../../../resources/icons/icon-list.svg";

interface TrendingData {
  trending: Tweet[],
}

const TrendingArticlesList: React.FC<TrendingData> = (props) => (
  <React.Fragment>
    {
      props.trending.slice(0, 5).map((newsItem, index) => {
        return (
          <Col key={newsItem.tweetId} md={{span: 12}} className="d-flex flex-row justify-content-start align-content-center margin-big-screen first-page-trending-list">
            <div className="small-list-icons"><Image src={icon_list} className="icon-list"/> </div>
            <TrendingHeadlineArticle key={newsItem.tweetId} article={newsItem} articleNo={index} withDate={false} />
          </Col>
        )
      })
    }
  </React.Fragment>
)

export default TrendingArticlesList;
