import * as React from "react";
import {Col, Container, Row, Image} from "react-bootstrap";
import logo_tvbizz from "../../../../resources/images/TVBIZZ_logo_grey-web.png";
import logo_tvbizzmagazine from "../../../../resources/images/tvbizz-magazine-logo-web.png";
import logo_ceetv from "../../../../resources/images/ceetv-logo-web.png";
import logo_pitchplay from "../../../../resources/images/pitchplaz-web.png";

const AboutUs: React.FC = () => {
  window.scrollTo(0, 0)
  return (
    <React.Fragment>
      <Container>
        <Row className="pane_center_row about-cont p-0 d-flex justify-content-center">
          <Col xl={{span: 8}} lg={{span:8}} md={{span: 12}} className="m-auto p-0">
            <Row>
              <Col>
                <h3 className="w-100 my-4">About us</h3>
              </Col>
            </Row>
            <Row>
              <Col className="my-4">
                <p>
                  Established in 2012, TVBIZZ is an industry intelligence service for
                  TV professionals around the world. Using local sources, TVBIZZ delivers
                  daily more than 50 news reports from the global TV industry about programming,
                  formats, ratings, deals, acquisitions, appointments, digital; etc.
                  Program managers, buyers, distributors and researchers from more than
                  30 countries use TVBIZZ for their business decisions. TVBIZZ is part of TVBIZZ Group
                  that owns and operates CEETV, TVBIZZ and TVBIZZ Magazine.
                </p>
              </Col>
            </Row>
            <Row className="m-4">
              <Col className="about-container">
                <a href="https://ceetv.net" target="_blank" rel="noreferrer" className="footer-social-link"><Image src={logo_ceetv} className="about-logo m-4"/></a>
                <a href="https://poc.tvbizz.net" target="_blank" rel="noreferrer" className="footer-social-link"><Image src={logo_tvbizz} className="about-logo m-4"/></a>
                <a href="https://www.tvbizzmagazine.com/" target="_blank" rel="noreferrer" className="footer-social-link"><Image src={logo_tvbizzmagazine} className="about-logo m-4
                "/></a>
                <a href="https://pitchplay.tv/" target="_blank" rel="noreferrer" className="footer-social-link"><Image src={logo_pitchplay} className="about-logo m-4"/></a>
              </Col>
            </Row>
          </Col>
        </Row>  
      </Container>
    </React.Fragment>
  );
}

export default AboutUs;
