import React from "react";
import {Link} from "react-router-dom";
import {buildArticleLink} from "../../../utils/article/articleUtils";

interface NewsTitleData {
  title: string,
  isPaid: boolean,
  isPr: boolean,
  newsId: number,
  ceetv: boolean,
}

const NewsTitle: React.FC<NewsTitleData> = (props) => (
    <React.Fragment>
      <Link to={buildArticleLink(props.newsId, props.ceetv)} className="headline-link">{props.title}</Link>&nbsp;&nbsp;
    </React.Fragment>
)

export default NewsTitle;
