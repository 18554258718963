import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

const PrivacyPage: React.FC = () => {
  window.scrollTo(0, 0)
  return (
    <React.Fragment>
      <Container>
        <Row className="pane_center_row about-cont p-0 d-flex justify-content-center">
          <Col xl={{span: 8}} lg={{span:8}} md={{span: 12}} className="m-auto p-0">
            <Row>
              <Col>
                <h3 className="w-100 my-4">Privacy policy</h3>
              </Col>
            </Row>
            <Row>
              <Col className="my-4">
                <p>
                  <span style={{fontWeight: 800}}>PRIVACY POLICY</span>
                  <br/><br/>
                  This online privacy policy is designed to tell you about how we collect and use information so that
                  you can make an informed choice about using this site. Our policy complies with the General Data
                  Protection Regulation of EU.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>WHAT INFORMATION DO WE COLLECT?</span>
                  <br/><br/>
                  We collect the domain name and e-mail address of visitors to our webpage, the e-mail addresses of
                  those who communicate with us via e-mail; we aggregate user-specific information such as what pages
                  customers access or visit and information volunteered by the customers, such as survey information
                  and/or site registrations. When you undertake to register yourself or purchase a product on this site
                  we ask for your name, e-mail address, company name, address, post code, phone number, job title,
                  industry, and business interests. Once you register with us and sign in to our services (paid or
                  otherwise) you are not anonymous to us. In order to tailor our subsequent communications to you and
                  continuously improve our products and services, we may also ask you to provide us with information
                  regarding your professional interests, experience with our products, and more detailed contact
                  preferences.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>USE OF PERSONAL INFORMATION</span>
                  <br/><br/>
                  The information we collect is used to facilitate the service, notify customers about updates to our
                  website and improve the content of our webpage.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>THIRD PARTIES</span>
                  <br/><br/>
                  We do not provide personal information of our customers to third parties.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>USE OF COOKIES</span>
                  <br/><br/>
                  A cookie is a small text file written to a user's hard drive that contains the User ID. The cookies do
                  not contain any personal information about users and in themselves only record those areas of the site
                  that have been visited by the computer in question, and for how long.
                  <br/><br/>
                  The use of cookies is now widespread by sites in order to track traffic flows. We use cookies to
                  enable us to offer certain time-saving features to our users such as, one-time log in and
                  pre-populating name and address fields. If a user sets up his browser to reject the cookie, he or she
                  may still use the site although functionality will be impaired.
                  <br/><br/>

                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>LOG FILES</span>
                  <br/><br/>
                  We use cookies and log files to administer the site, track users' movements and actions, and gather
                  broad demographic information for aggregate use. The information gathered can thus be used to improve
                  the site and the user experience.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>DATA COLLETCTION</span>
                  <br/><br/>
                  In accordance with the General Data Protection Regulation (EU 2016/679) TVBIZZ Ltd. collect and
                  process your personal data. Insofar, TVBIZZ Ltd. ("Krasna Polyana" 3, Bl. 340 A; fl. 16; ap. 61,
                  Sofia, 1330 g.k. Krasna polyana 3, Sofia, Bulgaria) plays the role of Data Processor. You are able at
                  all times to exercise control over your data by being the Data Controller.
                  <br/>
                  We collect personal data for the sole purpose of being able to authorize you to our system and to
                  maintain customer contacts with you. None of the information you is provided to third parties. See
                  WHAT INFORMATION DO WE COLLECT? for a detailed information of the type of information we collect. By
                  subscribing to TVBIZZ you give us the right to store your data as a Data Processor in accordance with
                  the requirements of GDPR.
                  <br/><br/>
                  In accordance with GDPR you retain the following rights:
                  <br/>&nbsp;&nbsp;You can request at all times information regarding the data we have collected for you
                  and your account. You can also request and receive confirmation about our processing of your data in
                  accordance with article 15.1 of the GDPR.
                  <br/>&nbsp;&nbsp;You can request all collected personal data to be provided on a physical carier. We
                  will handle such request will all the urgency required
                  <br/>&nbsp;&nbsp;You can revoke at all time your consent to have your personal data processed and
                  stored by us. Look at the section USER'S RIGHT TO OPT OUT for more information. At the moment we are
                  not able to provice your data to other Data Processors.
                  <br/>&nbsp;&nbsp;You can request the existing stored and processed data to be deleted. Please, submit
                  a request to <a href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                                  className="linkunderlineblue">admin@tvbizz.net</a> to request that.
                  <br/>&nbsp;&nbsp;You can request the change of your existing personal information. Please, look at the
                  section ACCESSING AND UPDATING PERSONAL INFORMATION on more details on how to achieve that.
                  <br/>&nbsp;&nbsp;You can request to be "forgotten" on our site by erasing all personal data we have
                  collected from you. Please, read the section DELETION OF DATA on how to do that.
                  <br/>&nbsp;&nbsp;All complaints that regard the storage and processing of personal data by TVBIZZ Ltd.
                  could be sent to the Commission for Personal Data Protection of Bulgaria.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>USER’S RIGHT TO OPT OUT</span>
                  <br/><br/>
                  If you do not want to receive e-mail from us in the future, please let us know by sending an e-mail
                  to <a href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                        className="linkunderlineblue">admin@tvbizz.net</a>.
                  <br/>
                  If you supply us with your postal address online you may receive periodic mailings from TVBIZZ Ltd.
                  with information on new products and services or upcoming events. If you do not wish to receive such
                  mailings, please let us know by sending an e-mail to <a href="mailto:admin@tvbizz.net"
                                                                          style={{color: '#5d8ab4;'}}
                                                                          className="linkunderlineblue">admin@tvbizz.net</a>.
                  <br/>
                  Persons who supply us with their telephone and fax numbers online may be contacted from TVBIZZ Ltd.
                  with information regarding new products and services or upcoming events. If you do not wish to receive
                  such telephone calls or fax messages, please let us know by sending an e-mail to <a
                  href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                  className="linkunderlineblue">admin@tvbizz.net</a>.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>ACCESSING AND UPDATING PERSONAL INFORMATION</span>
                  <br/><br/>
                  Upon request we offer visitors the opportunity to access and correct information that we maintain
                  about them. This includes proprietary information that we maintain about them such as transaction
                  information (e.g. types of customer purchases and contact information e.g. name, address, phone
                  number, e-mail). Customers can request this information by e-mailing us at <a
                  href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                  className="linkunderlineblue">admin@tvbizz.net</a>. Reasonable individual requests will be fulfilled
                  at no charge.
                  <br/>
                  If a user's personally identifiable information changes, or if a user no longer desires to use our
                  service, they can notify us by sending an e-mail to <a href="mailto:admin@tvbizz.net"
                                                                         style={{color: '#5d8ab4;'}}
                                                                         className="linkunderlineblue">admin@tvbizz.net</a>.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>PERIOD OF GRACE</span>
                  <br/><br/>
                  We will update your records as promptly as practical; however, you may still receive communication
                  from us for a transitional period whilst we process your records.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>DELETION OF DATA</span>
                  <br/><br/>
                  After expiration of your account the user records will be automatically deleted from our system. If a
                  user cancels his account, he can explicitly request record deletion at cancellation time. In
                  compliance with the requirements of GDPR a user can also explicitly request the erasure of all
                  personal data. Please, send a request to <a href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                                                              className="linkunderlineblue">admin@tvbizz.net</a> to do
                  that.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>SECURITY</span>
                  <br/><br/>
                  This website takes every precaution to protect its users' information. When users submit sensitive
                  information via the website, their information is encrypted and is protected with the best encryption
                  software in the industry - SSL.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>HYPERLINKS</span>
                  <br/><br/>
                  This site contains links to other websites. Their inclusion cannot be taken to imply any endorsement
                  or validation by us of the content of the given websites. TVBIZZ Ltd. is not responsible for the
                  privacy practices nor do we accept any liability in connection with the content of such websites.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>CHANGES/UPDATES TO PRIVACY POLICY</span>
                  <br/><br/>
                  In order to keep up with changing legislation and best practices, TVBIZZ Ltd. may revise this privacy
                  policy at any time. You will receive explicit information regarding any change to our privacy
                  policies.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>CONTACT US</span>
                  <br/><br/>
                  If you have any questions about this privacy pledge or feel that your privacy has been compromised,
                  please contact us at <a href="mailto:admin@tvbizz.net" style={{color: '#5d8ab4;'}}
                                          className="linkunderlineblue">admin@tvbizz.net</a>.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PrivacyPage;
