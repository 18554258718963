import {PaymentIntentResult} from "@stripe/stripe-js";
import {axiosInstance} from "../../const/axiosClient";
import {subscriptionActivate, subscriptionCreate} from "../../const/urlConst";

interface RetryData {
  subscription: any,
  invoice?: any | null,
  priceId: string,
  paymentMethodId: string,
  isRetry?: boolean | null,
  handleError: (error: string) => void,
  stripe: any,
  updateCurrentUser: () => void,
}

export async function createUserSubscription(
  customerId: string,
  paymentId: string,
  productId: string,
  company: string | null,
  country: string,
  city: string,
  address: string,
  zip: string,
  handleError: (error: string) => void,
  stripe: any,
  updateCurrentUser: () => void
)
{
  return (
    axiosInstance.post(`${subscriptionCreate}`,
      {
        customerId: customerId,
        paymentId: paymentId,
        priceId: productId,
        company: company,
        country: country,
        zip: zip,
        address: address,
        city: city,
      }
    ).then((response) => {
        console.log('Step2')
        console.log(response)
        if (response.data.errorMsg && response.data.errorMsg !== 'OK') {
          throw response.data.errorMsg;
        }
        return response.data;
    }).then((result) => {
        console.log('Step3')
        return {
          paymentMethodId: paymentId,
          priceId: productId,
          subscription: result.subscription,
          handleError: handleError,
          stripe: stripe,
          updateCurrentUser: updateCurrentUser,
        };
    }).then(handlePaymentThatRequiresCustomerAction)
      .then(handleRequiresPaymentMethod)
      .then(onSubscriptionComplete)
      .catch((error) => {
        handleError(error)
      })
  );
}

function handlePaymentThatRequiresCustomerAction(data: RetryData): RetryData {
  console.log('Step4')
  if (data.subscription && data.subscription.status === 'active') {
    return data;
  }
  let paymentIntent = data.invoice ? data.invoice.payment_intent : data.subscription.latest_invoice.payment_intent;
  if (paymentIntent.status === 'requires_action' || (data.isRetry === true &&
    paymentIntent.status === 'requires_payment_method')) {
    return data.stripe.confirmCardPayment(
        paymentIntent.client_secret, {
          payment_method: data.paymentMethodId,
        }
      ).then((result: PaymentIntentResult) => {
        if (result.error) {
          throw result;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            return data
          }
        }
      }).catch((error: string) => {
        throw error
      });
  } else {
    return data;
  }
}

function handleRequiresPaymentMethod(data: RetryData): RetryData {
  console.log('Step5')
  const cardError = 'Your card has been declined.'
  if (data.subscription.status === 'active') {
    return data
  } else if (
    data.subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
    localStorage.setItem('latestInvoiceId', data.subscription.latest_invoice.id);
    localStorage.setItem('latestInvoicePaymentIntentStatus',
      data.subscription.latest_invoice.payment_intent.status
    );
    throw cardError
  } else {
    return data
  }
}

function onSubscriptionComplete(data: RetryData) {
  console.log('Step6')
  console.log(data.subscription.status)
  if (data.subscription.status === 'active') {
    axiosInstance.post(`${subscriptionActivate}`,
      {
        subscriptionId: data.subscription.id,
      }
    ).then((response) => {
      data.updateCurrentUser()
      return data
    })
  }
}
