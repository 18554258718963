import * as React from "react";
import {Tweet} from "../../../../model/Tweet";
import HeadlineCenterSingle from "../HeadlineCenter/HeadlineCenterSingle";
import HeadlinePane from "../HeadlinePane/HeadlinePane";


interface HeadlineNewsData {
    headline: Tweet | null,
    headlines: Tweet[],
}

const HeadlineNews: React.FC<HeadlineNewsData> = (props) => (
    <React.Fragment>
        <HeadlineCenterSingle headline={props.headline} />
        <HeadlinePane headlines={props.headlines} />
    </React.Fragment>
)

export default HeadlineNews;
