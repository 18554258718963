import React, {Dispatch} from "react";
import {Col, Container, Row} from "react-bootstrap";
import UserTabUpgradeContent from "../UserSettings/UserTabUpgradeContent";
import UserTabStartTrial from "../UserSettings/UserTabStartTrial";
import UserTabsStripePayment from "../UserSettings/UserTabsStripePayment";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import "../UserSettings/UserSettings.css";
import {STRIPE_KEY} from "../../const/valueConst";
import {AppState} from "../../store/reducers/rootReducer";
import {getCustomerKey, getUser, isFree} from "../../store/selectors/user/user.selector";
import {registerStripeCustomer} from "../../hooks/user/userFunctions";
import {User} from "../../model/User";
import {connect} from "react-redux";
import UserTabSubscrSuccessful from "../UserSettings/UserTabSubscrSuccessful";

const stripePromise = loadStripe(STRIPE_KEY,  {locale: "en-GB"});

interface StripeCustomer {
  customerKey: string | null,
  user: User | null,
  isFree: boolean,
}

interface DispatchStripeFunctions {
  getConsumerId: (
    email: string,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
}

const mapStateToProps = (state: AppState): StripeCustomer => {
  return {
    customerKey: getCustomerKey(state),
    user: getUser(state),
    isFree: isFree(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchStripeFunctions => {
  return {
    getConsumerId: (
      email: string,
      handleSuccess: () => void,
      handleError: () => void) => registerStripeCustomer(email, handleSuccess, handleError, dispatch),
  }
}

type StripeData = StripeCustomer & DispatchStripeFunctions

const AccountSelection: React.FC<StripeData> = (props) => {
  return (
    <Container>
      {props.user && props.isFree && !props.customerKey && <Row className="d-flex">
        <Col xs="12" sm="12" lg="8" className="mt-2 mx-auto d-flex pricing-container-main">
          <h2 className="my-3">Please, select your plan </h2>
        </Col>
        <Col xs="12" sm="12" lg="8" className="mt-5 mx-auto d-flex pricing-container-main aligne-start">
          <UserTabStartTrial/>
          <UserTabUpgradeContent
            email={props.user ? props.user.email : ""}
            getConsumerId={props.getConsumerId}
          />
        </Col>
      </Row>}
      {props.user && props.customerKey && props.isFree && <Elements stripe={stripePromise}>
        <UserTabsStripePayment />
      </Elements>}
      {props.user && !props.isFree && <UserTabSubscrSuccessful />}
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSelection);
