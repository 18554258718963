import {Banner} from "../../../model/Banner";
import {Dispatch} from "react";
import {axiosInstance} from "../../../const/axiosClient";
import {bannersData} from "../../../const/urlConst";

export const LOAD_ALL_BANNERS_EVENT = 'LOAD_ALL_BANNERS';

export type LOAD_ALL_BANNERS = typeof LOAD_ALL_BANNERS_EVENT;

export interface LoadAllBannersAction {
  type: LOAD_ALL_BANNERS,
  frontPageLeft: Banner[],
  frontPageRight: Banner[],
  innerPageLeft: Banner[],
  innerPageRight: Banner[],
}

export const loadAllBanners = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${bannersData}`);
    dispatch({
      type: LOAD_ALL_BANNERS_EVENT,
      frontPageLeft: getPageLeftBanner(data),
      frontPageRight: getPageTopRightBanner(data),
      innerPageLeft: getInnerPageLeftBanner(data),
      innerPageRight: getInnerPageRightBanner(data),
    })
  }
}

const getPageLeftBanner = (banners: Banner[]): Banner[] => {
  return banners.filter(banner => banner.page === 'HOME'
          && banner.side === 'LEFT')
    .sort((b1: Banner, b2: Banner) => b1.sequentialNo > b2.sequentialNo ? -1 : 1)
}

const getPageTopRightBanner = (banners: Banner[]): Banner[] => {
  return banners.filter(banner => banner.page === 'HOME'
    && banner.side === 'RIGHT')
    .sort((b1: Banner, b2: Banner) => b1.sequentialNo > b2.sequentialNo ? -1 : 1)
}

const getInnerPageLeftBanner = (banners: Banner[]): Banner[] => {
  return banners.filter(banner => banner.page === 'NEWS' && banner.side === 'LEFT')
    .sort((b1: Banner, b2: Banner) => b1.sequentialNo > b2.sequentialNo ? -1 : 1)
}

const getInnerPageRightBanner = (banners: Banner[]): Banner[] => {
  return banners.filter(banner => banner.page === 'NEWS' && banner.side === 'RIGHT')
    .sort((b1: Banner, b2: Banner) => b1.sequentialNo > b2.sequentialNo ? -1 : 1)
}


