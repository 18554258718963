import {AppState} from "../../reducers/rootReducer";
import {Tweet} from "../../../model/Tweet";
import {FilterCriteria} from "../../../model/FilterCriteria";

export function getLatestTweets(state: AppState): Tweet[] {
  return state.tweets.latestTweets;
}

export function getTrendingTweets(state: AppState): Tweet[] {
  return state.tweets.trendingTweets;
}

export function getLatestFilterCriteria(state: AppState): FilterCriteria {
  return {
    region: state.tweets.region,
    country: state.tweets.country,
    company: state.tweets.company,
    show: state.tweets.show,
    category: state.tweets.category,
  }
}

export function getTrendingFilterCriteria(state: AppState): FilterCriteria {
  return {
    region: state.tweets.trendingRegion,
    country: state.tweets.trendingCountry,
    company: null,
    show: null,
    category: state.tweets.trendingCategory,
  }
}

export function getHeadline(state: AppState): Tweet | null {
  return state.tweets.headline
}

export function getHeadlineNews(state: AppState): Tweet[] {
  return state.tweets.headlineTweets
}

export function isLoadingPageItems(state: AppState): boolean {
  return state.tweets.loading
}

export function getRelatedItems(state: AppState): Tweet[] {
  return state.tweets.relatedNews
}

export function getLatestRelatedItems(state: AppState): Tweet[] {
  return state.tweets.relatedLatestNews
}

export function getTweetById(state: AppState, tweetId: number): Tweet | null {
  let allTweets: Tweet[] = state.tweets.selectedNewsItem && state.tweets.selectedNewsItem.tweetId === tweetId
                                                    ? [state.tweets.selectedNewsItem] : [];
  allTweets = allTweets.concat(
    (state.tweets.headline && state.tweets.headline.tweetId === tweetId) ? [state.tweets.headline] : [],
    state.tweets.headlineTweets,
    state.tweets.latestTweets,
    state.tweets.trendingTweets,
    state.tweets.relatedNews,
    state.tweets.relatedLatestNews)
  const filterArray = allTweets.filter(value => value.tweetId === tweetId)
  return filterArray.length > 0 ? filterArray[0] : null
}


export function getRegion(state:AppState) {
  return state.tweets.region
}

export function getCountry(state:AppState) {
  return state.tweets.country
}
