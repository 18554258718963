import * as React from "react";
import {Navbar} from "react-bootstrap";
import MainMenu from "../MainMenu/MainMenu";
import "./MainMenuContainer.css"
import MainLogo from "../MainLogo/MainLogo";

const MainMenuContainer: React.FC = () => (
  <Navbar collapseOnSelect expand="lg" className="col-xl-8 col-lg-12 col-md-12 main_menu_background justify-content-center px-0" variant="dark">
    <div className="menu-first-row">
        <div className="menu-first-first-row">
          <MainLogo/>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="responsive_button_margin" />
        </div>
        <div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <MainMenu />
          </Navbar.Collapse>
        </div> 
    </div>  
  </Navbar>
);

export default MainMenuContainer;
