import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

const ContactUs: React.FC = () => {

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h3 className="w-100">Text here</h3>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <p> More text here</p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ContactUs;
