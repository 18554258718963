import * as React from "react";
import {Tweet} from "../../../../../model/Tweet";
import NewsTitle from "../../../../Common/Title/NewsTitle";
import LatestArticleCountryPane from "../../../../Common/DateCountry/LatestArticlesCountryPane";

interface ArticleData {
  articleNo: number,
  article: Tweet,
  isTrending?: boolean,
  withDate: boolean,
}

const HeadlineArticle: React.FC<ArticleData> = (data) => (
  <div className={data.articleNo % 2 === 0 ? "row-news-list-list" : "row-news-list-list-bright"}>
    {<div className={"country-background-bright list-location location-news-short-list my-0"}>
      <LatestArticleCountryPane newsItem={data.article} />
    </div>}
    <h6 className="title-list">
      <div className="headline-news-list-short">
        <NewsTitle 
          isPaid={!data.article.free}
          isPr={data.article.pr}
          title={data.article.title}
          newsId={data.article.tweetId}
          ceetv={data.article.ceetv}
        />
      </div>  
    </h6>
  </div>
)

export default HeadlineArticle;
