import * as React from "react";
import {Button} from "react-bootstrap";
import {Table} from "react-bootstrap";
import "./UserSettings.css";
import {useState} from "react";
import {useLocation, useHistory} from "react-router-dom";

interface SubscribeFunction {
  email: string,
  getConsumerId: (
    email: string,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
}

const UserTabUpgradeContent: React.FC<SubscribeFunction> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [errorMsg, setErrorMsg] = useState('')
  const [creatingUser, setCreatingUser] = useState(false)
  return (
    <React.Fragment>
      {errorMsg && <div className="error-message">{errorMsg}</div>}
      <Table className="pricing_table shadow-box">
        <thead className="pricing_header">
        <tr className="pb-0">
          <th className="pb-2">
            <div className="text-center">
              <h3>TVBIZZ Annual Subscription</h3>
              <p>Get your TVBIZZ Subscription for more Personalised Content</p>
            </div>
            <span className="pricing_price pb-0">€100</span> / year<br/>
            <span>Charged annually</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <Button
              className="w-100 tvbizz-primary"
              onClick={() => {
                setCreatingUser(true)
                props.getConsumerId(props.email,() => {
                  setCreatingUser(false)
                  if(!location.pathname.includes('/startuser')) {
                    history.push('/startuser')
                  }
                }, () => {
                  setErrorMsg('Unable to obtain customer id. Please, try again later')
                })
              }}
              variant="primary"
              type="submit"
            >
              {!creatingUser && "Subscribe now"}
              {creatingUser && <div className="button-loader">
                <div className="dot1"/>
                <div className="dot2"/>
                <div className="dot3"/>
              </div>}
            </Button>
          </td>
        </tr>
        <tr>
          <td>Full access to the TVBIZZ news library</td>
        </tr>
        <tr>
          <td>Full access to the TVBIZZ video library</td>
        </tr>
        <tr>
          <td>Search news according to geographical and product criteria</td>
        </tr>
        <tr>
          <td>Look for news related to a company or a show</td>
        </tr>
        <tr>
          <td>Search news according to geographical and product criteria</td>
        </tr>
        <tr>
          <td>Receive regular updates and breaking news from tvbizz</td>
        </tr>
        </tbody>
      </Table>
    </React.Fragment>

  )
}

export default UserTabUpgradeContent;
