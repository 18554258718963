import update from 'immutability-helper';
import {Tweet} from "../../../model/Tweet";
import {RegionType} from "../../../model/RegionType";
import {CategoryType} from "../../../model/CategoryType";
import {
  CLEAR_DATA_IN_STORE_EVENT, ClearNewsData,
  LOAD_HEADLINES_EVENT, LOAD_TWEET_LOADING_EVENT,
  LOAD_TWEET_RELATED_EVENT,
  LOAD_TWEETS_LATEST_EVENT, LOAD_TWEETS_LATEST_RELATED_EVENT,
  LOAD_TWEETS_TRENDING_EVENT,
  LoadTweetHeadlines,
  LoadTweetLatestAction, LoadTweetLatestRelated,
  LoadTweetLoading,
  LoadTweetRelated,
  LoadTweetTrendingAction
} from "../../actions/tweets/tweet.actions";

export interface TweetReducerState {
  headline: Tweet | null,
  headlineTweets: Tweet[],
  latestTweets: Tweet[],
  region: RegionType | null,
  country: string | null,
  company: string | null,
  show: string | null,
  category: CategoryType | null,
  trendingTweets: Tweet[],
  trendingRegion: RegionType | null,
  trendingCountry: string | null,
  trendingCategory: CategoryType | null,
  loading: boolean,
  relatedNews: Tweet[],
  relatedLatestNews: Tweet[],
  selectedNewsItem: Tweet | null,
}

const tweetState = {
  headline: null,
  headlineTweets: [],
  latestTweets: [],
  region: null,
  company: null,
  country: null,
  show: null,
  category: null,
  trendingTweets: [],
  trendingRegion: null,
  trendingCountry: null,
  trendingCategory: null,
  loading: false,
  relatedNews: [],
  relatedLatestNews: [],
  selectedNewsItem: null,
}

export default function tweetReducer(state: TweetReducerState = tweetState,
                                     action: LoadTweetLatestAction | LoadTweetTrendingAction
                                       | LoadTweetHeadlines | LoadTweetLoading | LoadTweetRelated
                                       | LoadTweetLatestRelated | ClearNewsData
): TweetReducerState {
  switch (action.type) {
    case LOAD_TWEETS_LATEST_EVENT:
      return update(state, {
        latestTweets: {$push: action.tweets},
        region: {$set: action.filter.region},
        country: {$set: action.filter.country},
        category: {$set: action.filter.category},
        company: {$set: action.filter.company},
        show: {$set: action.filter.show},
      })
    case LOAD_TWEETS_TRENDING_EVENT:
      return update(state, {
        trendingTweets: {$set: action.tweets},
        trendingRegion: {$set: action.filter.region},
        trendingCountry: {$set: action.filter.country},
        trendingCategory: {$set: action.filter.category},
      })
    case LOAD_HEADLINES_EVENT:
      return update(state, {
        headline: {$set: action.mainNews},
        headlineTweets: {$set: action.headlines},
      })
    case LOAD_TWEET_RELATED_EVENT:
      return update(state, {
        selectedNewsItem: {$set: action.selectedItem},
        relatedNews: {$set: action.relatedNews},
        relatedLatestNews: {$set: action.latestRelatedNews},
      })
    case LOAD_TWEET_LOADING_EVENT:
      return update(state, {
        loading: {$set: action.loading},
      })
    case LOAD_TWEETS_LATEST_RELATED_EVENT:
      return update(state, {
        relatedLatestNews: {$push: action.latestRelatedNews},
      })
    case CLEAR_DATA_IN_STORE_EVENT:
      return update(state, {
        latestTweets: {$set: []},
        region: {$set: null},
        country: {$set: null},
        category: {$set: null},
        company: {$set: null},
        show: {$set: null},
      })
    default:
      return state;
  }
}
