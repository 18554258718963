import React from "react";
import {tranformCountry} from "../../../utils/country/countryUtils";
import {dateDifferenceWithString} from "../../../utils/date/dateUtils";
import {TweetVideo} from "../../../model/TweetVideo";
import TitleElement from "./Common/TitleElement";

interface PaneVideoItem {
  video: TweetVideo,
  withDate: boolean,
}

const VideoDateCountryPane: React.FC<PaneVideoItem> = (props) => {
  const country = tranformCountry(props.video.country, null)
  return (
    <React.Fragment>
      <TitleElement country={country} video={true} />
      {props.withDate ? ` | ${dateDifferenceWithString(props.video.approveDate)}` : ''}
    </React.Fragment>
  )
}

export default VideoDateCountryPane;
