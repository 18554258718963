import React from "react";
import {Link} from "react-router-dom";
import {buildArticleLink} from "../../../utils/article/articleUtils";

interface NewsTitleData {
  title: string,
  newsId: number,
  ceetv: boolean,
}

const HeadlineTrendingNewsTitle: React.FC<NewsTitleData> = (props) => (
  <React.Fragment>
    <Link to={buildArticleLink(props.newsId, props.ceetv)} className="headline-link" style={{ fontSize:'14px', color:'#FFFFFF' }}>
      {props.title}
    </Link>&nbsp;&nbsp;
  </React.Fragment>
)

export default HeadlineTrendingNewsTitle;
