import * as React from "react";
import {Button} from "react-bootstrap";
import {Container, Col} from "react-bootstrap";
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import "./Signin.css";
import {withRouter, Link, RouteComponentProps} from "react-router-dom";
import {Formik, FormikHelpers} from "formik";
import {signinFormValidation} from "./SigninValidation/signinFormValidation";
import {axiosInstance} from "../../const/axiosClient";
import {userRegister} from "../../const/urlConst";

interface SignupData {
  firstName: string,
  lastName: string,
  company: string,
  email: string,
  country: string,
  password: string,
  reenter: string,
  tocAccept: boolean,
}

const handleSuccess = (helpers: FormikHelpers<SignupData>,
                       userId: number,
                       props: RouteComponentProps) => {
  helpers.resetForm()
  helpers.setSubmitting(false)
  props.history.replace(`/activate/${userId}`)

}

const handleError = (helpers: FormikHelpers<SignupData>, error: string) => {
  helpers.setStatus({
    isError: true,
    errorMsg: error,
  })
  helpers.setSubmitting(false)
}

const registerUser = async (user: SignupData,
                            helpers: FormikHelpers<SignupData>,
                            props: RouteComponentProps,
                            handleSuccess: (helpers: FormikHelpers<SignupData>, userId: number, props: RouteComponentProps) => void,
                            handleError: (helpers: FormikHelpers<SignupData>, err: string) => void) => {
  helpers.setSubmitting(true)
  if(user.password !== user.reenter) {
    handleError(helpers, "The passwords that you entered do no match!")
    return
  }
  if(!user.tocAccept) {
    handleError(helpers, "You must accept the Terms and Conditions of TVBIZZ!")
    return
  }
  try {
    const {data} = await axiosInstance.post(`${userRegister}`,
      {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: user.password,
        company: user.company,
        country: user.country,
      });
    if (data.registered) {
      handleSuccess(helpers, data.userId, props)
    } else {
      handleError(helpers, data.errorMsg)
    }
  } catch (err: any) {
    handleError(helpers, err.errorMsg)
  }
}

const Signup: React.FC<RouteComponentProps> = (props) => {
  return (
    <Container className="p-0">
      <div className="signing-content">
        <Col xs="12" sm="12" lg="6" className="my-5">
          <p className="w-100 text-right">
            <Link to="signin" className="text-signup-link"> Already have an account? Sign in.</Link>
          </p>
          <h2 className="my-4">Sign up with TVBIZZ</h2>
          <p className="text-muted mb-4">
            Please fill in this short form to submit your request for trial or subscription.
            After confirming your email you can choose to start a trial or to subscribe to the service.
          </p>
          <Formik
            initialValues={
              {
                firstName: "",
                lastName: "",
                country: "",
                company: "",
                password: "",
                email: "",
                reenter: "",
                tocAccept: false,
              }
            }
            validationSchema={signinFormValidation}
            onSubmit={(signup: SignupData, helpers: FormikHelpers<SignupData>) => {
              helpers.setSubmitting(true)
              registerUser(signup, helpers, props, handleSuccess, handleError)
            }}
          >
            {
              ({
                 values,
                 errors,
                 touched,
                 status,
                 isSubmitting,
                 handleChange,
                 handleBlur,
                 handleSubmit,
               }) => (
                <Form className="row" onSubmit={handleSubmit}>
                  {status && status.isError && <Col sm="12" lg="12" xl="12" >
                    <div className="error-message-in-form mb-2">{status.errorMsg}</div>
                  </Col>}
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicFirstName">
                      <FormLabel>First Name*:</FormLabel>
                      <FormControl
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.firstName && errors.firstName ? "error-border" : ""}
                        placeholder="Enter First Name"
                      />
                      {touched.firstName && errors.firstName ? (
                        <div className="error-message">{errors.firstName}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicLastName">
                      <FormLabel>Last Name*:</FormLabel>
                      <FormControl
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.lastName && errors.lastName ? "error-border" : ""}
                        placeholder="Enter Last Name"
                      />
                      {touched.lastName && errors.lastName ? (
                        <div className="error-message">{errors.lastName}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicCompany">
                      <FormLabel>Company*:</FormLabel>
                      <FormControl
                        type="text"
                        name="company"
                        value={values.company}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.company && errors.company ? "error-border" : ""}
                        placeholder="Enter Company Name"
                      />
                      {touched.company && errors.company ? (
                        <div className="error-message">{errors.company}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicEmail">
                      <FormLabel>E-mail*: (we'll never share your email with anyone else)</FormLabel>
                      <FormControl
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.email && errors.email ? "error-border" : ""}
                        placeholder="Enter email"
                      />
                      {touched.email && errors.email ? (
                        <div className="error-message">{errors.email}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicCountry">
                      <FormLabel>Country*:</FormLabel>
                      <FormControl
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.country && errors.country ? "error-border" : ""}
                        placeholder="Enter Country"
                      />
                      {touched.country && errors.country ? (
                        <div className="error-message">{errors.country}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicPass">
                      <FormLabel>Password*:</FormLabel>
                      <FormControl
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.password && errors.password ? "error-border" : ""}
                        placeholder="Enter Password"
                      />
                      {touched.password && errors.password ? (
                        <div className="error-message">{errors.password}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <FormGroup controlId="formBasicRepeatPass">
                      <FormLabel>Confirm password*:</FormLabel>
                      <FormControl
                        type="password"
                        name="reenter"
                        value={values.reenter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={touched.reenter && errors.reenter ? "error-border" : ""}
                        placeholder="Enter your password again"
                      />
                      {touched.reenter && errors.reenter ? (
                        <div className="error-message">{errors.reenter}</div>
                      ) : <div className="error-message">&nbsp;</div>}
                    </FormGroup>
                  </Col>
                  <Col sm="12" lg="12" xl="12">
                    <Form.Group controlId="formBasicCheckbox" className="d-flex">
                      <Form.Check
                        type="checkbox"
                        name="tocAccept"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        aria-checked={values.tocAccept}
                        className={touched.tocAccept && errors.tocAccept ? "error-border" : ""}
                      />
                      <FormLabel> I accept the <Link to="/terms" target="_blank" className="text-signup-link">Terms of Use</Link></FormLabel>
                    </Form.Group>
                  </Col>
                  <Col sm="12" lg="12" xl="12" className="my-3 text-center">
                    <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                      {!isSubmitting && "Sign up"}
                      {isSubmitting && <div className="button-loader">
                        <div className="dot1"/>
                        <div className="dot2"/>
                        <div className="dot3"/>
                      </div>}
                    </Button>
                  </Col>
                </Form>
              )
            }
          </Formik>
        </Col>
      </div>
    </Container>
  )

}

export default withRouter(Signup);
