import * as React from "react";
import {Col, Container, Row, Image, Button} from "react-bootstrap";
import tv from '../../../resources/images/tv.png'

const ErrorPage: React.FC = () => {
  
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xl={{span: 4}} lg={{span:8}} md={{span: 6}} sm={{span: 10}} xs={{span: 10}} className="mx-auto my-3 p-5">
            <Image src={tv} fluid />
          </Col>
        </Row>
        <Row className="text-center">
          <Col xl={{span: 8}} lg={{span:8}} md={{span: 8}} sm={{span: 10}} xs={{span: 10}} className="mx-auto my-3">
            <h3 className="w-100">Oops. Something went wrong...</h3>
          </Col>
          <Col xl={{span: 12}} lg={{span:12}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} className="mx-auto my-3 p-5">
            <Button className="w-100 tvbizz-primary m-auto" variant="primary">
            Take me to the Homepage
            </Button>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ErrorPage;
