import {
  LOAD_TRENDING_DATA_EVENT, LOAD_TRENDING_HEADLINES_EVENT,
  LOAD_TRENDING_LOADING_EVENT, LOAD_TRENDING_NEWS_REGION_EVENT,
  LOAD_TRENDING_TAGS_EVENT, LOAD_TRENDING_TAGS_REGION_EVENT, LoadTagsRegionTrending,
  LoadTagsTrending,
  LoadTweetRegionTrendingHeadlines,
  LoadTweetTrendingCategory,
  LoadTweetTrendingHeadlines,
  LoadTweetTrendingLoading
} from "../../actions/trending/trending.actions";
import update from "immutability-helper";
import {TrendingItem} from "../../../model/TrendingItem";
import {TrendingCategory} from "../../../model/TrendingCategory";
import {TrendingRegions} from "../../../model/TrendingRegions";

export interface TrendingReducerState {
  trendingNews: TrendingItem[],
  regionNews: TrendingRegions | null,
  regionCompanyTags: TrendingRegions,
  regionShowTags: TrendingRegions,
  regionPeopleTags:TrendingRegions,
  trendingCategories: TrendingCategory[],
  // main page data
  companyTags: TrendingItem[],
  showTags: TrendingItem[],
  peopleTags: TrendingItem[],
  loading: boolean,
  // trending page loaders
  loadedTrendingData: boolean,
  loadedPanelNewsData: boolean,
  loadedPanelTagsData: boolean,
  isLoadingCategories: boolean,
}

const trendingState = {
  trendingNews: [],
  regionNews: null,
  regionCompanyTags: {northAmerica: [], westEurope: [], latam: [], ausnz: [], asia: [], africa: [], mideast: []},
  regionShowTags: {northAmerica: [], westEurope: [], latam: [], ausnz: [], asia: [], africa: [], mideast: []},
  regionPeopleTags: {northAmerica: [], westEurope: [], latam: [], ausnz: [], asia: [], africa: [], mideast: []},
  trendingCategories: [],
  // main page data
  companyTags: [],
  showTags: [],
  peopleTags: [],
  loading: false,
  // trending page loaders
  loadedTrendingData: false,
  loadedPanelNewsData: false,
  loadedPanelTagsData: false,
  isLoadingCategories: false,
}

export default function trendingReducer(state: TrendingReducerState = trendingState,
                                        action: LoadTweetTrendingCategory | LoadTweetTrendingLoading | LoadTagsTrending |
                                         LoadTweetTrendingHeadlines | LoadTweetRegionTrendingHeadlines | LoadTagsRegionTrending,
): TrendingReducerState {
  switch (action.type) {
    case LOAD_TRENDING_DATA_EVENT:
      return update(state, {
        // trendingNews: {$set: action.tweets},
        trendingCategories: {$set: action.categories},
        loading: {$set: action.loading},
      })
    case LOAD_TRENDING_LOADING_EVENT:
      return update(state, {
        loading: {$set: action.loading},
      })
    case LOAD_TRENDING_TAGS_EVENT:
      return update(state, {
        companyTags: {$set: action.companyTags},
        showTags: {$set: action.showTags},
        peopleTags: {$set: action.peopleTags},
      })
    case LOAD_TRENDING_HEADLINES_EVENT:
      return update(state, {
        trendingNews: {$set: action.headlines},
        loadedTrendingData: {$set: true},
      })
    case LOAD_TRENDING_NEWS_REGION_EVENT:
      return update(state, {
        regionNews: {$set: action.newsRegion},
        loadedPanelNewsData: {$set: true},
      })
    case LOAD_TRENDING_TAGS_REGION_EVENT:
      return update(state, {
        regionCompanyTags: {$set: action.companyTags},
        regionShowTags: {$set: action.showTags},
        regionPeopleTags: {$set: action.peopleTags},
        loadedPanelTagsData: {$set: true},
      })
    default:
      return state;
  }
}
