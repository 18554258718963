import * as React from "react";
import {Col, Row} from "react-bootstrap";
import "./HeadlineCenter.css"
import {Tweet} from "../../../../model/Tweet";
import { Link } from "react-router-dom";
import NewsDateCountryPane from "../../../Common/DateCountry/NewsDateCountryPane";
import {buildArticleLink} from "../../../../utils/article/articleUtils";
import {IMAGE_URL_LOCAL} from "../../../../const/valueConst";


interface HeadlineData {
    headline: Tweet | null,
}

const HeadlineCenterSingle: React.FC<HeadlineData> = (props) => (
    <Row className="headline_center_row w-100">
        <Col className="main-news-block p-0">
            {props.headline &&
                <Link to={buildArticleLink(props.headline.tweetId, props.headline.ceetv)} className="headline-link">
                    <div className="hero-image-div" style={{ backgroundImage: `url('${encodeURI(IMAGE_URL_LOCAL + (props.headline?.image ?? (props.headline?.ceetv ? "ceetv-white-fill-big.png" : "tvbizz-white-fill-big.png")))}')` }}>
                        <div className="LocationTime mb-0 w-100">
                            <span className="country-background">
                                <NewsDateCountryPane newsItem={props.headline} withDate={false} />
                            </span>    
                        </div>
                        <div className="hero-image-text-div">
                            <div className="hadline-hero-image">
                                <h2 className="m-0">
                                    {props.headline.title}
                                </h2>
                            </div>
                        </div>
                    </div>
                </Link>}
        </Col>
    </Row>
)

export default HeadlineCenterSingle;