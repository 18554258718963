export const TEXT_LENGTH_ON_MAIN_PAGES = 320
export const IMAGE_URL_LOCAL = 'https://images.tvbizz.net/tvbizz_images/'
export const IMAGE_URL_YOUTUBE = 'https://img.youtube.com/vi/'
export const SUBSCRIPTION_PRICE_ID = 'price_1IX2s1Jq3LqMeYhAjL1Syn6S'
export const SUBSCRIPTION_PRODUCT_ID = 'prod_J9LZv2OVYpRtzn'
export const STRIPE_KEY = 'pk_test_51I7cTtJq3LqMeYhAOElXsnta9qBzRDNLXOChfXdrmvbfTfEu9J9WSJ3brouskpag2e5ZfWV3TmgBfyiuWOQ1evqX00E3bdMRou'
export const MAX_ARTICLES_AS_GUEST = 5;
export const CONSENT_COOKIE_NAME = 'tvbizz-consent';
export const MAX_ITEMS_IN_SEARCH = 16;


