import { combineReducers } from 'redux';
import tweetReducer from './tweets/tweet.reducer';
import videoReducer from './videos/video.reducer';
import userReducer from './user/user.reducer';
import loadReducer from "./load/load.reducer";
import trendingReducer from "./trending/trending.reducer";
import companyReducer from "./company/company.reducer";
import showReducer from "./show/show.reducer";
import searchReducer from "./search/search.reducer";
import bannersReducer from "./banners/banners.reducer";
import messagesReducer from "./message/message.reducer";

const rootReducer = combineReducers({
  tweets: tweetReducer,
  videos: videoReducer,
  user: userReducer,
  load: loadReducer,
  trending: trendingReducer,
  company: companyReducer,
  show: showReducer,
  search: searchReducer,
  banners: bannersReducer,
  messages: messagesReducer,
})

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
