import * as React from "react";
import {Col} from "react-bootstrap";
import {Image} from "react-bootstrap";
import {TrendingItem} from "../../../../../../model/TrendingItem";
import TagsTitle from "../../../../../Common/Title/TagsTitle";
import icon_list from "../../../../../../resources/icons/icon-list.svg";

interface TrendingTagData {
    trendingTags: TrendingItem[],
}

const TrendingTagList: React.FC<TrendingTagData> = (props) => (
    <React.Fragment>
        {
            props.trendingTags.slice(0, 5).map((tag, index) => {
                return (
                    <Col key={index} md={{span: 12}} className="d-flex flex-row pt-0 margin-big-screen first-page-trending-list p-0">
                        <div className="small-list-icons"><Image src={icon_list} className="icon-list"/> </div>
                        <h6 className="trending_article_title p-2 my-1"><TagsTitle link={tag.url} tag={tag.tag} /></h6>
                    </Col>
                )
            })
        }
    </React.Fragment>
)

export default TrendingTagList;
