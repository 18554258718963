import * as React from "react";
import {Row, Col} from "react-bootstrap";
import {Button, Image} from "react-bootstrap";
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import {Formik, FormikHelpers} from "formik";
import "./UserSettings.css"
import {userEditFormValidation} from "../SignUp/SigninValidation/userEditFormValidation";
import {AppState} from "../../store/reducers/rootReducer";
import {getUser, getUserData} from "../../store/selectors/user/user.selector";
import {connect} from "react-redux";
import {UserData} from "../../model/UserData";
import {User} from "../../model/User";
import {UserSettingsData} from "../../model/UserSettingsData";
import {Component, Dispatch} from "react";
import {changeUserData} from "../../hooks/user/userFunctions";
import PictureSetupModal from "../Modals/PictureSetupModal/PictureSetupModal";

interface TabProperties {
  user: User | null,
  userData: UserData | null,
}

interface DispatchEditFunctions {
  editUserCredentials: (
    editData: UserSettingsData,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
  getDisptach: () => Dispatch<any>,
}

interface DialogState {
  showDialog: boolean,
}

type UserSettingsState = TabProperties & DispatchEditFunctions

const mapStateToProps = (state: AppState): TabProperties => {
  return {
    user: getUser(state),
    userData: getUserData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchEditFunctions => {
  return {
    editUserCredentials: (
      editData: UserSettingsData,
      handleSuccess: () => void,
      handleError: () => void,
    ) => changeUserData(editData, handleSuccess, handleError, dispatch),
    getDisptach: () => dispatch
  }
}

class UserTabMyContent extends Component<UserSettingsState, DialogState> {
  state = {
    showDialog: false
  }

  openPixDialog = () => {
    this.setState({ showDialog: true })
  }

  closePixDialog = () => {
    this.setState({ showDialog: false })
  }

  componentDidMount() {
    console.log('Initialize')
    this.setState({ showDialog: false })
  }

  render() {
    return (
      <div className="settings_tab_content">
        <Row>
          <Col xs="12" sm="12" lg="3">
            <div className="profile_photo mx-auto">
              {this.props.user && !this.props.user.photo && <div className="profile-menu-div">
                <span className="profile_photo">{this.props.user.initials}</span>
              </div>}
              {this.props.user && this.props.user.photo && <div className="profile-menu-div">
                <Image className="profile_photo" src={`https://images.tvbizz.net/image_data/${this.props.user.photo}`}
                       fluid/>
              </div>}
            </div>
            <Button onClick={this.openPixDialog} variant="outline-primary" className="w-100 tvbizz-outline-primary mx-auto my-3" type="submit">
              Change photo
            </Button>
          </Col>
          {this.props.user && this.props.userData && <Col xs="12" sm="12" lg="8" className="ml-2">
            <Formik
              initialValues={
                {
                  firstName: this.props.user.firstName,
                  lastName: this.props.user.lastName,
                  company: this.props.user.company ? this.props.user.company : '',
                  position: this.props.userData.position,
                  country: this.props.user.country,
                  city: this.props.userData.city,
                  webpage: this.props.userData.webpage,
                  social: this.props.userData.linkedin,
                }
              }
              validationSchema={userEditFormValidation}
              onSubmit={(editData: UserSettingsData, helpers: FormikHelpers<UserSettingsData>) => {
                helpers.setSubmitting(true)
                this.props.editUserCredentials(editData, () => {
                  helpers.setSubmitting(false)
                }, () => {
                  helpers.setStatus({
                    isError: true,
                    errorMsg: "Unable to change the user data. Please, try again later!"
                  })
                  helpers.setSubmitting(false)
                })
              }}
            >
              {
                ({
                   values,
                   errors,
                   touched,
                   status,
                   isSubmitting,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                 }) => (
                  <Form className="row" onSubmit={handleSubmit}>
                    {status && status.isError && <Col sm="12" lg="12" xl="12">
                      <div className="error-message-in-form mb-2">{status.errorMsg}</div>
                    </Col>}
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicFirstName">
                        <FormLabel>First Name:</FormLabel>
                        <FormControl
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="First Name"
                        />
                        {touched.firstName && errors.firstName ? (
                          <div className="error-message">{errors.firstName}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicLastName">
                        <FormLabel>Last Name:</FormLabel>
                        <FormControl
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Last Name"
                        />
                        {touched.lastName && errors.lastName ? (
                          <div className="error-message">{errors.lastName}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicCompany">
                        <FormLabel>Company:</FormLabel>
                        <FormControl
                          type="text"
                          name="company"
                          value={values.company}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Company"
                        />
                        {touched.company && errors.company ? (
                          <div className="error-message">{errors.company}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicPosition">
                        <FormLabel>Position:</FormLabel>
                        <FormControl
                          type="text"
                          name="position"
                          value={values.position}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Position"
                        />
                        {touched.position && errors.position ? (
                          <div className="error-message">{errors.position}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicCountry">
                        <FormLabel>Country:</FormLabel>
                        <FormControl
                          type="text"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Country"
                        />
                        {touched.country && errors.country ? (
                          <div className="error-message">{errors.country}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicCity">
                        <FormLabel>City/Town:</FormLabel>
                        <FormControl
                          type="text"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="City/Town"
                        />
                        {touched.city && errors.city ? (
                          <div className="error-message">{errors.city}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicWebpage">
                        <FormLabel>Webpage:</FormLabel>
                        <FormControl
                          type="text"
                          name="webpage"
                          value={values.webpage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Web Page"
                        />
                        {touched.webpage && errors.webpage ? (
                          <div className="error-message">{errors.webpage}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicSocial">
                        <FormLabel>Social:</FormLabel>
                        <FormControl
                          type="text"
                          name="social"
                          value={values.social}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Social"
                        />
                        {touched.social && errors.social ? (
                          <div className="error-message">{errors.social}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12" className="my-3 text-center">
                      <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                        {!isSubmitting && "Update"}
                        {isSubmitting && <div className="button-loader">
                          <div className="dot1"/>
                          <div className="dot2"/>
                          <div className="dot3"/>
                        </div>}
                      </Button>
                    </Col>
                  </Form>
                )
              }
            </Formik>
            <PictureSetupModal
              showDialog={this.state.showDialog}
              closeDialog={() => this.closePixDialog()}
              dispatch={this.props.getDisptach()}
            />
          </Col>}
        </Row>
      </div>
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserTabMyContent);
