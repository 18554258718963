import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";
import {Company} from "../../../model/Company";
import {
  CLEAR_COMPANY_DATA_EVENT,
  ClearCompanyData,
  LOAD_COMPANY_COMPLETED_EVENT,
  LOAD_COMPANY_NOT_COMPLETED_EVENT,
  LOAD_COMPANY_RELATED_ITEMS_EVENT,
  LoadCompanyCompletedAction,
  LoadCompanyNotCompletedAction,
  LoadCompanyRelatedItemsAction
} from "../../actions/company/company.actions";
import update from "immutability-helper";

export interface CompanyReducerState {
  tweets: Tweet[],
  videos: TweetVideo[],
  loading: boolean,
  company: Company | null,
  noMoreRelatedData: boolean,
}

const companyState = {
  tweets: [],
  videos: [],
  loading: false,
  company: null,
  noMoreRelatedData: false,
}

export default function companyReducer(state: CompanyReducerState = companyState,
                                       action: LoadCompanyCompletedAction | LoadCompanyRelatedItemsAction |
                                               LoadCompanyNotCompletedAction | ClearCompanyData
) : CompanyReducerState {
  switch (action.type) {
    case LOAD_COMPANY_COMPLETED_EVENT:
      return update(state, {
        company: {$set: action.company},
        loading: {$set: false},
      })
    case LOAD_COMPANY_RELATED_ITEMS_EVENT:
      return update(state, {
        tweets: {$push: action.tweets},
        videos: {$push: action.videos},
        loading: {$set: false},
        noMoreRelatedData: {$set: (action.tweets.length === 0 && action.videos.length === 0)},
      })
    case LOAD_COMPANY_NOT_COMPLETED_EVENT:
      return update(state, {
        loading: {$set: true},
      })
    case CLEAR_COMPANY_DATA_EVENT:
      return update(state, {
        company: {$set: null},
        tweets: {$set: []},
        videos: {$set: []},
        loading: {$set: false},
      })
    default:
      return state
  }
}
