import {MenuItemElement} from "../model/MenuItemElement";
import {RegionItemElement} from "../model/RegionItemElement";
import {RegionType} from "../model/RegionType";

export const list: MenuItemElement[]  = [
  { text: "ALL", region: "ALL", selected: false },
  { text: "US/CANADA", region: "USCAN", selected: false },
  { text: "WEST EUROPE", region: "WESTEUROPE", selected: false },
  { text: "LATAM", region: "LATAM", selected: false },
  { text: "ASIA", region: "ASIA", selected: false },
  { text: "AUSTRALIA & NEW ZEALAND", region: "AUSNZ", selected: false },
  { text: "CE EUROPE", region: "CEE", selected: false },
  { text: "AFRICA", region: "AFRICA", selected: false },
  { text: "MID EAST", region: "MIDEAST", selected: false },
];

export const countries: RegionItemElement[] = [
  {
    region: "USCAN",
    regionEnum: RegionType.USCAN,
    countries: [
      "Canada",
      "United States"
    ]
  },
  {
    region: "WESTEUROPE",
    regionEnum: RegionType.WESTEUROPE,
    countries: [
      "Austria",
      "Belgium",
      "Denmark",
      "Finland",
      "France",
      "Germany",
      "Ireland",
      "Italy",
      "Netherlands",
      "Norway",
      "Portugal",
      "Spain",
      "Sweden",
      "Switzerland",
      "United Kingdom"
    ]
  },
  {
    region: "LATAM",
    regionEnum: RegionType.LATAM,
    countries: [
      "Argentina",
      "Bolivia",
      "Brazil",
      "Chile",
      "Colombia",
      "Ecuador",
      "Mexico",
      "Paraguay",
      "Peru",
      "Uruguay",
      "Venezuela"
    ]
  },
  {
    region: "ASIA",
    regionEnum: RegionType.ASIA,
    countries: [
      "China",
      "Hong Kong",
      "India",
      "Japan",
      "Korea",
      "Philippines",
      "Singapore",
      "Thailand",
      "Vietnam"
    ]
  },
  {
    region: "AUSNZ",
    regionEnum: RegionType.AUSNZ,
    countries: [
      "Australia",
      "New Zealand"
    ]
  },
  {
    region: "CEE",
    regionEnum: RegionType.CEE,
    countries: [
      "Belarus",
      "Bosnia",
      "Bulgaria",
      "Croatia",
      "Czech Republic",
      "Estonia",
      "Greece",
      "Hungary",
      "Latvia",
      "Lithuania",
      "North Macedonia",
      "Poland",
      "Romania",
      "Russia",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Turkey",
      "Ukraine"
    ]
  },
  {
    region: "AFRICA",
    regionEnum: RegionType.AFRICA,
    countries: []
  },
  {
    region: "MIDEAST",
    regionEnum: RegionType.MIDEAST,
    countries: [
      "Algeria",
      "Egypt",
      "Israel",
      "Morocco",
      "Tunisia",
      "Other"
    ]
  },
]
