import * as React from "react";
import {Row, Col, Button} from "react-bootstrap";

const UserTabSubscrContent: React.FC = () => (
  <div className="settings_tab_content">
    <Row>
      <Col xs="12" sm="12" lg="12">
        <h3 className="font-weight-bold my-3">Your Subscription</h3>
      </Col>
    </Row>
    <Row>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">My current Subscription</h6>
        <p>Free</p>
      </Col>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Subscription Fee</h6>
        <p>0 €</p>
      </Col>
    </Row>
    <Row className="mt-2">
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Expiry Date</h6>
        <p>N/A</p>
      </Col>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Paid news allowed</h6>
        <p>None</p>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col xs="12" sm="12" lg="12">
        <h3 className="font-weight-bold my-3">Cancel Subscription</h3>
      </Col>
    </Row>
    <Row>
      <Col xs="12" sm="12" lg="12">
        <span>Cancelling your subscription will lock all the paid news articles on our side.</span>
      </Col>
      <Col xs="12" sm="12" lg="12">
        <Button variant="outline-primary" className="w-100 tvbizz-outline-primary mx-auto mt-4" type="submit">
          Cancel my subscription
        </Button>
      </Col>
    </Row>
  </div>
)

export default UserTabSubscrContent;
