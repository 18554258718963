import React from "react";
import {Link} from "react-router-dom";

interface NewsTagData {
  tag: string,
  link: string,
}

const TagTitle: React.FC<NewsTagData> = (props) => (
  <React.Fragment>
    <Link to={props.link} className="headline-link">{props.tag}</Link>&nbsp;&nbsp;
  </React.Fragment>
)

export default TagTitle;
