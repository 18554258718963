import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Newsletter from "../../../Newsletter/Newsletter";

const NewsletterFail: React.FC = () => {

  return (
    <React.Fragment>
      <Container>
        <Row className="pane_center_row p-0 d-flex justify-content-center">
          <Col xl={{span: 8}} lg={{span:8}} md={{span: 12}} className="m-auto p-0">
            <Row>
              <Col>
                <h3 className="w-100 my-4">Newsletter subscription failed</h3>
              </Col>
            </Row>
            <Row>
              <Col className="my-4">
                <p>
                  You can use the form below to retry subscribing to our newsletter:
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Newsletter />
    </React.Fragment>
  );
}

export default NewsletterFail;
