import {Dispatch} from "react";


// areBannersLoaded: false,
    // areMainArticlesLoaded: false,
    // areTrendingArticlesLoaded: false,

export const LOAD_COMPLETED_EVENT = 'LOAD_COMPLETED';
export const LOAD_NOT_COMPLETED_EVENT = 'LOAD_NOT_COMPLETED';
export const LOAD_BANNERS_COMPLETED_EVENT = 'LOAD_BNNERS_COMPLETED_EVENT';
export const LOAD_BANNERS_NOT_COMPLETED_EVENT = 'LOAD_BANNER_NOT_COMPLETED_EVENT';
export const LOAD_MAIN_COMPLETED_EVENT = 'LOAD_MAIN_COMPLETED_EVENT';
export const LOAD_MAIN_NOT_COMPLETED_EVENT = 'LOAD_NOT_COMPLETED_EVENT';
export const LOAD_TRENDING_COMPLETED_EVENT = 'LOAD_TRENDING_COMPLETED_EVENT';
export const LOAD_TRENDING_NOT_COMPLETED_EVENT = 'LOAD_TRENDING_NOT_COMPLETED_EVENT';

export type LOAD_COMPLETED = typeof LOAD_COMPLETED_EVENT;
export type LOAD_NOT_COMPLETED = typeof LOAD_NOT_COMPLETED_EVENT;
export type LOAD_BANNERS_COMPLETED = typeof LOAD_BANNERS_COMPLETED_EVENT;
export type LOAD_BANNERS_NOT_COMPLETED = typeof LOAD_BANNERS_NOT_COMPLETED_EVENT;
export type LOAD_MAIN_COMPLETED = typeof LOAD_MAIN_COMPLETED_EVENT;
export type LOAD_MAIN_NOT_COMPLETED = typeof LOAD_MAIN_NOT_COMPLETED_EVENT;
export type LOAD_TRENDING_COMPLETED = typeof LOAD_TRENDING_COMPLETED_EVENT;
export type LOAD_TRENDING_NOT_COMPLETED = typeof LOAD_TRENDING_NOT_COMPLETED_EVENT;

export interface LoadCompletedAction {
  type: LOAD_COMPLETED,
}

export interface LoadNotCompletedAction {
  type: LOAD_NOT_COMPLETED,
}

export interface LoadBannersCompletedAction {
  type: LOAD_BANNERS_COMPLETED,
}

export interface LoadBannersNotCompletedAction {
  type: LOAD_BANNERS_NOT_COMPLETED,
}

export interface LoadMainCompletedAction {
  type: LOAD_MAIN_COMPLETED,
}

export interface LoadMainNotCompletedAction {
  type: LOAD_MAIN_NOT_COMPLETED,
}

export interface LoadTrendingCompletedAction {
  type: LOAD_TRENDING_COMPLETED,
}

export interface LoadTrendingNotCompletedAction {
  type: LOAD_TRENDING_NOT_COMPLETED,
}

export const loadingComplete = (isLoadingInProcess: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch( {
      type: isLoadingInProcess ? LOAD_COMPLETED_EVENT : LOAD_NOT_COMPLETED_EVENT,
    });
  }
}

export const loadingBannersComplete = (isLoadingInProcess: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch( {
      type: isLoadingInProcess ? LOAD_BANNERS_COMPLETED_EVENT : LOAD_BANNERS_NOT_COMPLETED_EVENT,
    });
  }
}

export const loadingMainComplete = (isLoadingInProcess: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch( {
      type: isLoadingInProcess ? LOAD_MAIN_COMPLETED_EVENT : LOAD_MAIN_NOT_COMPLETED_EVENT,
    });
  }
}

export const loadingTrendingComplete = (isLoadingInProcess: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch( {
      type: isLoadingInProcess ? LOAD_TRENDING_COMPLETED_EVENT : LOAD_MAIN_NOT_COMPLETED_EVENT,
    });
  }
}
