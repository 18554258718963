export enum RegionType {
  NA = -1,
  USCAN = 1,
  WESTEUROPE = 2,
  LATAM = 3,
  ASIA = 4,
  AUSNZ = 5,
  CEE = 6,
  AFRICA = 7,
  MIDEAST = 8,
}
