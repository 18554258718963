import * as React from "react";
import {useSelector} from "react-redux";
import {getBannersLeft} from "../../../store/selectors/banners/banners.selector";
import BannerFrame from "../BannerFrame";

const BannersHomeLeft: React.FC = () => {
  const banners = useSelector(getBannersLeft)
  return (
    <div className="mr-2 bckgr-black">
      {
        banners.map(banner => <BannerFrame key={banner.bannerId} banner={banner}/>)
      }
    </div>
  )

}

export default BannersHomeLeft;
