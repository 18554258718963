import {Form, Formik, FormikHelpers} from "formik";
import React from "react";
import {Button, Col, Container, FormCheck, FormControl, FormGroup, FormLabel, InputGroup, Row, Image} from "react-bootstrap";
import {newsletterFormValidation} from "../SignUp/SigninValidation/newsletterFormValidation";
import {axiosInstance} from "../../const/axiosClient";
import {newsletterSubscribe} from "../../const/urlConst";
import logo_pitchplay from "../../resources/images/pitchplaz-web.png";
import logo_tvbizzmagazine from "../../resources/images/tvbizz-magazine-logo-web.png";
import logo_ceetv from "../../resources/images/ceetv-logo-web.png";
import {Link} from "react-router-dom";
import "./Newsletter.css";
import NewsletterEnableModal from "../Modals/NewsletterEnableModal/NewsletterEnableModal";

interface NewsletterData {
  email: string,
  tocAccept: boolean,
}

const handleSuccess = (helpers: FormikHelpers<NewsletterData>) => {
  helpers.resetForm()
  // @ts-ignore
  document.getElementById("formBasicCheckbox").checked = false
  helpers.setStatus({
    isError: false,
    showMsg: true,
  })
  helpers.setSubmitting(false)
}

const handleError = (helpers: FormikHelpers<NewsletterData>, error: string) => {
  helpers.setStatus({
    isError: true,
    errorMsg: error,
    showMsg: false,
  })
  helpers.setSubmitting(false)
}


const signUpForNewsletter = async (subscription: NewsletterData,
                            helpers: FormikHelpers<NewsletterData>,
                            handleSuccess: (helpers: FormikHelpers<NewsletterData>) => void,
                            handleError: (helpers: FormikHelpers<NewsletterData>, err: string) => void) => {
  helpers.setSubmitting(true)
  if(!subscription.tocAccept) {
    handleError(helpers, "Please, accept the Terms of Use!")
    return
  }
  try {
    const {data} = await axiosInstance.post(`${newsletterSubscribe}`,
      {
        email: subscription.email,
      });
    if (data.result) {
      handleSuccess(helpers)
    } else {
      handleError(helpers, data.errorMessage)
    }
  } catch (err) {
    handleError(helpers, "Problems with your network - please, try again later")
  }
}

const Newsletter: React.FC = () => (
  <Container fluid className ="bckgr-blue p-0">
    <Container>
      <Row>
        <Col xl={{span: 12}} lg={{span:12}} md={{span: 12}} sm={{span: 12}} className="mx-auto px-0">
          <Row>
            <Col xl={{span: 6}} lg={{span:6}} md={{span: 12}} sm={{span: 12}} className="newsletter-container mt-2 mx-auto pl-0">
              <Col className="m-auto px-0 w-100">
                <h6 className="subscr">Subscribe to our newletter:</h6>
                <Formik
                  initialValues={{ email: "", tocAccept: false }}
                  validationSchema={newsletterFormValidation}
                  onSubmit={(subscription: NewsletterData, helpers: FormikHelpers<NewsletterData>) => {
                    helpers.setSubmitting(true)
                    signUpForNewsletter(subscription, helpers, handleSuccess, handleError)
                  }}
                >
                  {
                    ({
                      values,
                      errors,
                      touched,
                      status,
                      setStatus,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <Col sm="12" lg="12" xl="12" className="px-0">
                          <InputGroup className="mb-1">
                            <FormControl
                              type="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={touched.email && errors.email ? "input-user-email error-border" : "input-user-email"}
                              placeholder="Enter your email"
                            />
                            <Button className="tvbizz-input-button ml-0 newsletter-button" variant="primary" type="submit">
                              {!isSubmitting && "Subscribe"}
                              {isSubmitting && <div className="button-loader">
                                <div className="dot1"/>
                                <div className="dot2"/>
                                <div className="dot3"/>
                              </div>}
                            </Button>
                          </InputGroup>
                        </Col>
                        <Col sm="12" className="text-center justify-content-space-betweeen px-0">
                          <FormGroup controlId="formBasicCheckbox" className="d-flex m-0">
                            <FormCheck
                              type="checkbox"
                              name="tocAccept"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              aria-checked={values.tocAccept}
                              className={touched.tocAccept && errors.tocAccept ? "error-border" : ""}
                            />
                            <FormLabel> I accept the <Link to="/terms" target="_blank" className="text-signup-link-white">Terms of Use</Link></FormLabel>
                          </FormGroup>   
                        </Col>
                        {status && status.isError && <Col sm="12" lg="6" md="6" xl="6">
                          <div className="error-message-in-form mb-2">{status.errorMsg}</div>
                        {touched.email && errors.email ? (
                            <div className="error-message-in-form mb-2">{errors.email}</div>
                          ) : <div className="error-message">&nbsp;</div>}
                        </Col>}
                        <NewsletterEnableModal
                          showDialog={status && status.showMsg && !status.isError}
                          closeDialog={() => {
                            setStatus({
                              isError: false,
                              showMsg: false,
                            })
                          }}
                        />
                      </Form>
                    )
                  }
                </Formik>
              </Col>
            </Col>
            <Col xl={{span: 6}} lg={{span:6}} md={{span: 12}} sm={{span: 12}} className="services-container mt-2 mx-auto" >
              <div>
                <h6 className="mb-2">Our services</h6>
              </div>
              <div className="">
                  <Col className="p-0">
                    <a href="https://ceetv.net" rel="noreferrer"  target="_blank" className="footer-social-link"><Image src={logo_ceetv} className="services-logo m-1"/></a>
                    <a href="https://poc.ceetv.net" rel="noreferrer"  target="_blank" className="footer-social-link"><Image src={logo_pitchplay} className="services-logo m-1"/></a>
                    <a href="https://tvbizzmagazine.com/" rel="noreferrer"  target="_blank" className="footer-social-link"><Image src={logo_tvbizzmagazine} className="services-logo m-1
                    "/></a>
                  </Col>
                </div>
            </Col>
          </Row>
        </Col>
      </Row>  
    </Container>  
  </Container>
)

export default Newsletter;
