import * as React from "react";
import {Table} from "react-bootstrap";
import "../../UserSettings/UserSettings.css";

const UserPlanTrial: React.FC = () => (
  <Table className="pricing_table">
    <thead className="pricing_header pricing-header-black">
    <tr className="pb-0">
      <th className="pb-0">
        <div className="text-center">
          <h3>TVBIZZ Trial</h3>
          <p>Get to know our service</p>
        </div>
        <span className="pricing_price pb-0">Free</span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>5 paid TVBIZZ articles</td>
    </tr>
    <tr>
      <td>Access to the TVBIZZ free-news library</td>
    </tr>
    <tr>
      <td>Access to the tvbizz video library</td>
    </tr>
    <tr>
      <td>Search news according to geographical and product criteria</td>
    </tr>
    </tbody>
  </Table>
)

export default UserPlanTrial;
