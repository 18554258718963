import * as Yup from 'yup';

export const signinFormValidation = Yup.object().shape({
  firstName: Yup.string().min(2, "The first name must contain at least 2 characters")
    .required("You need to enter your first name"),
  lastName: Yup.string().min(2, "The last name must contain at least 2 characters")
    .required("You need to enter your last name"),
  company: Yup.string().min(2, "The country name must contain at least 2 characters")
    .required("You need to enter your country of residence"),
  country: Yup.string().min(2, "The company name must contain at least 2 characters")
    .required("You need to enter the company you work for"),
  email: Yup.string().email("You need to enter a valid email")
    .required("You need to enter your email - it will be used as your username"),
  password: Yup.string().min(6, "Your password must contain at least 6 characters")
    .matches(new RegExp("^.*(?=.{6,20})(?=.*\\d)(?=.*[a-zA-Z]).*$"),
      "Your password should contain at least one letter and one number and be between 6 and 20 symbols")
    .required("You need to enter your TVBIZZ password!"),
  reenter: Yup.string().min(6, "Your password must contain at least 6 characters")
    .matches(new RegExp("^.*(?=.{6,20})(?=.*\\d)(?=.*[a-zA-Z]).*$"),
      "Your password should contain at least one letter and one number and be between 6 and 20 symbols")
    .required("You need to re-enter your TVBIZZ password!"),
})
