import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";

const UserTabSubscrSuccessful: React.FC = () => (
  <Container>
    <Row>
      <Col className="d-flex flex-column justify-content-center align-center m-5">
        <h2 className="text-center">Thank you for subscribing for TVBIZZ!</h2>
        <div className="check_mark">
          <div className="sa-icon sa-success animate">
            <span className="sa-line sa-tip animateSuccessTip"/>
            <span className="sa-line sa-long animateSuccessLong"/>
            <div className="sa-fix"/>
          </div>
        </div>
        <Button className="w-100 tvbizz-primary mx-auto my-3" variant="primary" type="submit" >
          View your subscribtion
        </Button>
      </Col>
    </Row>
  </Container>
)

export default UserTabSubscrSuccessful;
