import {Tweet} from "../../model/Tweet";
import {IMAGE_URL_LOCAL, IMAGE_URL_YOUTUBE, TEXT_LENGTH_ON_MAIN_PAGES} from "../../const/valueConst";

export function selectRandomTweet(tweets: Tweet[]): Tweet {
  const index = Math.floor(Math.random() * (tweets.length - 1))
  return tweets[index]
}

export function cutAndFormatText(text: string): string {
  if(!text) {
    return ''
  }
  text = text.length > TEXT_LENGTH_ON_MAIN_PAGES
                      ? `${text.substring(0, TEXT_LENGTH_ON_MAIN_PAGES)} ...` : text
  text = text.replace(/#/g, '')
  text = text.replace(/\^/g, '')
  return text
}

export function buildVideoLink(embedLink: string, headline: boolean): string {
  const startYouTubeIndex = embedLink.indexOf("/embed/")
  if(embedLink.indexOf("youtube") > -1 && startYouTubeIndex > -1) {
    const youTubeId = embedLink.substring(startYouTubeIndex + 7);
    return headline ? `${IMAGE_URL_YOUTUBE}${youTubeId}/maxresdefault.jpg`
                    : `${IMAGE_URL_YOUTUBE}${youTubeId}/mqdefault.jpg`
  } else {
    return `${IMAGE_URL_LOCAL}filler-video.png`
  }
}

export function checkIfDisallowedText(contents: string) {
  return contents === 'You are not allowed to read this news item.'
}

export function buildArticleLink(newsId: number, ceetv: boolean) {
  return ceetv ? `/news/single/${newsId}/ceetv` : `/news/single/${newsId}/tvbizz`
}
