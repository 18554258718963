import * as React from "react";
import {Col, Row} from "react-bootstrap";
import "./HeadlinePane.css"
import HeadlinePaneCardContainer from "./HeadlinePaneCardContainer/HeadlinePaneCardContainer";
import {Tweet} from "../../../../model/Tweet";

interface HeadlineItems {
  headlines: Tweet[],
}

const HeadlinePane: React.FC<HeadlineItems> = (props) => (
  <Row className="pane_center_row">
    <Col className="headline-pane-center px-0">
      <Row className="scroller_no_margins-cards">
        {
          props.headlines.slice(0, 3).map((newsItem, index) => {
            return <HeadlinePaneCardContainer key={index} isFirst={index === 1} newsItem={newsItem} />
          })
        }
      </Row>  
    </Col>
  </Row>
)

export default HeadlinePane;
