import React from "react";
import {Link} from "react-router-dom";
import {Image} from "react-bootstrap";
import icon_play from "../../../resources/icons/icon-play.svg";

interface VideoTitleData {
  title: string,
  videoId: number,
}

const VideoTitle: React.FC<VideoTitleData> = (props) => (
  <React.Fragment>
    <Link to={`/watch/single/${props.videoId}`} className="headline-link">
      <Image src={icon_play} className="icon-small" />{props.title}
    </Link>
  </React.Fragment>
)

export default VideoTitle;
