import React from "react";
import {Link} from "react-router-dom";


interface TitleData {
    country: String,
    video: boolean,
}

const TitleElement: React.FC<TitleData> = (props) => (
    <React.Fragment>
        {(props.country === 'CEETV' || props.country === 'TVBIZZ') &&
            <span className="headline-country-link">
        {props.country}
            </span>}
        {!props.video && props.country !== 'CEETV' && props.country !== 'TVBIZZ' &&
            <Link className="headline-country-link" to={`/news/country/${props.country}`}>
                {props.country}
            </Link>}
        {props.video && props.country !== 'CEETV' && props.country !== 'TVBIZZ' &&
            <Link className="headline-country-link" to={`/watch/country/${props.country}`}>
                {props.country}
            </Link>}
    </React.Fragment>
)

export default TitleElement;