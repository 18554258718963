import {Dispatch} from "react";
import {
  CHANGE_USER_VIEW_EVENT, confirmUser, editUserData,
  loginUser, registerUserInStripe,
} from "../../store/actions/user/user.actions";
import {UserSettingsData} from "../../model/UserSettingsData";
import {axiosInstance} from "../../const/axiosClient";
import {userConsent, userListView} from "../../const/urlConst";
import {CONSENT_COOKIE_NAME} from "../../const/valueConst";

export async function loginUserWithParams(
  username: string,
  password: string,
  handleSuccess: () => void,
  handleError: () => void,
  dispatch: Dispatch<any>)
{
  await dispatch(loginUser(username, password, handleSuccess, handleError))
}

export async function registerStripeCustomer(
  email: string,
  handleSuccess: () => void,
  handleError: () => void,
  dispatch: Dispatch<any>)
{
  await dispatch(registerUserInStripe(email, handleSuccess, handleError))
}

export async function confirmUserAccount(
  userId: number,
  token: number,
  handleSuccess: () => void,
  handleError: () => void,
  dispatch: Dispatch<any>)
{
  await dispatch(confirmUser(token, userId, handleSuccess, handleError))
}


export async function changeUserView(
  listView: boolean,
  isLogged: boolean,
  dispatch: Dispatch<any>)
{
  dispatch({
    type: CHANGE_USER_VIEW_EVENT,
    listView: listView,
  });
  if(isLogged) { // save to server
    await axiosInstance.put(`${userListView}`,
      {
        setting: listView,
    });
  }
}

export async function changeUserData(userData: UserSettingsData,
                                     handleSuccess: () => void,
                                     handleError: () => void,
                                     dispatch: Dispatch<any>)
{
  await dispatch(editUserData(userData, handleSuccess, handleError))
}

export function isConsentStatusAvailable(): boolean {
  return document.cookie.indexOf(CONSENT_COOKIE_NAME) > -1
}

export async function setUserConsent(isConsentGiven: boolean, closeDialog: () => void) {
  createConsentCookie(isConsentGiven)
  const {data} = await axiosInstance.put(`${userConsent}`,
    {
      setting: isConsentGiven,
  });
  if(data.result || data.errorMessage === 'No user is logged or the current session in invalid.') {
    closeDialog()
  }
}

function createConsentCookie(isConsentGiven: boolean) {
  let expires = '';
  const date = new Date();
  if (!isConsentGiven) {
    date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
    expires = '; expires=" + date.toUTCString()'
  } else {
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    expires = '; expires=" + date.toUTCString()'
  }
  document.cookie = "tvbizz-consent=" + isConsentGiven + expires + "; path=/";
}


