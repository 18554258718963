import {TweetVideo} from "../../model/TweetVideo";
import {Tweet} from "../../model/Tweet";

export function dateDifferenceWithString(tweetDate: string | null): string {
  const convertedDate = tweetDate ? new Date(Date.parse(tweetDate)) : new Date()
  return dateDifferenceWithStart(convertedDate, new Date())
}

export function dateDifference(tweetDate: Date): string {
  return dateDifferenceWithStart(tweetDate, new Date())
}

export function dateDifferenceWithStart(tweetDate: Date, nowDate: Date) {
  const diff = Math.abs((nowDate.getTime() - tweetDate.getTime()) / 1000);
  if(diff < 60) {
    return "just now"
  } else if(diff < 3600) {
    return `${Math.floor(diff / 60)} mins`
  } else if(diff < 86400) {
    return `${Math.floor(diff / 3600)}h`
  } else if(diff < 31536000) {
    return `${Math.floor(diff / 86400)}d`
  } else {
    return `${Math.floor(diff / 31536000)}y`
  }
}

export function getLastDateForNews(news: Tweet[]): number | null {
  if(!news || news.length < 1) {
    return null
  }
  const approveDate = news[news.length - 1].approveDate
  return approveDate ? Date.parse(approveDate) : null // check if approve date is null
}

export function getLastDateForVideos(videos: TweetVideo[]): number | null {
  if(!videos || videos.length < 1) {
    return null
  }
  const approveDate = videos[videos.length - 1].approveDate
  return approveDate ? Date.parse(approveDate) : null // check if approve date is null
}

export function compareTwoItemDates(date1: string | null, date2: string | null): number {
  const d1 = date1 ? new Date(Date.parse(date1)) : new Date()
  const d2 = date2 ? new Date(Date.parse(date2)) : new Date()
  return d1 > d2 ? -1 : 1
}
