import update from "immutability-helper";
import {
  LOAD_BANNERS_COMPLETED_EVENT,
  LOAD_BANNERS_NOT_COMPLETED_EVENT,
  LOAD_COMPLETED_EVENT,
  LOAD_MAIN_COMPLETED_EVENT,
  LOAD_MAIN_NOT_COMPLETED_EVENT,
  LOAD_NOT_COMPLETED_EVENT,
  LOAD_TRENDING_COMPLETED_EVENT, LOAD_TRENDING_NOT_COMPLETED_EVENT,
  LoadBannersCompletedAction,
  LoadBannersNotCompletedAction,
  LoadCompletedAction,
  LoadMainCompletedAction,
  LoadMainNotCompletedAction,
  LoadNotCompletedAction,
  LoadTrendingCompletedAction,
  LoadTrendingNotCompletedAction
} from "../../actions/load/load.actions";

export interface LoadReducerState {
  isFullyLoaded: boolean,
  areBannersLoaded: boolean,
  areMainArticlesLoaded: boolean,
  areTrendingArticlesLoaded: boolean,
}

const loadState = {
  isFullyLoaded: false,
  areBannersLoaded: false,
  areMainArticlesLoaded: false,
  areTrendingArticlesLoaded: false,
}

export default function loadReducer(state: LoadReducerState = loadState,
                                    action: LoadCompletedAction | LoadNotCompletedAction | LoadBannersCompletedAction |
                                        LoadBannersNotCompletedAction | LoadMainCompletedAction | LoadMainNotCompletedAction
                                    | LoadTrendingCompletedAction | LoadTrendingNotCompletedAction
): LoadReducerState {
  switch (action.type) {
    case LOAD_COMPLETED_EVENT:
      return update(state, {isFullyLoaded: {$set: true}})
    case LOAD_NOT_COMPLETED_EVENT:
      return update(state, {isFullyLoaded: {$set: false}})
    case LOAD_MAIN_COMPLETED_EVENT:
      return update(state, {areMainArticlesLoaded: {$set: true}})
    case LOAD_MAIN_NOT_COMPLETED_EVENT:
      return update(state, {areMainArticlesLoaded: {$set: false}})
    case LOAD_BANNERS_COMPLETED_EVENT:
      return update(state, {areBannersLoaded: {$set: true}})
    case LOAD_BANNERS_NOT_COMPLETED_EVENT:
      return update(state, {areBannersLoaded: {$set: false}})
    case LOAD_TRENDING_COMPLETED_EVENT:
      return update(state, {areTrendingArticlesLoaded: {$set: true}})
    case LOAD_TRENDING_NOT_COMPLETED_EVENT:
      return update(state, {areTrendingArticlesLoaded: {$set: false}})
    default:
      return state;
  }
}
