import * as React from "react";
import {Container} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./MainFooter.css";
import FooterMenus from "./FooterMenus/FooterMenus";

const MainFooter: React.FC = () => {
  const location = useLocation()
  const showFooter = !location.pathname.includes('/watch') &&
                     !location.pathname.includes('/signin') &&
                     !location.pathname.includes('/signup') &&
                     !location.pathname.includes('/news') &&
                     !location.pathname.includes('/activate') &&
                     !location.pathname.includes('/startuser') &&
                     !location.pathname.includes('/company') &&
                     !location.pathname.includes('/show') &&
                     !location.pathname.includes('/trending') &&
                     !location.pathname.includes('/search') &&
                     !location.pathname.includes('/country') &&
                     !location.pathname.includes('/profile') &&
                     !location.pathname.includes('/logout') &&
                     !location.pathname.includes('/learnmore') &&
                     !location.pathname.includes('/inbox') &&
                     !location.pathname.includes('/people')
  return (
    <React.Fragment>
      {showFooter && <Container className="main_footer py-2" fluid>
          <FooterMenus />
        </Container>
      }
    </React.Fragment>
  )
}

export default MainFooter;
