import React, {Dispatch} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainHeader from "./Header/MainHeader";
import CenterPane from "./CenterPane/CenterPane";
import MainFooter from "./Footer/MainFooter";
import SiteContainerPane from "./Loader/SiteContainerPane";
import {AppState} from "../store/reducers/rootReducer";
import {connect} from "react-redux";
import {loadInitial} from "../hooks/initial/loadInitial";
import {areMainLoaded, isFullyLoaded} from "../store/selectors/load/load.selector";
import ConsentModal from "./Modals/ConsentModal/ConsentModal";

interface PropertiesFromState {
  isFullyLoaded: boolean,
  areMainLoaded: boolean,
}

interface DispatchFunctions {
  initializeData: () => void,
}

type LocalProps = PropertiesFromState & DispatchFunctions;

const mapStateToProps = (state: AppState): PropertiesFromState => {
  return {
    isFullyLoaded: isFullyLoaded(state),
    areMainLoaded: areMainLoaded(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchFunctions => {
  return {
    initializeData: () => loadInitial(dispatch),
  }
}

class TVBIZZPage extends React.Component<LocalProps> { // do the loading of initial data here

  componentDidMount() {
    this.props.initializeData()
  }

  render() {
    return (
      <Router>
        <SiteContainerPane>
          {this.props.areMainLoaded && <MainHeader/>}
          {this.props.areMainLoaded && <CenterPane/>}
          {this.props.isFullyLoaded && <ConsentModal />}
          {this.props.areMainLoaded && <MainFooter/>}
        </SiteContainerPane>
      </Router>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TVBIZZPage);
