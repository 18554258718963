import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import UserPlanTrial from "./PlanTabs/UserPlanTrial";
import UserPlanPaid from "./PlanTabs/UserPlanPaid";
import {Link} from "react-router-dom";



const AccountPlans: React.FC = () => {
  return (
    <Container>
      <Row className="d-flex">
        <Col xs="12" sm="12" lg="8" className="mt-5 mx-auto d-flex pricing-container-main aligne-start">
          <UserPlanTrial/>
          <UserPlanPaid/>
        </Col>
        <Col xs="12" sm="12" lg="8" className="mt-1 mb-4 mx-auto d-flex pricing-container-main justify-content-center">
          <Button className="w-100 tvbizz-primary black" variant="primary" type="submit">
            <Link to="/SignUp" className="button-text-link">Register your account now!</Link>
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AccountPlans;
