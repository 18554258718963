import React from "react";
import {tranformCountry} from "../../../utils/country/countryUtils";
import {Tweet} from "../../../model/Tweet";
import TitleElement from "./Common/TitleElement";

interface PaneNewsItem {
  newsItem: Tweet,
}


const LatestArticleCountryPane: React.FC<PaneNewsItem> = (props) => {
  const country = tranformCountry(props.newsItem.country, props.newsItem.ceetv)
  return (
    <React.Fragment>
      <TitleElement country={country} video={false} />
      {/*{!props.newsItem.free && <Image src={tvbizz_paid} className="icon-paid-news ml-2" title="Paid news item" />}
      {props.newsItem.free && props.newsItem.pr && <Image src={tvbizz_pr} title="Press release" className="icon-paid-news ml-2" />}
      {props.newsItem.free && !props.newsItem.pr && <Image src={tvbizz_mr} title="Media report" className="icon-paid-news ml-2" />}*/}
    </React.Fragment>
  )
}

export default LatestArticleCountryPane;
