import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

const TermsPage: React.FC = () => {
  window.scrollTo(0, 0)
  return (
    <React.Fragment>
      <Container>
        <Row className="pane_center_row about-cont p-0 d-flex justify-content-center">
          <Col xl={{span: 8}} lg={{span:8}} md={{span: 12}} className="m-auto p-0">
            <Row>
              <Col>
                <h3 className="w-100 my-4">Terms of Use</h3>
              </Col>
            </Row>
            <Row>
              <Col className="my-4">
                <p>
                  <span style={{fontWeight: 800}}>1. General Terms and Conditions</span>
                  <br/><br/>
                  1.1 The following terms and conditions will apply to all users of our Website. By registering on
                  TVBIZZ, or by accessing our Website, including our mobile applications, you agree to follow these
                  terms and conditions.
                  <br/><br/>
                  1.2 If you are using TVBIZZ on behalf of a company or organization, you are nevertheless personally
                  subject to these terms and conditions.
                  <br/><br/>
                  1.3 By registering on TVBIZZ, or by accessing our Website, including our mobile applications, you
                  acknowledge that you have read the terms and conditions, you understand them and you agree to be bound
                  by them.
                  <br/><br/>
                  1.4. These terms and conditions can be changed at any time by TVBIZZ Ltd. All users will be duly
                  informed of any changes.
                  <br/><br/>
                  1.5 The user agrees to pay any subscription fees, determined by TVBIZZ Ltd., to get access to the
                  services available on TVBIZZ.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>2. Content Submission and Licensing</span>
                  <br/><br/>
                  2.1 You acknowledge and warrant that any information you provide on TVBIZZ is accurate to the best of
                  your knowledge and not in violation of any copyrights, any other third party rights or in breach of
                  any contractual agreements.
                  <br/><br/>
                  2.2 You grant TVBIZZ a non-exclusive, irrevocable, unlimited and royalty-free right to us to copy,
                  prepare derivative works of, improve, distribute, publish, remove, use and commercialize, in any way
                  now known or in the future discovered, any information or content you provide on TVBIZZ, without any
                  further consent, notice and/or compensation to you or to any third parties.
                  <br/><br/>
                  2.3 You agree that TVBIZZ cannot be held responsible for any damages, losses and costs, related to you
                  or any third party. You agree that TVBIZZ cannot be held responsible for the loss of any information
                  provided by you on the Website.
                  <br/><br/>
                  2.4 You agree that TVBIZZ cannot be held responsible or liable for the accuracy or completeness of the
                  information on TVBIZZ.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>3. User Eligibility, Rights, Privileges and Obligations</span>
                  <br/><br/>
                  3.1 TVBIZZ and its mobile application are a restricted business to business social network. As such,
                  their intention and purpose are to serve only professionals in the TV industry. TVBIZZ has the right
                  to approve or decline any request for membership at any time.
                  <br/><br/>
                  3.2 In order to use the TVBIZZ service you have to create a user profile. After your initial
                  registration, during your first login to the Website you will be prompted to fill in your profile
                  details. These details include: name, e-mail, company, position, website, about me and others. Only
                  after you have filled in the details to your user profile, you will be able to use the Website.
                  <br/><br/>
                  3.3 Users may access the Website and our mobile application, according to their subscription package,
                  print any content on paper and use the information on TVBIZZ in any way that does not breach the terms
                  of use.
                  <br/><br/>
                  3.4 Users may not copy, prepare derivative works of, improve, distribute, publish, use and
                  commercialize, in any way, the information provided on TVBIZZ and on our mobile applications.
                  <br/><br/>
                  3.5 You agree that TVBIZZ cannot be held responsible for the conduct, online or offline, or any user
                  of TVBIZZ.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>4. Secure Access and Account Protection</span>
                  <br/><br/>
                  4.1 Access to TVBIZZ and its mobile application is strictly individual. Users may not grant access to
                  to the Website our our mobile application to another party.
                  <br/><br/>
                  4.2 You may access, operate and/or use the one and only one account on TVBIZZ in your name or in the
                  name of your business.
                  <br/><br/>
                  4.3. You may not access, operate and/or use accounts of other individuals or other businesses.
                  <br/><br/>
                  4.4 You agree to keep your password secure and confidential.
                  <br/><br/>
                  4.5 Each subscription is for a single user only. It cannot be sold, shared, loaned or transferred to
                  another party.
                  <br/><br/>
                  4.6 Users can terminate their registration and/or subscription at any time.
                  <br/><br/>
                  4.7 TVBIZZ has the right to suspend or terminate subscriptions and/or accounts to the Website or our
                  mobile applications if a user breaches these terms and conditions, with or without notice and without
                  further obligation to the user.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>5. Subscriptions and Payment</span>
                  <br/><br/>
                  5.1 If you choose to use one of our subscription packages, you agree to pay the applicable fees, plus
                  all applicable taxes. Failure to pay any due fees may result in the termination of your subscription.
                  <br/><br/>
                  5.2. Subscriptions can be canceled at any time by contacting our Sales department. We do not guarantee
                  refunds due to lack of usage or dissatisfaction.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>6. Privacy Policy</span>
                  <br/><br/>
                  6.1 By registering on TVBIZZ, or by accessing our Website, including our mobile applications, you
                  agree to follow the Privacy Policy. The full text of our Privacy Policy is accessible <a
                  href="privacy" style={{color: '#5d8ab4'}} className="linkunderlineblue" target="_blank">here</a>.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>7. Law and Jurisdiction</span>
                  <br/><br/>
                  7.1 These terms, together with our privacy policy, constitute the entire agreement between our users
                  and TVBIZZ Ltd. in relation to the use of our website and our mobile applications, and supersede all
                  previous agreements in respect of the use of the services on this website.
                  <br/><br/>
                  7.2 These terms of use will be governed by and construed in accordance with Bulgarian law and EU
                  regulations.
                </p><br/><br/>
                <p>
                  <span style={{fontWeight: 800}}>8. VAT registration</span>
                  <br/><br/>
                  8.1. TVBIZZ Ltd. VAT registration number is 201881223.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TermsPage;
