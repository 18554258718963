import * as React from "react";
import HeadlineArticle from "../../../CommonComponents/HeadlineArticle/HeadlineArticle";
import "./LatestArticleList.css";
import {Col, Row} from "react-bootstrap";
import {Tweet} from "../../../../../../model/Tweet";

interface LatestArticles {
  latest: Tweet[],
}

const LatestArticlesList: React.FC<LatestArticles> = (props) => {
  return (
    <React.Fragment>
      <Row className="latest_center_row">
        {
          props.latest.slice(0, props.latest.length - Math.round(props.latest.length / 14)).map((newsItem, index) => {
            return (
              <Col key={newsItem.tweetId} md={{span: 12}} className="pr-0 pl-0">
                <HeadlineArticle
                  key={newsItem.tweetId}
                  article={newsItem}
                  articleNo={index}
                  withDate={true}
                  isTrending={false}
                />
              </Col>
            )
          })
        }
      </Row>
    </React.Fragment>
  )

}

export default LatestArticlesList;
