import * as React from 'react';
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import "./MainMenuItem.css"

const MainMenuItem: React.FunctionComponent<{
  linkAddress: string;
  caption: string;
}> = (props) => (
  <Nav.Item className={props.caption !== "Trending" ? "menu_item_b_left" : "menu_item_b_left no-line-item-right"}>
    <Link to={`/${props.linkAddress}`} className="menu_script">{props.caption}</Link>
  </Nav.Item>
);

export default MainMenuItem;
