import {AppState} from "../../reducers/rootReducer";

export function isFullyLoaded(state: AppState): boolean {
  return state.load.isFullyLoaded
}

export function areBannersLoaded(state: AppState): boolean {
  return state.load.areBannersLoaded
}

export function areMainLoaded(state: AppState): boolean {
  return state.load.areMainArticlesLoaded
}

export function areTrendingLoaded(state: AppState): boolean {
  return state.load.areTrendingArticlesLoaded
}
