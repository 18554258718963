import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";
import {Dispatch} from "react";
import {axiosInstance} from "../../../const/axiosClient";
import {showData, tagRelatedItems} from "../../../const/urlConst";
import {Show} from "../../../model/Show";

export const LOAD_SHOW_COMPLETED_EVENT = 'LOAD_SHOW_COMPLETED';
export const LOAD_SHOW_NOT_COMPLETED_EVENT = 'LOAD_SHOW_NOT_COMPLETED';
export const LOAD_SHOW_RELATED_ITEMS_EVENT = 'LOAD_SHOW_RELATED_ITEMS';
export const CLEAR_SHOW_DATA_EVENT = 'CLEAR_SHOW_DATA';

export type LOAD_SHOW_COMPLETED = typeof LOAD_SHOW_COMPLETED_EVENT;
export type LOAD_SHOW_NOT_COMPLETED = typeof LOAD_SHOW_NOT_COMPLETED_EVENT;
export type LOAD_SHOW_RELATED_ITEMS = typeof LOAD_SHOW_RELATED_ITEMS_EVENT;
export type CLEAR_SHOW_DATA = typeof CLEAR_SHOW_DATA_EVENT;

export interface LoadShowCompletedAction {
  type: LOAD_SHOW_COMPLETED,
  show: Show | null,
}

export interface LoadShowRelatedItemsAction {
  type: LOAD_SHOW_RELATED_ITEMS,
  tweets: Tweet[],
  videos: TweetVideo[],
}

export interface LoadShowNotCompletedAction {
  type: LOAD_SHOW_NOT_COMPLETED,
}

export interface ClearShowData {
  type: CLEAR_SHOW_DATA,
}

export const loadShow = (showName: string) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${showData}${encodeURIComponent(showName)}`);
    dispatch( {
      type: LOAD_SHOW_COMPLETED_EVENT,
      show: data.found ? {
        showId: data.show.showId,
        name: data.show.name,
        logo: data.show.logo,
        countries: data.show.countries,
      } : null
    });
  }
}

export const loadShowRelatedNews = (showName: string, before: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const lastItemTime = before ? before : Date.now();
    const { data } = await axiosInstance.get(`${tagRelatedItems}${encodeURIComponent(showName)}&before=${lastItemTime}`);
    dispatch( {
      type: LOAD_SHOW_RELATED_ITEMS_EVENT,
      tweets: data.news,
      videos: data.videos,
    });
  }
}

export const markShowStateAsUnloaded = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_SHOW_NOT_COMPLETED_EVENT,
    });
  }
}

export const clearShowState = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: CLEAR_SHOW_DATA_EVENT,
    });
  }
}
