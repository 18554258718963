import React from 'react';
import {NavDropdown, Image} from "react-bootstrap";
import {User} from "../../../../model/User";
import {LinkContainer} from "react-router-bootstrap";
import {useSelector} from "react-redux";
import {isFree} from "../../../../store/selectors/user/user.selector";


interface LoggedUser {
  loggedUser: User,
}

const SignedUserMenu: React.FC<LoggedUser> = (props) => {
  const isFreeUser = useSelector(isFree)
  return (
    <div className="menu_script_profile">
      {!props.loggedUser.photo && <div className="profile-menu-div">
        <span className="profile-menu">{props.loggedUser.initials}</span>
      </div>}
      {props.loggedUser.photo && <div className="profile-menu-div">
        <Image className="profile-menu-avatar" src={`https://images.tvbizz.net/image_data/${props.loggedUser.photo}`}
               fluid/>
      </div>}
      <NavDropdown title="My Profile" id="collasible-nav-dropdown" className="profile-position">
        <span className="sub-item-indicator"/>
        <div className="profile-nav-dropdown">
          <LinkContainer to={"/profile"}>
            <NavDropdown.Item className="dropdown_mainmenu">My TVBIZZ</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to={"/profile/subscription"}>
            <NavDropdown.Item className="dropdown_mainmenu">Subscription</NavDropdown.Item>
          </LinkContainer>
          {isFreeUser && <LinkContainer to={"/profile/upgrade"}>
            <NavDropdown.Item className="dropdown_mainmenu">Upgrade</NavDropdown.Item>
          </LinkContainer>}
          <LinkContainer to={"/profile/security"}>
            <NavDropdown.Item className="dropdown_mainmenu">Security</NavDropdown.Item>
          </LinkContainer>
          {<LinkContainer to={"/inbox"}>
            <NavDropdown.Item className="dropdown_mainmenu">Inbox</NavDropdown.Item>
          </LinkContainer>}
          <NavDropdown.Item href="/logout" className="dropdown_mainmenu">Logout</NavDropdown.Item>
        </div>
      </NavDropdown>
    </div>
  )
}

export default SignedUserMenu;
