import {TweetVideo} from "../../../model/TweetVideo";
import {RegionType} from "../../../model/RegionType";
import update from "immutability-helper";
import {
  CLEAR_VIDEO_DATA_EVENT,
  ClearVideoData,
  LOAD_VIDEO_HEADLINES_EVENT,
  LOAD_VIDEO_LATEST_RELATED_EVENT,
  LOAD_VIDEO_LIST_EVENT,
  LOAD_VIDEO_LOADING_EVENT,
  LOAD_VIDEO_RELATED_EVENT,
  LoadVideoHeadlinesAction,
  LoadVideoLatestRelatedAction,
  LoadVideoListAction,
  LoadVideoLoadingAction,
  LoadVideoRelatedAction
} from "../../actions/videos/video.actions";

export interface VideoReducerState {
  mainVideo: TweetVideo | null,
  headlines: TweetVideo[]
  videos: TweetVideo[],
  regionCriteria: RegionType | null,
  countryCriteria: string | null,
  loading: boolean,
  relatedVideos: TweetVideo[],
  relatedLatestVideos: TweetVideo[],
  selectedVideoItem: TweetVideo | null,
}

const videoState = {
  mainVideo: null,
  headlines:[],
  videos: [],
  regionCriteria: null,
  countryCriteria: null,
  loading: false,
  relatedVideos: [],
  relatedLatestVideos: [],
  selectedVideoItem: null,
}

export default function videoReducer (state: VideoReducerState = videoState,
                                      action: LoadVideoHeadlinesAction | LoadVideoListAction
                                      | LoadVideoLoadingAction | LoadVideoRelatedAction
                                      | LoadVideoLatestRelatedAction | ClearVideoData)
  : VideoReducerState
{
  switch (action.type) {
    case LOAD_VIDEO_LIST_EVENT:
      return update(state, {
        videos: {$push: action.videos},
        regionCriteria: {$set: action.filter.region},
        countryCriteria: {$set: action.filter.country},
      })
    case LOAD_VIDEO_HEADLINES_EVENT:
      return update(state, {
        headlines: {$set: action.headlines},
        mainVideo: {$set: action.mainVideo},
      })
    case LOAD_VIDEO_LOADING_EVENT:
      return update(state, {
        loading: {$set: action.loading},
      })
    case LOAD_VIDEO_RELATED_EVENT:
      return update(state, {
        relatedVideos: {$set: action.relatedVideos},
        relatedLatestVideos: {$set: action.relatedLatestVideos},
        selectedVideoItem: {$set: action.selectedItem},
      })
    case LOAD_VIDEO_LATEST_RELATED_EVENT:
      return update(state, {
        relatedLatestVideos: {$push: action.latestRelatedVideos},
      })
    case CLEAR_VIDEO_DATA_EVENT:
      return update(state, {
        videos: {$set: []},
        regionCriteria: {$set: null},
        countryCriteria: {$set: null},
      })
    default:
      return state;
  }
}
