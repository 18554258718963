import {Tweet} from "../../../model/Tweet";
import {TweetVideo} from "../../../model/TweetVideo";
import {SearchType} from "../../../model/SearchType";
import update from "immutability-helper";
import {
  CLEAR_SEARCH_DATA_EVENT, ClearSearchData,
  LOAD_CATEGORY_COMPLETED_EVENT,
  LOAD_COUNTRY_COMPLETED_EVENT,
  LOAD_KEYWORD_COMPLETED_EVENT,
  LOAD_PEOPLE_COMPLETED_EVENT,
  LOAD_RELATED_ITEMS_EVENT, LOAD_SEARCH_NOT_COMPLETED_EVENT,
  LoadCategoryCompletedAction,
  LoadCountryCompletedAction,
  LoadKeywordCompletedAction,
  LoadPeopleCompletedAction,
  LoadRelatedItemsAction, LoadSearchNotCompletedAction
} from "../../actions/search/search.actions";
import {MAX_ITEMS_IN_SEARCH} from "../../../const/valueConst";

export interface SearchReducerState {
  tweets: Tweet[],
  videos: TweetVideo[],
  loading: boolean,
  criteria: string | null,
  searchType: SearchType | null,
  noMoreRelatedData: boolean,
}

const searchState = {
  tweets: [],
  videos: [],
  loading: false,
  criteria: null,
  searchType: null,
  noMoreRelatedData: false,
}

export default function searchReducer(state: SearchReducerState = searchState,
                                      action: LoadPeopleCompletedAction | LoadCountryCompletedAction |
                                        LoadKeywordCompletedAction | LoadRelatedItemsAction |
                                        LoadSearchNotCompletedAction | LoadCategoryCompletedAction |
                                        ClearSearchData
) : SearchReducerState {
  switch (action.type) {
    case LOAD_PEOPLE_COMPLETED_EVENT:
      return update(state, {
        criteria: {$set: action.criteria},
        searchType: {$set: SearchType.PEOPLE},
        loading: {$set: false},
        tweets: {$push: action.tweets},
        videos: {$push: action.videos},
        noMoreRelatedData: {$set: (action.tweets.length + action.videos.length < MAX_ITEMS_IN_SEARCH)},
      })
    case LOAD_COUNTRY_COMPLETED_EVENT:
      return update(state, {
        criteria: {$set: action.criteria},
        searchType: {$set: SearchType.COUNTRY},
        loading: {$set: false},
        tweets: {$push: action.tweets},
        videos: {$push: action.videos},
        noMoreRelatedData: {$set: (action.tweets.length + action.videos.length < MAX_ITEMS_IN_SEARCH)},
      })
    case LOAD_CATEGORY_COMPLETED_EVENT:
      return update(state, {
        criteria: {$set: action.criteria},
        searchType: {$set: SearchType.CATEGORY},
        loading: {$set: false},
        tweets: {$push: action.tweets},
        videos: {$set: []},
        noMoreRelatedData: {$set: (action.tweets.length < MAX_ITEMS_IN_SEARCH)},
      })
    case LOAD_KEYWORD_COMPLETED_EVENT:
      return update(state, {
        criteria: {$set: action.criteria},
        searchType: {$set: SearchType.KEYWORD},
        loading: {$set: false},
        tweets: {$push: action.tweets},
        videos: {$push: action.videos},
        noMoreRelatedData: {$set: (action.tweets.length + action.videos.length < MAX_ITEMS_IN_SEARCH)},
      })
    case LOAD_RELATED_ITEMS_EVENT:
      return update(state, {
        tweets: {$push: action.tweets},
        videos: {$push: action.videos ? action.videos : []},
        loading: {$set: false},
        noMoreRelatedData: {$set: (action.tweets.length + action.videos.length < MAX_ITEMS_IN_SEARCH)},
      })
    case LOAD_SEARCH_NOT_COMPLETED_EVENT:
      return update(state, {
        loading: {$set: true},
      })
    case CLEAR_SEARCH_DATA_EVENT:
      return update(state, {
        searchType: {$set: null},
        tweets: {$set: []},
        videos: {$set: []},
      })
    default:
      return state
  }
}
