import {Message} from "../../../model/Message";
import {LoadMessagesAction, MessagesLoadingAction} from "../../actions/message/message.actions";
import update from "immutability-helper";

interface MessageReducerState {
  loading: boolean,
  messages: Message[],
}

const messagesState = {
  loading: false,
  messages: [],
}

export default function messagesReducer(state: MessageReducerState = messagesState,
                                        action: LoadMessagesAction | MessagesLoadingAction,
): MessageReducerState {
  switch (action.type) {
    case "LOAD_MESSAGES_AFTER":
      return update(state, {
        messages: {$push: action.messages},
        loading: {$set: false},
      })
    case "LOAD_MESSAGES_LOADING":
      return update(state, {
        loading: {$set: true},
      })
    default:
      return state
  }
}
