import {User} from "../../../model/User";
import {
  CHANGE_USER_VIEW_EVENT,
  ChangeUserView,
  LOAD_EXPIRED_EVENT,
  LOAD_LOCKED_EVENT,
  LOAD_USER_DATA_EVENT,
  LOAD_USER_EVENT,
  LOAD_USER_STRIPE_ID_EVENT,
  LoadCurrentUser,
  LoadCurrentUserAdditionalData,
  LoadStripeCustomerId,
  MarkUserAsExpired,
  MarkUserAsLocked
} from "../../actions/user/user.actions";
import update from "immutability-helper";
import {UserData} from "../../../model/UserData";

export interface UserReducerState {
  user: User | null,
  userData: UserData | null,
  stripeCustomerId: string | null,
  isFree: boolean,
  isCeetv: boolean,
  isTrial: boolean,
  isListView: boolean,
  isExpired: boolean,
  isLocked: boolean,
}

const userState = {
  user: null,
  userData: null,
  stripeCustomerId: null,
  isFree: true,
  isCeetv: false,
  isTrial: true,
  isListView: false,
  isExpired: false,
  isLocked: false,
}

export default function userReducer (state: UserReducerState = userState,
                                     action: LoadCurrentUser | MarkUserAsExpired
                                       | MarkUserAsLocked | ChangeUserView | LoadStripeCustomerId
                                       | LoadCurrentUserAdditionalData)
  : UserReducerState
{
  switch (action.type) {
    case LOAD_USER_EVENT:
      return update(state, {
        user: {$set: action.user},
        isFree: {$set: action.isFree},
        isTrial: {$set: action.isTrial},
        isListView: {$set: action.listViewEnabled},
      })
    case LOAD_LOCKED_EVENT:
      return update(state, {
        isLocked: {$set: true},
        user: {$set: null},
      })
    case LOAD_EXPIRED_EVENT:
      return update(state, {
        isExpired: {$set: true},
        user: {$set: null},
      })
    case CHANGE_USER_VIEW_EVENT:
      return update(state, {
        isListView: {$set: action.listView},
      })
    case LOAD_USER_STRIPE_ID_EVENT:
      return update(state, {
        stripeCustomerId: {$set: action.stripeCustomerId},
      })
    case LOAD_USER_DATA_EVENT:
      return update(state, {
        userData: {$set: {
          position: action.position,
          city: action.city,
          webpage: action.webpage,
          linkedin: action.linkedin,
          facebook: action.facebook,
        }},
      })
    default:
      return state;
  }
}
