import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TVBIZZPage from "./Components/TVBIZZPage";
import {Provider} from "react-redux";

// configure the store depending on the environment
let configureStore: Function;

if(process.env.NODE_ENV === 'development') {
  configureStore = require('./store/configureStore.dev').configureStore;
} else {
  configureStore = require('./store/configureStore').configureStore;
}

const store = configureStore();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <TVBIZZPage />
      </Provider>
    </div>
  );
}

export default App;
