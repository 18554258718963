import * as Yup from "yup";

export const passwordChangeFormValidation = Yup.object().shape({
  oldpass: Yup.string().min(2, "The existing password is at least 2 characters long")
    .required("You need to enter your old password"),
  newpass1: Yup.string().required('No new password provided.').min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'The password must contain at least one letter, one number and one special character.'),
  newpass2: Yup.string().required('No new password provided.').min(6, 'Password is too short - should be 6 chars minimum.')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'The password must contain at least one letter, one number and one special character.')
})
