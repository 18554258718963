import * as React from "react";
import {Table} from "react-bootstrap";
import "../../UserSettings/UserSettings.css";

const UserPlanPaid: React.FC = () => (
  <React.Fragment>
    <Table className="pricing_table shadow-box">
      <thead className="pricing_header">
      <tr className="pb-0">
        <th className="pb-2">
          <div className="text-center">
            <h3>TVBIZZ Annual Subscription</h3>
            <p>Get your TVBIZZ Subscription for more Personalised Content</p>
          </div>
          <span className="pricing_price pb-0">€100</span> / year<br/>
          <span>Charged annually</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Full access to the TVBIZZ news library</td>
      </tr>
      <tr>
        <td>Full access to the TVBIZZ video library</td>
      </tr>
      <tr>
        <td>Search news according to geographical and product criteria</td>
      </tr>
      <tr>
        <td>Look for news related to a company or a show</td>
      </tr>
      <tr>
        <td>Search news according to geographical and product criteria</td>
      </tr>
      <tr>
        <td>Receive regular updates and breaking news from tvbizz</td>
      </tr>
      </tbody>
    </Table>
  </React.Fragment>
)

export default UserPlanPaid;
