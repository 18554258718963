import * as React from "react";
import {Container, Col, Row} from "react-bootstrap";
import TrendingPane from "./TrendingPane/TrendingPane";
import {connect} from "react-redux";
import {
  getHeadline,
  getHeadlineNews,
  getLatestTweets,
  getTrendingTweets
} from "../../../store/selectors/tweets/tweet.selector";
import {getMainVideo} from "../../../store/selectors/videos/videos.selector";
import BannersHomeLeft from "../../Banners/BannersHomeLeft/BannersHomeLeft";
import BannersHomeRight from "../../Banners/BannersHomeRight/BannersHomeRight";
import Newsletter from "../../Newsletter/Newsletter";
import {
  getCompanyTags,
  getPeopleTags,
  getShowTags,
} from "../../../store/selectors/trending/trending.selector";
import HeadlineNews from "./HeadlineNews/HeadlineNews";
import {Tweet} from "../../../model/Tweet";
import {AppState} from "../../../store/reducers/rootReducer";
import {areTrendingLoaded} from "../../../store/selectors/load/load.selector";
import {Dispatch} from "react";
import {loadMainPageData} from "../../../hooks/initial/loadInitial";
import {TweetVideo} from "../../../model/TweetVideo";
import {TrendingItem} from "../../../model/TrendingItem";

interface MainPageLocalData {
  isLoadTrendingComplete: boolean,
  latestNews: Tweet[],
  headlineItem: Tweet | null,
  headlineNews: Tweet[],
  trendingNews: Tweet[],
  mainVideo: TweetVideo | null,
  companyTags: TrendingItem[],
  showTags: TrendingItem[],
  peopleTags: TrendingItem[],
}

interface DispatchMainPageLoadingFunction {
  loadLatestDataNews: () => void,
}

type MainPageProps = MainPageLocalData & DispatchMainPageLoadingFunction;


const mapStateToProps = (state: AppState): MainPageLocalData => {
  return {
    companyTags: getCompanyTags(state),
    headlineItem: getHeadline(state),
    headlineNews: getHeadlineNews(state),
    mainVideo: getMainVideo(state),
    peopleTags: getPeopleTags(state),
    showTags: getShowTags(state),
    trendingNews: getTrendingTweets(state),
    isLoadTrendingComplete: areTrendingLoaded(state),
    latestNews:getLatestTweets(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchMainPageLoadingFunction => {
  return {
    loadLatestDataNews: () => loadMainPageData(dispatch),
  }
}

class MainPage extends React.Component<MainPageProps> {

  componentDidMount() {
    this.props.loadLatestDataNews()
  }

  render() {
    /*const headlineItem = useSelector(getHeadline);
    const headlineNews = useSelector(getHeadlineNews);
    const trendingNews = useSelector(getTrendingTweets);
    const mainVideo = useSelector(getMainVideo)
    const companyTags = useSelector(getCompanyTags)
    const showTags = useSelector(getShowTags)
    const peopleTags = useSelector(getPeopleTags)*/
    // const headlinesData = headlineItem ? [headlineItem].concat(headlineNews) : [...headlineNews]
    return (
        <React.Fragment>
          <Container className="p-0 sm-news-container mt-0">
            <Row className="pane_center_row p-0 d-flex justify-content-center">
              <BannersHomeLeft />
              <Col xl={{span: 8}} lg={{span: 12}} md={{span: 12}} className="p-0">
                <Row className="trending_main_row pb-0 justify-content-between">
                  <HeadlineNews headline={this.props.headlineItem} headlines={this.props.headlineNews} />
                  <TrendingPane
                      latest={this.props.latestNews}
                      trending={this.props.trendingNews}
                      mainVideo={this.props.mainVideo}
                      companyTags={this.props.companyTags}
                      showTags={this.props.showTags}
                      peopleTags={this.props.peopleTags}
                      trendingLoadComplete={this.props.isLoadTrendingComplete}
                  />
                </Row>
              </Col>
              <BannersHomeRight />
            </Row>
          </Container>
          <Newsletter />
        </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
