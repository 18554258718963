import * as React from "react";
import {Col} from "react-bootstrap";
import HeadlinePaneCard from "../HeadlinePaneCard/HeadlinePaneCard";
import "./HeadlinePaneCardContainer.css";
import {Tweet} from "../../../../../model/Tweet";

interface CardPosition {
  isFirst: boolean
  newsItem: Tweet,
  key: number,
}

const HeadlinePaneCardContainer: React.FC<CardPosition> = (props) => (
  <Col xs="12" sm="12" lg="4" xl="4" md="4" className="card_margins-sm">
    <HeadlinePaneCard news={props.newsItem} />
  </Col>
)

export default HeadlinePaneCardContainer;
