import {Banner} from "../../../model/Banner";
import {LoadAllBannersAction} from "../../actions/banners/banners.actions";
import update from "immutability-helper";

interface BannersReduceState {
  frontPageLeft: Banner[],
  frontPageRight: Banner[],
  innerPageLeft: Banner[],
  innerPageRight: Banner[],
}

const bannerState = {
  frontPageLeft: [],
  frontPageRight: [],
  innerPageLeft: [],
  innerPageRight: [],
}

export default function bannersReducer(state: BannersReduceState = bannerState,
                                       action: LoadAllBannersAction,
) : BannersReduceState {
  switch (action.type) {
    case "LOAD_ALL_BANNERS":
      return update(state, {
        frontPageLeft: {$set: action.frontPageLeft},
        frontPageRight: {$set: action.frontPageRight},
        innerPageLeft: {$set: action.innerPageLeft},
        innerPageRight: {$set: action.innerPageRight},
      })
    default:
      return state
  }
}
