import * as Yup from 'yup';

export const subscribeFormValidation = Yup.object().shape({
  address: Yup.string().min(5, "The address must contain at least 5 characters")
    .required("You need to enter the address of your"),
  city: Yup.string().min(2, "The country name must contain at least 2 characters")
    .required("You need to enter your country of residence"),
  country: Yup.string().min(2, "The country name must contain at least 2 characters")
    .required("You need to enter your country of residence"),
  zip: Yup.string().min(4, "The zip code must contain at least 4 characters")
    .required("You need to enter your zip code"),
})
