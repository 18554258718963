import * as React from "react";
import "../Modals.css"
import Modal from 'react-bootstrap/Modal';
import ModalButtonsSimple from "../ModalButtons/ModalsButtonSimple";

interface PaidNewsGuestModalData {
  showDialog: boolean,
  closeDialog: () => void
}

const NewsletterEnableModal: React.FC<PaidNewsGuestModalData> = (props) => {
  return (
    <Modal show={props.showDialog} className="modal-paid-news">
      <Modal.Header className="text-center justify-content-center">
        <Modal.Title>Newsletter Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="text-news">
          Thanks for your interest in TVBIZZ and in the TVBIZZ newsletter. You will now receive a mail with the
          newsletter subscription confirmation link on email address you wanted to subscribe with to our newsletter.
          After clicking the link and confirming your mail you will be added to our distribution list.
        </div>
      </Modal.Body>
      <ModalButtonsSimple closeDialog={props.closeDialog} />
    </Modal>
  )
}

export default NewsletterEnableModal;
