import * as React from "react";
import {Button} from "react-bootstrap";
import {Container, Col} from "react-bootstrap";
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import "./Signin.css";
import {withRouter, Link, RouteComponentProps} from "react-router-dom";
import {Formik, FormikHelpers} from "formik";
import {confirmFormValidation} from "./SigninValidation/confirmFormValidation";
import {AppState} from "../../store/reducers/rootReducer";
import {getUser} from "../../store/selectors/user/user.selector";
import {Dispatch} from "react";
import {confirmUserAccount} from "../../hooks/user/userFunctions";
import {connect} from "react-redux";

interface UserIdInfo {
  userId: string,
}

interface UserLoggedInfo {
  isUserLogged: boolean,
}

interface ConfirmData {
  token: string,
}

interface DispatchConfirmFunctions {
  confirmAccountWithWebToken: (
    token: number,
    userId: number,
    handleSuccess: () => void,
    handleError: () => void,
  ) => void,
}

type UserConfirmData = UserLoggedInfo & DispatchConfirmFunctions & RouteComponentProps<UserIdInfo>

const mapStateToProps = (state: AppState): UserLoggedInfo => {
  return {
    isUserLogged: getUser(state) !== null,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchConfirmFunctions => {
  return {
    confirmAccountWithWebToken: (
      token: number,
      userId: number,
      handleSuccess: () => void,
      handleError: () => void) => confirmUserAccount(userId, token, handleSuccess, handleError, dispatch),
  }
}

const Confirm: React.FC<UserConfirmData> = (props) => (
  <Container className="p-0">
    <div className="signing-content">
      <Col xs="12" sm="12" lg="6" className="my-5">
        <p className="w-100 text-right">
          <Link to="signin" className="text-signup-link"> Already have an account? Sign in.</Link>
        </p>
        <h2 className="my-4">Activate your TVBIZZ account</h2>
        <p className="text-muted mb-4">
          Please, enter to 6-digit code you have received on your email to activate your account.
        </p>
        <Formik
          initialValues={
            {
              token: "",
            }
          }
          validationSchema={confirmFormValidation}
          onSubmit={(confirmData: ConfirmData, helpers:FormikHelpers<ConfirmData>) => {
            helpers.setSubmitting(true)
            props.confirmAccountWithWebToken(
              parseInt(confirmData.token, 10),
              parseInt(props.match.params.userId, 10), () => {
              helpers.resetForm()
              props.history.replace("/startuser")
              helpers.setSubmitting(false)
            }, () => {
              helpers.setStatus({
                isError: true,
                errorMsg: "The token is invalid or has expired, click the resent button to get another token!"
              })
              helpers.setSubmitting(false)
            })
          }}
        >
          {
            ({
               values,
               errors,
               touched,
               status,
               isSubmitting,
               handleChange,
               handleBlur,
               handleSubmit,
             }) => (
              <Form className="row" onSubmit={handleSubmit}>
                {status && status.isError && <Col sm="12" lg="12" xl="12" >
                  <div className="error-message-in-form mb-2">{status.errorMsg}</div>
                </Col>}
                <Col sm="12" lg="12" xl="12">
                  <FormGroup controlId="formBasicToken">
                    <FormLabel>Activation Token: <Button className="tvbizz-primary-small">Resend</Button></FormLabel>
                    <FormControl
                      type="text"
                      name="token"
                      value={values.token}
                      onChange={handleChange}
                      htmlSize={6}
                      onBlur={handleBlur}
                      className={touched.token && errors.token ? "error-border" : ""}
                      placeholder="Enter activation token"
                    />
                    {touched.token && errors.token ? (
                      <div className="error-message">{errors.token}</div>
                    ): <div className="error-message">&nbsp;</div>}
                  </FormGroup>
                  {status && status.isError && <div className="error-message">{status.errorMsg}</div>}
                </Col>
                <Col sm="12" lg="12" xl="12" className="my-3 text-center">
                  <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                    {!isSubmitting && "Confirm account"}
                    {isSubmitting && <div className="button-loader">
                      <div className="dot1"/>
                      <div className="dot2"/>
                      <div className="dot3"/>
                    </div>}
                  </Button>
                </Col>
              </Form>
            )
          }
        </Formik>
      </Col>
    </div>
  </Container>
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Confirm));
