import * as React from "react";
import {Banner} from "../../model/Banner";
import {Image} from "react-bootstrap";

interface BannerData {
  banner: Banner,
}

const BannerFrame: React.FC<BannerData> = (props) => (
  <div className="mb-2">
    <a href={props.banner.link} target="_blank" rel="noreferrer">
      <Image src={`https://dm9a5aaaxmld9.cloudfront.net/banners/${props.banner.file}`} alt={props.banner.name} />
    </a>
  </div>
)

export default BannerFrame;
