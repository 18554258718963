import * as React from "react";
import {Tweet} from "../../../../../model/Tweet";
// import NewsTitle from "../../../../Common/Title/NewsTitle";
import HeadlineTrendingNewsTitle from "../../../../Common/Title/HeadlineTrendingNewsTitle";

interface ArticleData {
  articleNo: number,
  article: Tweet,
  withDate: boolean,
}

const TrendingHeadlineArticle: React.FC<ArticleData> = (data) => (
  <h6 className="trending_article_title p-2">
    <HeadlineTrendingNewsTitle
      title={data.article.title}
      newsId={data.article.tweetId}
      ceetv={data.article.ceetv}
    />
  </h6>
)

export default TrendingHeadlineArticle;
