import * as React from 'react';
import {Nav, NavDropdown, Button, FormControl} from "react-bootstrap";
import {Image} from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup'
import "./MainMenu.css"
import {useSelector} from "react-redux";
import {getUser} from "../../../store/selectors/user/user.selector";
import SignedUserMenu from "../UserMenus/SignedUserMenu/SignedUserMenu";
import NotSignedUserMenu from "../UserMenus/NotSignedUserMenu/NotSignedUserMenu";
import {ChangeEvent, useState} from "react";
import {useHistory} from "react-router-dom";
import icon_trending from "../../../resources/icons/trending-header-gold-outline.svg";
import icon_news from "../../../resources/icons/globe-header-gold.svg";
import icon_watch from "../../../resources/icons/watch-header-gold.svg";
import icon_search from "../../../resources/icons/search.svg";

const MainMenu: React.FC = () => {
  const loggedUser = useSelector(getUser)
  const [searchCriteria, setSearchCriteria] = useState('')
  const history = useHistory()
  const handleSearchCriteriaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchCriteria(e.currentTarget.value);
  };
  const handlePressSearchButton = () => {
    setSearchCriteria('')
    history.push(`/search/${encodeURIComponent(searchCriteria)}`)
  };
  return (
    <Nav className="justify-content-end menu_box full_length" activeKey="/home">
      <div className="search-nav-mobile">
        <InputGroup className="mb-5">
          <FormControl
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon2"
            onChange={handleSearchCriteriaChange}
          />
          <InputGroup.Append>
            <Button onClick={handlePressSearchButton}
                    variant="outline-primary"
                    className="w-100 tvbizz-primary form-button md-m-0 ml-0">Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
      <div className="menu_item_mobile_button">
          <Button variant="sign-up-button" className="sign-up-button mr-1" type="submit" href="/news">
              <Image src={icon_news} className="icon-menu" title="Read the latest news"/>
          </Button>
      </div>
      <div className="menu_item_mobile_button">
          <Button variant="sign-up-button" className="sign-up-button mr-1" type="submit" href="/watch">
              <Image src={icon_watch} className="icon-menu" title="Watch the latest videos"/>
          </Button>
      </div>
      <div className="menu_item_mobile_button">
          <Button variant="sign-up-button" className="sign-up-button mr-1" type="submit" href="/trending">
              <Image src={icon_trending} className="icon-menu" title="Check out what is trending"/>
          </Button>
      </div>
        {/* <Nav.Item className="menu_item_b_left no-line-item">
          <a href={'/news'} className="menu_script">News</a>
        </Nav.Item> */}
        {/*<NavDropdown title="News" id="news-nav-dropdown-sub" className="news-nav-dropdown menu_script">
          <span className="sub-item-indicator-news"/>
          <div className="row-big">
            <ul className="clmn-big p-70">
              <div className="nav-category">Regions</div>
              <MenuCountry sectionUrl={"news"} />
            </ul>
          </div>
        </NavDropdown>*/}
        {/* <MainMenuItem linkAddress="watch" caption="Watch"/>
        <MainMenuItem linkAddress="trending" caption="Trending"/> */}
      <NavDropdown 
       title={
        <Image src={icon_search} className="icon-menu"/>
        }
        id="search-nav-dropdown-sub" 
        className="search-nav-dropdown menu_script search-nav-desktop">
        {/* <span className="sub-item-indicator-search"/> */}
        <div className="row-big">
          <InputGroup className="m-3">
            <FormControl
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={handleSearchCriteriaChange}
            />
            <InputGroup.Append>
              <Button onClick={handlePressSearchButton} variant="outline-primary" className="w-100 tvbizz-primary form-button mr-1">Search</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </NavDropdown>
      {!loggedUser && <NotSignedUserMenu />}
      {loggedUser && <SignedUserMenu loggedUser={loggedUser} />}
    </Nav>
  )
};

export default MainMenu;
