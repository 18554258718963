import * as React from "react";
import {Container} from "react-bootstrap";
import {Route, Switch} from "react-router-dom";
import MainPage from "./MainPage/MainPage";
import Signin from "../SignUp/Signin";
import Signup from "../SignUp/Signup";
import Confirm from "../SignUp/Confirm";
import AccountSelection from "../SignUp/AccountSelection";
import ErrorPage from "./SinglePages/ErrorPage";
import AboutUs from "./SinglePages/Administrative/AboutUs";
import ContactUs from "./SinglePages/Administrative/ContactUs";
import TermsPage from "./SinglePages/Administrative/TermsPage";
import PrivacyPage from "./SinglePages/Administrative/PrivacyPage";
import NewsletterSuccess from "./SinglePages/Administrative/NewsletterSuccess";
import NewsletterFail from "./SinglePages/Administrative/NewsletterFail";
import UserSettingsTabs from "../UserSettings/UserSettingsTabs";
import RedirectLogout from "../Redirect/RedirectLogout";
import AccountPlans from "../SignUp/AccountPlans";
import Loader from "../Loader/Loader";
import { Suspense } from "react";

const CenterPane: React.FC = () => {
  // const user = useSelector(getUser)
  const NewsList = React.lazy(() => import("./NewsList/NewsList"));
  const NewsSingle = React.lazy(() => import("./NewsList/NewsSingle/NewsSingle"));
  const VideoSingle = React.lazy(() => import("./VideoList/VideoSingle/VideoSingle"));
  const CompanyPage = React.lazy(() => import("./SinglePages/CompanyPage/CompanyPage"));
  const TrendingPage = React.lazy(() => import("./TrendingPage/TrendingPage"));
  const Inbox = React.lazy(() => import("../Inbox/Inbox"));
  const VideoList = React.lazy(() => import("./VideoList/VideoList"));
  const ShowPage = React.lazy(() => import("./SinglePages/ShowPage/ShowPage"));
  const SearchPage = React.lazy(() => import("./SinglePages/SearchPage/SearchPage"));
  const CountryPage = React.lazy(() => import("./SinglePages/CountryPage/CountryPage"));
  const CategoryPage = React.lazy(() => import("./SinglePages/CategoryPage/CategoryPage"));
  return (
    <Container fluid className="m-0">
      <Suspense fallback={<Loader/>}>
        <Switch>
          <Route path="/" exact component={MainPage}/>
          <Route path="/news" exact component={NewsList}/>
          <Route path="/news/region/:region" exact component={NewsList}/>
          <Route path="/news/country/:country" exact component={CountryPage}/>
          <Route path="/news/category/:category" exact component={CategoryPage}/>
          <Route path="/news/single/:newsId/:type" exact component={NewsSingle}/>
          <Route path="/country/:country" exact component={CountryPage}/>
          <Route path="/category/:category" exact component={CategoryPage}/>
          <Route path="/company/:companyName" exact component={CompanyPage}/>
          <Route path="/show/:showName" exact component={ShowPage}/>
          <Route path="/search/:criteria" exact component={SearchPage}/>
          <Route path="/news/people/:criteria" exact component={SearchPage}/>
          <Route path="/people/:criteria" exact component={SearchPage}/>
          <Route path="/watch" exact component={VideoList}/>
          <Route path="/watch/region/:region" exact component={VideoList}/>
          <Route path="/watch/country/:country" exact component={CountryPage}/>
          <Route path="/watch/category/:category" exact component={CategoryPage}/>
          <Route path="/watch/single/:videoId" exact component={VideoSingle}/>
          <Route path="/trending" exact component={TrendingPage}/>
          <Route path="/profile" exact component={UserSettingsTabs}/>
          <Route path="/profile/subscription" exact component={UserSettingsTabs}/>
          <Route path="/profile/upgrade" exact component={UserSettingsTabs}/>
          <Route path="/profile/security" exact component={UserSettingsTabs}/>
          <Route path="/inbox" exact component={Inbox}/>
          <Route path="/signin" exact component={Signin}/>
          <Route path="/signup" exact component={Signup}/>
          <Route path="/activate/:userId" exact component={Confirm}/>
          <Route path="/confirm" exact component={Confirm}/>
          <Route path="/startuser" exact component={AccountSelection}/>
          <Route path="/about" exact component={AboutUs}/>
          <Route path="/contact" exact component={ContactUs}/>
          <Route path="/terms" exact component={TermsPage}/>
          <Route path="/privacy" exact component={PrivacyPage}/>
          <Route path="/newsletter-subscribed" exact component={NewsletterSuccess}/>
          <Route path="/newsletter-error" exact component={NewsletterFail}/>
          <Route path="/learnmore" exact component={AccountPlans}/>
          <Route path="/logout" exact component={RedirectLogout}/>
          <Route path="/error" exact component={ErrorPage}/>
        </Switch>
      </Suspense>
    </Container>
  )
}

export default CenterPane;
