import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";

const RedirectLogout: React.FC = () =>  {
  useEffect(() => {
    window.location.href = "https://tvbizz.net/api/user/logout";
  }, []);

  return (
    <Row>
      <Col xl={{span: 9}} lg={{span: 12, order: 2}} md={{span: 12}}>
        <h2>Logging out...</h2>
      </Col>
    </Row>
  )
}

export default RedirectLogout;
