import * as React from "react";
import {Row, Col} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import "./UserSettings.css"
import {Formik, FormikHelpers} from "formik";
import {axiosInstance} from "../../const/axiosClient";
import {userChangePass} from "../../const/urlConst";
import {passwordChangeFormValidation} from "../SignUp/SigninValidation/passwordChangeFormValidation";
import {useHistory} from "react-router-dom";
import * as H from "history";

interface PasswordChange {
  oldpass: string,
  newpass1: string,
  newpass2: string,
}

const handleSuccess = (helpers: FormikHelpers<PasswordChange>, history: H.History) => {
  helpers.resetForm()
  helpers.setStatus({
    isError: false,
    showMsg: true,
  })
  helpers.setSubmitting(false)
  history.push('/logout')
}

const handleError = (helpers: FormikHelpers<PasswordChange>, error: string) => {
  helpers.setStatus({
    isError: true,
    errorMsg: error,
    showMsg: false,
  })
  helpers.setSubmitting(false)
}

const changePasswords = async (passwords: PasswordChange,
                               helpers: FormikHelpers<PasswordChange>,
                               handleSuccess: (helpers: FormikHelpers<PasswordChange>, history: H.History) => void,
                               handleError: (helpers: FormikHelpers<PasswordChange>, err: string) => void,
                               history: H.History) => {
  helpers.setSubmitting(true)
  if(passwords.newpass1 !== passwords.newpass2) {
    handleError(helpers, "The two passwords do not match!")
    return
  }
  try {
    const {data} = await axiosInstance.put(`${userChangePass}`,
      {
        oldpass: passwords.oldpass,
        newpass: passwords.newpass1
      });
    if (data.result) {
      handleSuccess(helpers, history)
    } else {
      handleError(helpers, data.errorMessage)
    }
  } catch (err) {
    handleError(helpers, "Problems with your network - please, try again later")
  }
}

const UserSecurity: React.FC = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div className="settings_tab_content">
        <Row>
          <Col xs="12" sm="12" lg="9" xl="9" className="mx-auto">
            <h2 className="my-4">Change you password</h2>
            <span>
              <strong>Note: You need to re-login after saving the password change.</strong><br/><br/>
            </span>
            <Formik
              initialValues={{oldpass: "", newpass1: "", newpass2: ""}}
              validationSchema={passwordChangeFormValidation}
              onSubmit={(passwords: PasswordChange, helpers: FormikHelpers<PasswordChange>) => {
                helpers.setSubmitting(true)
                changePasswords(passwords, helpers, handleSuccess, handleError, history)
              }}
            >
              {
                ({
                   values,
                   errors,
                   touched,
                   status,
                   isSubmitting,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                 }) => (
                  <Form className="row" onSubmit={handleSubmit}>
                    {status && status.isError && <Col sm="12" lg="12" xl="12">
                      <div className="error-message-in-form mb-2">{status.errorMsg}</div>
                    </Col>}
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicEmail">
                        <FormLabel>Your current password:</FormLabel>
                        <FormControl
                          type="password"
                          name="oldpass"
                          value={values.oldpass}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={touched.oldpass && errors.oldpass ? "error-border" : ""}
                          placeholder="Enter current password"/>
                        {touched.oldpass && errors.oldpass ? (
                          <div className="error-message">{errors.oldpass}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col sm="12" lg="12" xl="12">
                      <FormGroup controlId="formBasicEmail">
                        <FormLabel>Your new password:</FormLabel>
                        <FormControl
                          type="password"
                          name="newpass1"
                          value={values.newpass1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={touched.newpass1 && errors.newpass1 ? "error-border" : ""}
                          placeholder="Enter new password"/>
                        {touched.newpass1 && errors.newpass1 ? (
                          <div className="error-message">{errors.newpass1}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="12" lg="9" xl="12">
                      <FormGroup controlId="formBasicEmail">
                        <FormLabel>Confirm your new password:</FormLabel>
                        <FormControl
                          type="password"
                          name="newpass2"
                          value={values.newpass2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={touched.newpass2 && errors.newpass2 ? "error-border" : ""}
                          placeholder="Reenter new password"/>
                        {touched.newpass2 && errors.newpass2 ? (
                          <div className="error-message">{errors.newpass2}</div>
                        ) : <div className="error-message">&nbsp;</div>}
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="12" lg="9" xl="9" className="my-3 text-center mx-auto">
                      <Button className="w-100 tvbizz-primary" variant="primary" type="submit" disabled={isSubmitting}>
                        {!isSubmitting && "Save"}
                        {isSubmitting && <div className="button-loader">
                          <div className="dot1"/>
                          <div className="dot2"/>
                          <div className="dot3"/>
                        </div>}
                      </Button>
                    </Col>
                  </Form>
                )
              }
            </Formik>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UserSecurity;
