import {TweetVideo} from "../../../model/TweetVideo";
import {Dispatch} from "react";
import {loadSingleVideosItem, videoHeadlinesList, videoLatest} from "../../../const/urlConst";
import {VideoFilterCriteria} from "../../../model/VideoFilterCriteria";
import {axiosInstance} from "../../../const/axiosClient";
import {RegionType} from "../../../model/RegionType";
import {getEnumFromInt} from "../../../utils/country/regionUtils";

export const LOAD_VIDEO_HEADLINES_EVENT = 'LOAD_VIDEO_HEADLINES';
export const LOAD_VIDEO_LIST_EVENT = 'LOAD_VIDEO_LIST';
export const LOAD_VIDEO_LOADING_EVENT = 'LOAD_VIDEO_LOADING';
export const LOAD_VIDEO_RELATED_EVENT = 'LOAD_VIDEO_RELATED';
export const LOAD_VIDEO_LATEST_RELATED_EVENT = 'LOAD_VIDEO_LATEST_RELATED';
export const CLEAR_VIDEO_DATA_EVENT = 'CLEAR_VIDEO_DATA';

export type LOAD_VIDEO_HEADLINE = typeof LOAD_VIDEO_HEADLINES_EVENT;
export type LOAD_VIDEO_LIST = typeof LOAD_VIDEO_LIST_EVENT;
export type LOAD_VIDEO_LOADING = typeof LOAD_VIDEO_LOADING_EVENT;
export type LOAD_VIDEO_RELATED = typeof LOAD_VIDEO_RELATED_EVENT;
export type LOAD_VIDEO_LATEST_RELATED = typeof LOAD_VIDEO_LATEST_RELATED_EVENT;
export type CLEAR_VIDEO_DATA = typeof CLEAR_VIDEO_DATA_EVENT;

export interface LoadVideoHeadlinesAction {
  type: LOAD_VIDEO_HEADLINE,
  mainVideo: TweetVideo | null,
  headlines: TweetVideo[],
}

export interface LoadVideoListAction {
  type: LOAD_VIDEO_LIST,
  videos: TweetVideo[],
  filter: VideoFilterCriteria,
}
export interface LoadVideoLoadingAction {
  type: LOAD_VIDEO_LOADING,
  loading: boolean,
}

export interface LoadVideoRelatedAction {
  type: LOAD_VIDEO_RELATED,
  selectedItem: TweetVideo,
  relatedVideos: TweetVideo[],
  relatedLatestVideos: TweetVideo[],
}

export interface LoadVideoLatestRelatedAction {
  type: LOAD_VIDEO_LATEST_RELATED,
  latestRelatedVideos: TweetVideo[],
}

export interface ClearVideoData {
  type: CLEAR_VIDEO_DATA,
}

export const loadVideoHeadlines = () => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${videoHeadlinesList}`);
    dispatch( {
      type: LOAD_VIDEO_HEADLINES_EVENT,
      headlines: data.videos,
      mainVideo: data.headline,
    });
  }
}

export const loadVideoList = (lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
            ? await axiosInstance.get(`${videoLatest}?before=${lastDate}`)
            : await axiosInstance.get(`${videoLatest}`);
    dispatch( {
      type: LOAD_VIDEO_LIST_EVENT,
      videos: data.videos,
      filter: {
        region: null,
        country: null,
      }
    });
  }
}

export const loadingRelatedVideoData = (loading: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: LOAD_VIDEO_LOADING_EVENT,
      loading: loading,
    });
  }
}

export const loadRelatedDataSingleVideoItem = (videoId: number) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = await axiosInstance.get(`${loadSingleVideosItem}${videoId}`)
      dispatch( {
      type: LOAD_VIDEO_RELATED_EVENT,
      selectedItem: data.videoDto,
      relatedVideos: data.related,
      relatedLatestVideos: data.nextItems,
    });
  }
}

export const loadRelatedVideoListAfterData = (lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${videoLatest}?before=${lastDate}`)
      : await axiosInstance.get(`${videoLatest}`);
    dispatch( {
      type: LOAD_VIDEO_LATEST_RELATED_EVENT,
      latestRelatedVideos: data.videos,
    });
  }
}

export const loadVideosListForRegion = (region: RegionType, lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${videoLatest}?before=${lastDate}&region=${region}`)
      : await axiosInstance.get(`${videoLatest}?region=${region}`);
    dispatch( {
      type: LOAD_VIDEO_LIST_EVENT,
      videos: data.videos,
      filter: {
        region: getEnumFromInt(data.region),
        country: null,
      }
    });
  }
}

export const loadVideosListForCountry = (country: string, lastDate: number | null) => {
  return async (dispatch: Dispatch<any>) => {
    const { data } = lastDate
      ? await axiosInstance.get(`${videoLatest}?before=${lastDate}&country=${encodeURIComponent(country)}`)
      : await axiosInstance.get(`${videoLatest}?country=${encodeURIComponent(country)}`);
    dispatch( {
      type: LOAD_VIDEO_LIST_EVENT,
      videos: data.videos,
      filter: {
        region: null,
        country: data.country,
      }
    });
  }
}

export const clearVideoData = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch( {
      type: CLEAR_VIDEO_DATA_EVENT,
    });
  }
}
