import * as React from "react";
import LatestArticlesList from "./LatestArticleList/LatestArticlesList";
import {Tweet} from "../../../../../model/Tweet";
import {Button} from "react-bootstrap";
// import {useDispatch, useSelector} from "react-redux";
// import {useCallback} from "react";
// import {loadNews} from "../../../../../hooks/scroll/loadNews";
// import {getLastDateForNews} from "../../../../../utils/date/dateUtils";
// import {isLoadingPageItems} from "../../../../../store/selectors/tweets/tweet.selector";


interface LatestArticlesData {
  latest: Tweet[],
}

const LatestArticles: React.FC<LatestArticlesData> = (props) => {
  // const dispatch = useDispatch()
  // const isSubmitting = useSelector(isLoadingPageItems)
  const loadMore = () => {
    window.location.href = 'news'
  }
  return (
    <React.Fragment>
      <div className="latest_headline_title">
        <div onClick={() => window.location.href = '/news'} className="latest_underline force-cursor-on-div">Happening
          now
        </div>
      </div>
      <LatestArticlesList latest={props.latest.length > 14 ? props.latest.slice(0, 14) : props.latest}/>
      <div className="row">
        {props.latest.length < 100 && <Button onClick={loadMore} variant="outline-primary"
                className="w-100 tvbizz-outline-primary mx-auto my-3" type="submit">
          Show more news
          {/*isSubmitting && <div className="button-loader">
            <div className="dot1"/>
            <div className="dot2"/>
            <div className="dot3"/>
          </div>*/}
        </Button>}
      </div>
    </React.Fragment>
  )
}

export default LatestArticles;
