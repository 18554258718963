import * as React from "react";
import {Row, Col} from "react-bootstrap";

interface SubscrExpiryDate {
  expiryDate: string,
}

const UserTabPrepaidSubscrContent: React.FC<SubscrExpiryDate> = (props) => (
  <div className="settings_tab_content">
    <Row>
      <Col xs="12" sm="12" lg="12">
        <h3 className="font-weight-bold my-3">Your Subscription</h3>
      </Col>
    </Row>
    <Row>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">My current Subscription</h6>
        <p>Annual</p>
      </Col>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Renewal Method</h6>
        <p>Manual</p>
      </Col>
    </Row>
    <Row className="mt-2">
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Expiry Date</h6>
        <p>{new Date(Date.parse(props.expiryDate)).toLocaleDateString("en-UK")}</p>
      </Col>
      <Col xs="12" sm="12" lg="6">
        <h6 className="font-weight-bold">Next Payment Date</h6>
        <p>None</p>
      </Col>
    </Row>
    <hr />
    <Row>
      <Col xs="12" sm="12" lg="12">
        <h3 className="font-weight-bold my-3">Cancel Subscription</h3>
      </Col>
    </Row>
    <Row>
      <Col xs="12" sm="12" lg="12">
        <span>
          Your subscription has been created manually and will not be re-newed automatically. If you want to
          cancel your access before your expiry date, please, contact us by writing to <a href="mailto:info@tvbizz.net">info@tvbizz.net</a>
          <br/><br/><br/>
          Note: Early cancellation does not qualify you for a refund.
        </span>
      </Col>
    </Row>
  </div>
)

export default UserTabPrepaidSubscrContent;
