import {User} from "../../../model/User";
import {Dispatch} from "react";
import {
  subscriptionGetUser,
  userActivate,
  userEditData,
  userFullLoad,
  userLoad,
  userLogin
} from "../../../const/urlConst";
import {axiosInstance} from "../../../const/axiosClient";
import {UserSettingsData} from "../../../model/UserSettingsData";

export const LOAD_USER_EVENT = 'LOAD_USER';
export const LOAD_EXPIRED_EVENT = 'LOAD_EXPIRED_EVENT';
export const LOAD_LOCKED_EVENT = 'LOAD_LOCKED_EVENT';
export const CHANGE_USER_VIEW_EVENT = 'CHANGE_USER_VIEW_EVENT';
export const LOAD_USER_STRIPE_ID_EVENT = 'LOAD_USER_STRIPE_ID_EVENT';
export const LOAD_USER_DATA_EVENT = 'LOAD_USER_DATA';

export type LOAD_USER = typeof LOAD_USER_EVENT;
export type LOAD_EXPIRED = typeof LOAD_EXPIRED_EVENT;
export type LOAD_LOCKED = typeof LOAD_LOCKED_EVENT;
export type CHANGE_USER_VIEW = typeof CHANGE_USER_VIEW_EVENT;
export type LOAD_USER_STRIPE_ID = typeof LOAD_USER_STRIPE_ID_EVENT;
export type LOAD_USER_DATA = typeof LOAD_USER_DATA_EVENT;


export interface LoadCurrentUser {
  type: LOAD_USER,
  user: User,
  ceetvEnabled: boolean,
  listViewEnabled: boolean,
  isTrial: boolean,
  isFree: boolean,
}

export interface LoadCurrentUserAdditionalData {
  type: LOAD_USER_DATA,
  position: string,
  city: string,
  webpage: string,
  linkedin: string,
  facebook: string,
}

export interface MarkUserAsExpired {
  type: LOAD_EXPIRED,
}

export interface MarkUserAsLocked {
  type: LOAD_LOCKED,
}

export interface ChangeUserView {
  type: CHANGE_USER_VIEW,
  listView: boolean,
}

export interface LoadStripeCustomerId {
  type: LOAD_USER_STRIPE_ID,
  stripeCustomerId: string,
}

export const loadUser = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await axiosInstance.get(`${userLoad}`);
      if(data.errorMsg === 'OK') {
        dispatch({
          type: LOAD_USER_EVENT,
          user: data.userDto,
          ceetvEnabled: data.userDto.ceetv,
          listViewEnabled: data.userDto.listViews,
          isTrial: data.userDto.trial,
          isFree: data.userDto.trial
        });
      }
    } catch(err: any) {
      if(err.errorMsg === 'User locked') { // user locked
        dispatch({
          type: LOAD_LOCKED_EVENT,
        });
      } else if(err.errorMsg === 'User subscription expired') { // user expired
        dispatch({
          type: LOAD_EXPIRED_EVENT,
        });
      } /*else {
        const dataTemp = JSON.parse('{"errorMsg":"OK","userDto":{"id":13,"firstName":"Kiril","lastName":"Borisov","company":"CEE TV","country":"Bulgaria","email":"admin@ceetv.net","username":"patton","photo":"Photo_13_1503000660783.png","initials":"KB","expiryDate":"2022-07-20T21:00:00.000+00:00","ceetv":true,"trial":false,"listViews":false}}')
        dispatch({
          type: LOAD_USER_EVENT,
          user: dataTemp.userDto,
          ceetvEnabled: dataTemp.userDto.ceetv,
          listViewEnabled: dataTemp.userDto.listViews,
          isTrial: dataTemp.userDto.trial,
          isFree: dataTemp.userDto.trial
        });
      }*/
    }
  }
}

export const loadUserFullData = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { data } = await axiosInstance.get(`${userFullLoad}`);
      if(data.errorMsg === 'OK') {
        dispatch({
          type: LOAD_USER_EVENT,
          user: data.userDto.user,
          ceetvEnabled: data.userDto.user.ceetv,
          listViewEnabled: data.userDto.user.listViews,
          isTrial: data.userDto.user.trial,
          isFree: data.userDto.user.trial
        })
        dispatch({
          type: LOAD_USER_DATA_EVENT,
          position: data.userDto.position,
          city: data.userDto.city,
          webpage: data.userDto.webpage,
          linkedin: data.userDto.social,
          facebook: '',
        })
      }
    } catch(err: any) {
      if(err.errorMsg === 'User locked') { // user locked
        dispatch({
          type: LOAD_LOCKED_EVENT,
        });
      } else if(err.errorMsg === 'User subscription expired') { // user expired
        dispatch({
          type: LOAD_EXPIRED_EVENT,
        });
      } /*else {
        const dataTemp = JSON.parse('{"errorMsg":"OK","userDto":{"user":{"id":13,"firstName":"Kiril","lastName":"Borisov","company":"CEE TV","country":"Bulgaria","email":"admin@ceetv.net","username":"patton","photo":"Photo_13_1503000660783.png","initials":"KB","expiryDate":"2022-07-20T21:00:00.000+00:00","ceetv":true,"trial":false,"listViews":false},"position":"CEE TV","city":"Simeonovo 4, Block Magnolianull","phone":"+359 886 278632","mobile":null,"info":"good ok","webpage":"https://tvbizz.net","linkedin":null,"facebook":null},"subscriptionDto":null}')
        dispatch({
          type: LOAD_USER_EVENT,
          user: dataTemp.userDto.user,
          ceetvEnabled: dataTemp.userDto.user.ceetv,
          listViewEnabled: dataTemp.userDto.user.listViews,
          isTrial: dataTemp.userDto.user.trial,
          isFree: dataTemp.userDto.user.trial
        })
        dispatch({
          type: LOAD_USER_DATA_EVENT,
          position: dataTemp.userDto.position,
          city: dataTemp.userDto.city,
          webpage: dataTemp.userDto.webpage,
          linkedin: dataTemp.userDto.social,
          facebook: '',
        })
      }*/
    }
  }
}

export const loadAdditionalUserData = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axiosInstance.get(`${userLoad}`);

    } catch(err: any) {
      if(err.errorMsg === 'User locked') { // user locked
        dispatch({
          type: LOAD_LOCKED_EVENT,
        });
      } else if(err.errorMsg === 'User subscription expired') { // user expired
        dispatch({
          type: LOAD_EXPIRED_EVENT,
        });
      }
    }
  }
}

export const loginUser = (username: string, password:string, handleSuccess: () => void, handleError: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {data} = await axiosInstance.post(`${userLogin}`,
        {
          username: username,
          password: password,
        });
      triggerExtendedUserEvents(data, dispatch, handleSuccess)
    } catch(err) {
      handleError()
    }
  }
}

export const confirmUser = (token: number, userId:number, handleSuccess: () => void, handleError: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {data} = await axiosInstance.post(`${userActivate}`,
        {
          userId: userId,
          token: token,
        });
      triggerUserEvent(data, dispatch, handleSuccess)
    } catch(err) {
      handleError()
    }
  }
}

export const registerUserInStripe = (email: string, handleSuccess: () => void, handleError: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {data} = await axiosInstance.post(`${subscriptionGetUser}`,
        {
          email: email,
        });
      if(data.errorMsg === 'OK') {
        dispatch({
          type: LOAD_USER_STRIPE_ID_EVENT,
          stripeCustomerId: data.stripeId,
        });
        handleSuccess()
      }
    } catch(err) {
      handleError()
    }
  }
}

export const editUserData = (userData: UserSettingsData, handleSuccess: () => void, handleError: () => void) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {data} = await axiosInstance.put(`${userEditData}`,
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          company: userData.company,
          position: userData.position,
          country: userData.country,
          address: userData.city,
          webpage: userData.webpage,
          social: userData.social,
      })
      triggerExtendedUserEvents(data, dispatch, handleSuccess)
    } catch(err) {
      handleError()
    }
  }
}

const triggerUserEvent = (data: any, dispatch: Dispatch<any>, handleSuccess: () => void) => {
  if(data.errorMsg === 'OK') {
    dispatch({
      type: LOAD_USER_EVENT,
      user: data.userDto,
      ceetvEnabled: data.userDto.ceetv,
      listViewEnabled: data.userDto.listViews,
      isTrial: data.userDto.trial,
      isFree: data.userDto.trial
    });
    handleSuccess()
  }
}

const triggerExtendedUserEvents = (data: any, dispatch: Dispatch<any>, handleSuccess: () => void) => {
  if(data.errorMsg === 'OK') {
    dispatch({
      type: LOAD_USER_EVENT,
      user: data.userDto.user,
      ceetvEnabled: data.userDto.user.ceetv,
      listViewEnabled: data.userDto.user.listViews,
      isTrial: data.userDto.user.trial,
      isFree: data.userDto.user.trial
    })
    dispatch({
      type: LOAD_USER_DATA_EVENT,
      position: data.userDto.position,
      city: data.userDto.city,
      webpage: data.userDto.webpage,
      linkedin: data.userDto.social,
      facebook: '',
    })
    handleSuccess()
  }
}

