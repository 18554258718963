import * as Yup from 'yup';

export const userEditFormValidation = Yup.object().shape({
  firstName: Yup.string().min(2, "The first name must contain at least 2 characters")
    .required("You need to enter your first name"),
  lastName: Yup.string().min(2, "The last name must contain at least 2 characters")
    .required("You need to enter your last name"),
  company: Yup.string().min(2, "The country name must contain at least 2 characters")
    .required("You need to enter your country of residence"),
  country: Yup.string().min(2, "The company name must contain at least 2 characters")
    .required("You need to enter the company you work for"),
  position: Yup.string().min(2, "The position designation must contain at least 2 characters"),
  city: Yup.string().min(2, "The city or town name must contain at least 2 characters"),
  webpage: Yup.string().url("Your webpage should be a valid url"),
  social: Yup.string().url("Your social profile should be a valid social network url"),
})
