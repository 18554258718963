import {AppState} from "../../reducers/rootReducer";
import {TrendingItem} from "../../../model/TrendingItem";
import {TrendingCategory} from "../../../model/TrendingCategory";
import {TrendingRegions} from "../../../model/TrendingRegions";

export function getTrendingNews(state: AppState): TrendingItem[] {
  return state.trending.trendingNews
}

export function getTrendingRegionHeadlines(state: AppState): TrendingRegions | null {
  return state.trending.regionNews
}

export function getRegionCompanyTags(state: AppState): TrendingRegions {
  return state.trending.regionCompanyTags
}

export function getRegionShowTags(state: AppState): TrendingRegions {
  return state.trending.regionShowTags
}

export function getRegionPeopleTags(state: AppState): TrendingRegions {
  return state.trending.regionPeopleTags
}

export function getTrendingCategories(state: AppState): TrendingCategory[] {
  return state.trending.trendingCategories
}

export function getCompanyTags(state: AppState): TrendingItem[] {
  return state.trending.companyTags
}

export function getShowTags(state: AppState): TrendingItem[] {
  return state.trending.showTags
}

export function getPeopleTags(state: AppState): TrendingItem[] {
  return state.trending.peopleTags
}

export function isLoading(state: AppState): boolean {
  return state.trending.loading
}

export function isLoadedTrendingData(state: AppState): boolean {
  return state.trending.loadedTrendingData
}

export function isLoadedPanelNewsData(state: AppState): boolean {
  return state.trending.loadedPanelNewsData
}

export function isLoadedPanelTagsData(state: AppState): boolean {
  return state.trending.loadedPanelTagsData
}

export function isLoadingCategories(state: AppState): boolean {
  return state.trending.isLoadingCategories
}
